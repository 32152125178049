import { getTchSettingsFromLCStorage, setTchSettingsToLCStorage } from '../../../../utils/lcStorage';

/** Updates the current timestamp in the local storage.
 *
 * @param {any} currentTime - The current time to be stored in local storage.
 * we need to store the timeStampInLC in local storgae as 
 * we check timeStampInLC and if it is less than 24 hours 
 * we hardReload the page if version mismatch. 
 */
export const updateCurrentTimeStampeInLC = (currentTime: any) => {
  // It is necessary to get local values again bcz there are chances, some other part
  // of app may override some values inside localStorage while this function in executing.
  const existingValues = getTchSettingsFromLCStorage();
  const newSettings = {
    appVersionData: {
      ...existingValues.appVersionData,
      timeStampInLC: currentTime,
    }
  };
  // storing the currentTime into local storage for future use.
  setTchSettingsToLCStorage(newSettings);
};

/**
 * updates the hard refresh count in the local storage.
 * 
 * this function is necessary to ensure that the hard refresh count is accurately
 * updated and stored in the local storage. Since we are doing hard reload for maximum 5 times 
 *  with in 24Hours
 * 
 * @param {number} noOfRefresh - The number of hard refreshes to be set.
 */
export const updateRefreshCountInLC = (noOfRefresh: number) => {
  // It is necessary to get local values again bcz there are chances, some other part
  // of app may override some values inside localStorage while this function in executing.
  const existingValues = getTchSettingsFromLCStorage();
  const newSettings = {
    appVersionData: {
      ...existingValues.appVersionData,
      hardRefreshCount: noOfRefresh,
    }
  };
  // storing the hardRefreshCount into local storage for future use.
  setTchSettingsToLCStorage(newSettings);
};

// function to reset the current time and hardRefreshCount 
// we need to reset the values when 24Hours has been passed
export const resetAppVersionInLC = () => {
  updateCurrentTimeStampeInLC(null);
  updateRefreshCountInLC(0);
};

// getRefreshCountAndCurrntTimeFromLC is our getter function 
// this function is returning us the current values of appVersionData
// reason for getting appVerionData we are doing comparsion and doing our hardReload things.
export const getRefreshCountAndCurrntTimeFromLC = () => {
  const existingValues = getTchSettingsFromLCStorage();
  return existingValues.appVersionData;
};
