const graphql = require('babel-plugin-relay/macro');

export const TchAuthQuickLoginMutation = graphql`
  mutation TchAuthQuickLoginMutation($uuid: String!) {
    tch_auth_quicklogin(
      uuid: $uuid
  ) {
      success
      data {
        jwt
      }
    }    
  }
`;
