const graphql = require('babel-plugin-relay/macro');

export const verifyJWTMutation = graphql`
  mutation VerifyJWTMutation($jwt: String!) {
    resources_auth_verify_jwt(jwtToken: $jwt) {
        jwt
        data {
          resourceId
          languageId
          email
          image
          name
          timezone
        }
      }
    }
`;
