/**
 * @generated SignedSource<<874a2c69cd7c9c3500bbbc956accf5c8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomMutationDataLessonDetailFragment$data = {
  readonly av: {
    readonly room1: {
      readonly session: string;
      readonly token: string;
    };
    readonly room2: {
      readonly session: string;
      readonly token: string;
    };
    readonly studentGroupRooms: ReadonlyArray<{
      readonly session: string;
      readonly token: string;
      readonly uuid: string;
    } | null>;
  };
  readonly lessonUuid: string;
  readonly " $fragmentType": "CustomMutationDataLessonDetailFragment";
};
export type CustomMutationDataLessonDetailFragment$key = {
  readonly " $data"?: CustomMutationDataLessonDetailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomMutationDataLessonDetailFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "session",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomMutationDataLessonDetailFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonUuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApptGroupClassroomTchEnterAv",
      "kind": "LinkedField",
      "name": "av",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ApptGroupClassroomTchEnterRoomStudGrp",
          "kind": "LinkedField",
          "name": "studentGroupRooms",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ApptGroupClassroomTchEnterRoom",
          "kind": "LinkedField",
          "name": "room2",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ApptGroupClassroomTchEnterRoom",
          "kind": "LinkedField",
          "name": "room1",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApptGroupClassroomTchEnterOutput",
  "abstractKey": null
};
})();

(node as any).hash = "733bc886e09e503c953ff026a3a74952";

export default node;
