/* eslint-disable */
import { handleJWTCookie } from '../services/fetchService';
import * as Sentry from '@sentry/react';
import { getDataToLCStorage } from './lcStorage';
import { Backdrop } from '@languageconvo/wcl';

export const generateUniqueId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0; const
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const refreshPage = () => {
  // Don't do complete relead, just do cache version of browser reload...
  window.location.reload();
}

// this is responsible for resetting the legacy app cookies and once get success response 
// from resetting the cookies, remove the JWT and redirect user back to login.
export const handleLogout = async (setLogoutInProgress: React.Dispatch<React.SetStateAction<boolean>>) => {
  const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');
  const payload = {
    action: 'unsetLoginCookie',
    data: {
      jwt: storedSecurityValues?.jwt || ''
    }
  };
  try {
    // this will set the authContext state which later on we are accessing in dashboard layout
    // and displaying backdrop based on it.
    setLogoutInProgress(true);
    await handleJWTCookie(payload);
  } catch(err: any) {
    // Can check on sentry, why logOut not working...
    Sentry.captureException(err);
  } finally {
    localStorage.removeItem('lc_tchsecurity');
    setLogoutInProgress(false);
    window.location.href = '/login';
  }
}

export const getTimeValue = () => {
  // In general request should not take longer than 5 seconds.
  if (process.env.NODE_ENV === 'development') {
    return 15000; // for dev 15 sec.
  } else {
    return 5000;
  }
}