/**
 * @generated SignedSource<<9ac84bef2d4ba02eedf82860b6aa6027>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyPasscodeMutation$variables = {
  email: string;
  passcode: string;
};
export type VerifyPasscodeMutation$data = {
  readonly resources_auth_verify_passcode: {
    readonly data: {
      readonly email: string;
      readonly image: string;
      readonly languageId: number;
      readonly name: string;
      readonly resourceId: number;
      readonly timezone: string;
    };
    readonly jwt: string;
  } | null;
};
export type VerifyPasscodeMutation = {
  response: VerifyPasscodeMutation$data;
  variables: VerifyPasscodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passcode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "passcode",
        "variableName": "passcode"
      }
    ],
    "concreteType": "ResourcesAuthVerifyPasscode",
    "kind": "LinkedField",
    "name": "resources_auth_verify_passcode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResourcesAuthVerifyPasscodeData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "languageId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPasscodeMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyPasscodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "86bdd36e6efaa855e63058b416b0a41d",
    "id": null,
    "metadata": {},
    "name": "VerifyPasscodeMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPasscodeMutation(\n  $email: String!\n  $passcode: String!\n) {\n  resources_auth_verify_passcode(email: $email, passcode: $passcode) {\n    data {\n      email\n      image\n      name\n      resourceId\n      languageId\n      timezone\n    }\n    jwt\n  }\n}\n"
  }
};
})();

(node as any).hash = "07fc8b512b524a195adaea5bd28967e2";

export default node;
