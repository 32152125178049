import React from 'react';
import {
  CardContent, Grid2, Grid2Ct, Ty, Card, Button,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';

const DsIndex = () => {
  // dont allow access to this pagein prod, yet
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Design System
                </Ty>
              </Grid2>

              <Grid2 xs={12} sx={{ pt: 3 }}>
                <Button to="/ds/grid" component={NavLink} variant="outlined">Grid</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button to="/ds/forms" component={NavLink} variant="outlined">Forms</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button to="/ds/typography" component={NavLink} variant="outlined">Typography</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

export default DsIndex;
