/**
 * @generated SignedSource<<ef7d7bdaac9873031287ab7172d4746d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomMutationDataLessonDateTimeFragment$data = {
  readonly dt: {
    readonly current: string;
    readonly duration: number;
    readonly endsAt: string;
    readonly secondsUntilEnd: number;
    readonly secondsUntilStart: number;
    readonly startsAt: string;
  };
  readonly " $fragmentType": "CustomMutationDataLessonDateTimeFragment";
};
export type CustomMutationDataLessonDateTimeFragment$key = {
  readonly " $data"?: CustomMutationDataLessonDateTimeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomMutationDataLessonDateTimeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomMutationDataLessonDateTimeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApptGroupClassroomTchEnterDt",
      "kind": "LinkedField",
      "name": "dt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondsUntilStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondsUntilEnd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApptGroupClassroomTchEnterOutput",
  "abstractKey": null
};

(node as any).hash = "e7db36f554c708a828dd2bcb7809a296";

export default node;
