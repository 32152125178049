import * as Sentry from '@sentry/react';
import { handleJWTCookie } from '../services/fetchService';
import { getDataToLCStorage, setDataToLCStorage } from './lcStorage';

export const setCookieForCurrentUser = async (
  api_response :any,
  setAuth :any,
  storageKey :string,
  navigate? :any
) => {
  try {
    const { jwt, data } = api_response;
    // execute external cookie API call only if user is doing Auth else don't need to execute
    let response = { success: true };
    if (navigate) {
      // Should wait for cookies to set up first then save user data and tokens to
      // locaStorage and auth provider...
      const payload = { action: 'setLoginCookie', data: { jwt: jwt || '' } };
      response = await handleJWTCookie(payload);
    }
    if (response && response.success) {
      // setting here the auth provider (react state variables)...
      // need resourceId on each API call that's why better to store here for quick retrieval
      await setAuth({
        isAuthenticated: true,
        data: {
          resourceId: data?.resourceId,
          languageId: data?.languageId,
          timezone: data?.timezone
        } || {}
      });
      // adding values to localStorage...
      const storedValues = getDataToLCStorage(storageKey);
      setDataToLCStorage(storageKey, { ...storedValues, jwt });
      // conditionally navigate as we can calling same fucntion form multiple positions...
      if (navigate) { navigate('/home'); }
    }
  } catch (err: any) {
    // Can check on sentry, why set cookie api call failed...
    Sentry.captureException(err);
  }
};

export const setJWTForCurrentUserInLocalStorage = async (
  api_response :any,
  setAuth :any,
  storageKey :string,
  navigate? :any
) => {
  const { jwt } = api_response;
  // setting here the auth provider (react global state variable)...
  await setAuth({
    isAuthenticated: true,
  });
  // adding values to localStorage...
  const storedValues = getDataToLCStorage(storageKey);
  setDataToLCStorage(storageKey, { ...storedValues, jwt });
  // conditionally navigate as we can calling same fucntion form multiple positions...
  if (navigate) { navigate('/home'); }
};
