import * as Sentry from '@sentry/react';
import { AxiosWrapper } from '../common/utils/AxiosWrapper/AxiosWrapper';
import { getDataToLCStorage } from './lcStorage';

// make an api call attempting to get the versionTimestamp. will return null if that api
// call is not successful, otherwise it will return a timeStamp 
export const getVersionTimeStamp: () => Promise<string | null> = async () => {
  // default value for timeStampe is null so if our api fails null will be returned 
  let timeStamp = null;

  const axiosWrapper = new AxiosWrapper();
  // getting the jwt from local storage need to pass in `TchAuthGetversionts`
  // api call
  const jwt = getJWTFromLC();

  try {
    const response = await axiosWrapper.postApiRequest(
      // dont need to add an endpoint to the graphql base url, thus this empty string
      '',
      {
        baseURL: `${process.env.REACT_APP_BASE_API}`,
        // this passes the cookies to our backend, which is needed; the refresh token is in a
        // http only cookie
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      {
        query: `mutation TchAuthGetversionts {
          tch_auth_getversionts {
            success
            errors
              data {
                versionTimestamp
              }
          }
        }`
      }
    );

    // if we got the versionTimestamp in our response, the api call was successful. 
    // set the response(versionTimestamp) to timeStamp(variable)
    if (response?.data?.data?.tch_auth_getversionts?.data?.versionTimestamp) {
      // storing versionTimestamp return from BE in a varriable so we can return it and use it 
      timeStamp = response?.data?.data?.tch_auth_getversionts?.data?.versionTimestamp;
    } else {
      Sentry.captureMessage('Unexpected response format in getVersionTimeStamp');
    }
  } catch (error) {
    // logging the error to the sentry if api call fails
    Sentry.captureException(error);
  }

  return timeStamp;
};

// funcion to get jwt from local storage jwt is required to execute TchAuthGetversionts and 
// get versionTimestamp from BE 
export const getJWTFromLC = () => {
  const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');

  return storedSecurityValues.jwt;
};
