import React from 'react';
import {
  CardContent, Grid2, Grid2Ct, Card, Button, Ty,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';

const Grid = () => {
  // dont allow access to this pagein prod, yet
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  return (
    // usually, the entire page should be wrapped in a container
    <Grid2Ct xs={12}>
      {/* back to ds */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Button to="/ds" component={NavLink} variant="outlined">Back To DS</Button>
          </CardContent>
        </Card>
      </Grid2>

      {/* General Rules */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Ty v="h2">
              General Rules
            </Ty>
            <Ty v="subheading">
              Grid Container
            </Ty>
            <ul>
              <li>
                <Ty v="p">{'Usually, you should use our custom <Grid2Ct> because it contains our default padding and columns. But if you need to use MUI directly, it is <Grid2 container>'}</Ty>
              </li>
              <li>
                <Ty v="p">
                  A container grid CAN be nested directly under another container, youll just
                  need to set the breakpoint values; see the Three Levels - Slightly Simplified
                  example below. But in general we think its best to not do this, to keep code
                  congruent. Meaning, when nesting, grid containers should be children of
                  non-containers; a grid container should not be a child of another grid container
                </Ty>
              </li>
              <li>
                <Ty v="p">
                  {'spacing = {2} is needed on all containers. Our <Grid2Ct> adds this automatically'}
                </Ty>
              </li>
              <li>
                <Ty v="p">
                  {'columns = {12} is needed on all containers. Our <Grid2Ct> adds this automatically'}
                </Ty>
              </li>
            </ul>
            <Ty v="subheading">
              Grid Non-Container
            </Ty>
            <ul>
              <li>
                <Ty v="p">
                  A non-container grid *cannot* be nested directly under another
                  non-container grid. if you do this, spacing is messed up.
                </Ty>
              </li>
            </ul>
            <Ty v="subheading">
              Page Elements
            </Ty>
            <ul>
              <li>
                <Ty v="p">
                  Awlays put your page elements below a non-container grid. Do not put them
                  below a container grid.
                </Ty>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Grid2>

      {/* Grid2Ct */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Ty v="h2">
              Grid2Ct
            </Ty>
            <Ty v="p">
              Grid2Ct is a component we created that outputs this:
            </Ty>
            <Ty v="p">
              {'<'}Grid2 container columns={'{'}12{'}'} spacing={'{'}2{'}'}{'>'}
            </Ty>
            <Ty v="p">
              We use the above so frequently -- in fact columns=12 spacing=2 should be set nearly
              always when creating Grid containers -- that Grid2Ct can save us a lot of typing time.
              It exposes all of the MUI props for Grid, such as sx, so any of them can be used.
              Columns and spacing can also be overwritten.
              We should probably always use it, particularly because it allows us one central place
              spacing={2} is set, and can be changed in future easily if desired.
            </Ty>
            <Grid2Ct sx={{ mt: 3 }}>
              <Grid2 xs={6}>
                <Grid2Ct>
                  <Grid2 xs={6}>
                    <Button variant="outlined" fullWidth>xs=6</Button>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Grid2Ct>
                      <Grid2 xs={6}>
                        <Button variant="outlined" fullWidth>xs=6</Button>
                      </Grid2>
                      <Grid2 xs={6}>
                        <Button variant="outlined" fullWidth>xs=6</Button>
                      </Grid2>
                    </Grid2Ct>
                  </Grid2>
                </Grid2Ct>
              </Grid2>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* main examples */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Ty v="h2">
              Main Examples
            </Ty>
            <Ty v="p">
              Note: for example purposes/so we can see exactly how Grid works, the examples
              below use the MUI Grid container directly. We should use our Grid2Ct custom container,
              as it includes our default spacing and columns.
            </Ty>
            {/* basic full width */}
            <Ty v="p" cp={{ sx: { mt: 2 } }}>Basic</Ty>
            <Grid2 container spacing={2} columns={12}>
              <Grid2 xs={12}>
                <Button variant="outlined" fullWidth>xs=12</Button>
              </Grid2>
            </Grid2>
            <br />

            {/* One Level */}
            {/* 
              - spacing is needed on the container
              - columns=12 is not needed, but we add it to follow our general rules
            */}
            <Ty v="p">One Level</Ty>
            <Grid2 container spacing={2} columns={12}>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
            </Grid2>
            <br />

            {/* Problem: forgot the container */}
            {/* 
              - the grid element one level above this one is a normal grid, not a container.
                this breaks. you cannot make a non-container grid a child of another non-container
                grid
            */}
            <Ty v="p">Problem: forgot the container</Ty>
            <Grid2 xs={6}>
              <Button variant="outlined" fullWidth>xs=6</Button>
            </Grid2>
            <Grid2 xs={6}>
              <Button variant="outlined" fullWidth>xs=6</Button>
            </Grid2>
            <br />

            {/* Problem: forgot to add spacing to the container */}
            <Ty v="p">Problem: forgot to add spacing to the container:</Ty>
            <Grid2 container columns={12}>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
            </Grid2>
            <br />

            {/* Two Levels */}
            {/* - spacing is needed on BOTH containers
                - columns is technically only needed on the inner container, but we have it
                  on both to follow our general rules
            */}
            <Ty v="p">Two Levels</Ty>
            <Grid2 container spacing={2} columns={12}>
              <Grid2 xs={6}>
                <Grid2 container spacing={2} columns={12}>
                  <Grid2 xs={6}>
                    <Button variant="outlined" fullWidth>xs=6</Button>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Button variant="outlined" fullWidth>xs=6</Button>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
            </Grid2>
            <br />

            {/* Three Levels */}
            {/* - spacing is needed on ALL containers
                - columns is technically not needed on the outer container, but we have it
                  everywhere to follow our general rules
            */}
            <Ty v="p">Three Levels</Ty>
            <Grid2 container spacing={2} columns={12}>
              <Grid2 xs={6}>
                <Grid2 container spacing={2} columns={12}>
                  <Grid2 xs={6}>
                    <Button variant="outlined" fullWidth>xs=6</Button>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Grid2 container spacing={2} columns={12}>
                      <Grid2 xs={6}>
                        <Button variant="outlined" fullWidth>xs=6</Button>
                      </Grid2>
                      <Grid2 xs={6}>
                        <Button variant="outlined" fullWidth>xs=6</Button>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
            </Grid2>
            <br />

            {/* Three Levels - Slightly Simplified, But Not Recommended */}
            {/* - this is an example of nesting a container directly below another container,
                which does work fine. you can see it slightly simplifies things by removing
                a non-container element. generally though we think this is a little confusing
                and not a good practice
            */}
            <Ty v="p">Three Levels - Slightly Simplified, But Not Recommended</Ty>
            <Grid2 container spacing={2} columns={12}>
              {/* notice here that container is a child of container. this is allowed */}
              <Grid2 container spacing={2} columns={12} xs={6}>
                <Grid2 xs={6}>
                  <Button variant="outlined" fullWidth>xs=6</Button>
                </Grid2>
                {/* notice here that container is a child of container. this is allowed */}
                <Grid2 container spacing={2} columns={12} xs={6}>
                  <Grid2 xs={6}>
                    <Button variant="outlined" fullWidth>xs=6</Button>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Button variant="outlined" fullWidth>xs=6</Button>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 xs={6}>
                <Button variant="outlined" fullWidth>xs=6</Button>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

export default Grid;
