import { graphql } from 'babel-plugin-relay/macro';

export const TchApptgroupUpdatelayoutMutation = graphql`
  mutation TchApptgroupUpdatelayoutMutation($lessonUuid: String!, $data: String!) {
    tch_apptgroup_updatelayout(lessonUuid: $lessonUuid, data: $data) {
      success
      appt_group_layout {
        id
        layout
      } 
    }
  }
`;
