import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { commitLocalUpdate, useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';
import {
  Grid2, ScrollArea, Backdrop, LottiePlayer
} from '@languageconvo/wcl';
import {
  MainContentContainer,
  MainContentBackground, InnerContentContainer, MaxWidthContainer
} from './Dashboard.styles';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// hooks
import useAuth from '../../hooks/useAuth';
// config
import AppEnvironment from '../../relay/AppEnvironment';
import { NavbarContainer } from './navbar/NavbarContainer';
import { DashboardHeader } from './header/Header';
import { setCookieForCurrentUser } from '../../utils/setUserState';
import { getDataToLCStorage } from '../../utils/lcStorage';
import SessionTimeoutAnimation from '../../assets/sessiontimeoutanimation.json';
import { verifyJWTMutation } from './relay/VerifyJWT';
import { handleLogout } from '../../utils';
import { RelayAppSettingsVals } from '../../common/relay/clientschema/RelayAppSettingsTypes';
import { getVersionTimeStamp } from '../../utils/getVersionTimeStamp';
import { readLocalVersionAndDoComparisonWithDBVersion } from '../../common/utils/versionmanager/versionManager';

export const DashboardLayout = () => {
  // mutation to verify JWT.
  const [JWTVerificationMutation] = useMutation(verifyJWTMutation);
  const [open, setOpen] = useState(false);
  const {
    setAuth, logoutInProgress, setLogoutInProgress
  } = useAuth();
  const [noScroll, setNoScroll] = useState(false);
  const navigate = useNavigate();
  const { isCollapse } = useCollapseDrawer();
  // In react 18 useEffect (componentDidMount works twice) which causes to fail our
  // mutation as styched token is available only once.
  // React 18 used reuseable state feature, which mean, in between the initial mount and unMount at
  // the end of the lifecycle, component can be remounted multiple time
  // To restrict useEffect work only once, there are 2 solutions.
  // 1- Add cleanUp function in the useEffect so it executes only once.
  // 2- We are using Refernce, which will execute only once and save our ass.
  // 3- this will execute only on page refresh as this is hook.
  useEffect(() => {
    // If context (isAuthenticated) is not present then there will be 2 cases..
    // 1- User is logOut.
    // 2- User refreshed the page.
    const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');
    // if jwt is present in localStorage, it means, page refreshed and we need
    // to check validity of jwt, if not present in localStorage it means, user should
    // perform login to access the pages...
    if (storedSecurityValues?.jwt) {
      JWTVerificationMutation({
        variables: { jwt: storedSecurityValues?.jwt || '' },
        onCompleted(res: any) {
          setCookieForCurrentUser(res?.resources_auth_verify_jwt, setAuth, 'lc_tchsecurity');
        },
        onError(err: any) {
          Sentry.captureException(err);
          if (err.message === 'invalid-jwt') {
            handleLogout(setLogoutInProgress);
          }
        },
      });
    } else {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Set up our relay client schema extension 
      Note that we do this before other effects, as it *might* cause strange behaviors if
      this schema does not exist when it's needed by other components
      IMPORTANT: this should run first, so the client schema is available once the mutation
        for getting trial data completes. useLayoutEffect should accomplish that
  */
  useLayoutEffect(() => {
    relayClientSchemaExtensionsSetup();
  }, []);

  // #region for versionSynchronization

  // the app version timestamp from our db
  const versionFromDB: any = useLoaderData();

  useEffect(() => {
  // check if the version from the database is not available
    if (!versionFromDB) {
    // Log an error to Sentry if no version is found in the database
      Sentry.captureException(
        new Error('IMPORTANT - should never happen. No version found in db')
      );
      return;
    }

    // if versionFromDB is available, compare it with the local version
    // for more detail refer comment on `readLocalVersionAndDoComparisonWithDBVersion`
    readLocalVersionAndDoComparisonWithDBVersion(versionFromDB);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionFromDB]);

  // in this useEffect we attempt to get a new versionTimeStamp every 6 minutes
  //  and check the current version of the app and reload the page 
  // if the user has an outdated version
  useEffect(() => {
    const intervalCall = setInterval(async () => {
      // api call to get versionTimeStamp
      const result: any = await getVersionTimeStamp();

      // as long as our api call was successful, check app version and refresh page to get
      // the new version if the user has an outdated version
      if (result !== null) {
        readLocalVersionAndDoComparisonWithDBVersion(result);
      }
    }, 6 * 60 * 1000);

    return () => {
      // clean up, for clearing the interval
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  const MainContent = (
    <MainContentBackground collapseClick={isCollapse}>
      <MaxWidthContainer>
        <InnerContentContainer>
          <Outlet context={{ setNoScroll }} />
        </InnerContentContainer>
      </MaxWidthContainer>
    </MainContentBackground>
  );

  return (
    <Grid2 container>
      {/* displaying a backdrop in case of logout reset cookie api call is inprogress. */}
      <Backdrop open={logoutInProgress}>
        <LottiePlayer animationSrc={SessionTimeoutAnimation} animationHeight={300} />
      </Backdrop>
      {/* header/appbar */}
      <DashboardHeader
        onOpenSidebar={() => setOpen(true)}
      />
      {/* sidebar/drawer */}
      <Grid2 display="flex" width="100%">
        <NavbarContainer isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        {/* main content */}
        <MainContentContainer>
          {noScroll ? MainContent : (
            <ScrollArea>
              {MainContent}
            </ScrollArea>
          )}
        </MainContentContainer>
      </Grid2>
    </Grid2>
  );
};

/*  Create our custom relay client schema extensions
      - Create the root record
      - Create the microphone node (no data in it yet though)
      - Create the camera node, and set some initial values
*/
const relayClientSchemaExtensionsSetup = () => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const root = store.getRoot();

    // root record
    let record = store.get(RelayAppSettingsVals.idVal);
    if (!record) {
      record = store.create(RelayAppSettingsVals.idVal, RelayAppSettingsVals.name);
      record.setValue(RelayAppSettingsVals.idVal, RelayAppSettingsVals.idName);
    }
    root.setLinkedRecord(record, RelayAppSettingsVals.name);

    // microphone settings. create the basic record, and set the following values and initial values
    // microphone: {
    //   current: {
    //     micId: null,
    //     title: null,
    //     isMuted: false,
    //   },
    //   all: {
    //    edges: []
    //   }
    // }
    // once the user load the groupLessons page we will check the local storage and set
    // original values instead of these initial values.

    // camera settings. create the basic record, and set the following values and initial values
    // camera: {
    //   current: {
    //     camId: null,
    //     title: null,
    //     isCameraOff: false,
    //   },
    //   all: {
    //    edges: []
    //   }
    // }
    // once the user loads the groupLessons page we will check the local storage and set
    // original values instead of these initial values.

    // record for current microphone being used
    let recordforCurrentMic = store.get(RelayAppSettingsVals.currentMicIdVal);
    if (!recordforCurrentMic) {
      recordforCurrentMic = store.create(
        RelayAppSettingsVals.currentMicIdVal,
        RelayAppSettingsVals.currentMicType
      );

      recordforCurrentMic.setValue(null, RelayAppSettingsVals.currenMicrophoneIdName);
      recordforCurrentMic.setValue(null, RelayAppSettingsVals.currentMicrophoneTitleName);
      recordforCurrentMic.setValue(false, RelayAppSettingsVals.currentMicrohponeMutedName);
    }

    // record for current camera being used
    let recordforCurrentCamera = store.get(RelayAppSettingsVals.currentCamIdVal);
    if (!recordforCurrentCamera) {
      recordforCurrentCamera = store.create(
        RelayAppSettingsVals.currentCamIdVal,
        RelayAppSettingsVals.currentCamType
      );

      recordforCurrentCamera.setValue(null, RelayAppSettingsVals.currenCameraIdName);
      recordforCurrentCamera.setValue(null, RelayAppSettingsVals.currentCameraTitleName);
      recordforCurrentCamera.setValue(false, RelayAppSettingsVals.currentCameraOnName);
    }

    // record for all available microphones
    let recordforAllMic = store.get(RelayAppSettingsVals.allMicIdVal);
    if (!recordforAllMic) {
      recordforAllMic = store.create(
        RelayAppSettingsVals.allMicIdVal,
        RelayAppSettingsVals.allMicType
      );

      recordforAllMic.setValue(RelayAppSettingsVals.allMicIdVal, RelayAppSettingsVals.allMicIdName);
    }
    recordforAllMic.setLinkedRecords([], 'edges');

    // record fot all available camera
    let recordForAllCamera = store.get(RelayAppSettingsVals.allCamIdVal);
    if (!recordForAllCamera) {
      recordForAllCamera = store.create(
        RelayAppSettingsVals.allCamIdVal,
        RelayAppSettingsVals.allCamType
      );

      recordForAllCamera.setValue(
        RelayAppSettingsVals.allCamIdVal,
        RelayAppSettingsVals.allMicIdName
      );
    }
    recordForAllCamera.setLinkedRecords([], 'edges');

    // main microhpne object
    let microphoneRecord = store.get(RelayAppSettingsVals.micrphoneDetailsIdVal);
    if (!microphoneRecord) {
      microphoneRecord = store.create(
        RelayAppSettingsVals.micrphoneDetailsIdVal,
        RelayAppSettingsVals.micrphoneDetailsType
      );

      microphoneRecord.setValue(
        RelayAppSettingsVals.micrphoneDetailsIdVal,
        RelayAppSettingsVals.microphoneDetailsIdName
      );
    }

    let cameraRecord = store.get(RelayAppSettingsVals.cameraDetailsIdVal);
    if (!cameraRecord) {
      cameraRecord = store.create(
        RelayAppSettingsVals.cameraDetailsIdVal,
        RelayAppSettingsVals.cameraDetailsType
      );

      cameraRecord.setValue(
        RelayAppSettingsVals.cameraDetailsIdVal,
        RelayAppSettingsVals.cameraDetailsIdName
      );
    }

    microphoneRecord.setLinkedRecord(recordforCurrentMic, RelayAppSettingsVals.currentMicName);
    // setting current camera default values in relay store
    cameraRecord.setLinkedRecord(recordforCurrentCamera, RelayAppSettingsVals.currentCamName);

    microphoneRecord.setLinkedRecord(recordforAllMic, RelayAppSettingsVals.allMicName);
    // setting all available camera in relay store
    cameraRecord.setLinkedRecord(recordForAllCamera, RelayAppSettingsVals.allCamName);

    record.setLinkedRecord(microphoneRecord, RelayAppSettingsVals.microPhoneDetailsName);
    // appending camera Inside relayAppSetting Obj
    record.setLinkedRecord(cameraRecord, RelayAppSettingsVals.cameraDetailsName);
  });
};
