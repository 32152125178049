import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { VonageProvider } from './context/VonageProvider';
import { Step1DeviceAccess } from './components/setup/Step1DeviceAccess';
import { MainView } from './components/views/MainView';
import { CustomErrBoundary } from './components/views/CustomErrBoundary';
import { Modalview1Setup } from './components/modalview/Modalview1Setup';

export const GroupClassroom = () => {
  // default, 100, is a loading screen
  const [mainView, setMainView] = useState(100);

  // #region notif modal

  // modal state. true=open, false=closed. closed by default
  const [modalviewState, setModalviewState] = useState<boolean>(false);
  const handleModalviewClose = () => {
    setModalviewState(false);
  };

  // determines the contents of the modal
  const [modalviewContents, setModalviewContents] = useState<number>(0);

  // #endregion

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <CustomErrBoundary err={error} />
      )}
    >
      <VonageProvider
        setmodalviewContents={setModalviewContents}
        setmodalviewState={setModalviewState}
      >
        {/* this displays things that are *not* the main classroom, such as loading screen and
        some error messages. it's hidden when the classroom is shown */}
        <MainView view={mainView} />

        {/* setting up and running the classroom */}
        <Step1DeviceAccess
          setMainView={setMainView}
          setmodalviewContents={setModalviewContents}
          setmodalviewState={setModalviewState}
        />

        {/* We use this modal to display notifs to the user about things like
            they don't have a mic plugged in, there was a problem connecting to
            a stream, etc. It's a bit of a universal modal for various use cases
        */}
        <Modalview1Setup
          modalstate={modalviewState}
          modalcontents={modalviewContents}
          handleclose={handleModalviewClose}
        />
      </VonageProvider>
    </Sentry.ErrorBoundary>
  );
};
