import { useMemo } from 'react';
import { useFragment } from 'react-relay';
import { GetEnrolledStudentsFragment } from '../relay/GetEnrolledStudents';
import { GetEnrolledStudentsFragment$key } from '../relay/__generated__/GetEnrolledStudentsFragment.graphql';

export const useStudentsInfo = (stdFragmentRef: GetEnrolledStudentsFragment$key) => {
  // Reading student data from the store via a fragment 
  // because we need to show students in Select Drop Down 
  const studentData: any = useFragment(GetEnrolledStudentsFragment, stdFragmentRef);
  const studentInfo = studentData?.appt_group_connection?.edges[0]?.node?.appt_group_reservations;

  // memoize the computed student details to avoid unnecessary recomputations.
  // Why: We use useMemo to ensure that we only recompute the students array when 
  // the underlying data (studentInfo) changes. This improves performance by preventing
  //  unnecessary re-renders of the component.
  const students = useMemo(() => {
    // checking if studentInfo is an array.
    // Why: The fragment might return an empty or undefined value, so we need to ensure that
    // studentInfo is an array before attempting to map over it. This prevents runtime errors.
    if (Array.isArray(studentInfo)) {
      return studentInfo.map((std) => {
        const stdPublicInfo = std.users.user_publicinfo;
        const firstName = stdPublicInfo.display_firstname;
        const lastName = stdPublicInfo.display_lastname;
        const { uuid } = stdPublicInfo;

        // Concatenating first and last name
        const privateFirstName = std.users.first_name;
        const privateLastName = std.users.last_name;
        const privateName = `${privateFirstName} ${privateLastName}`.trim();
        const publicName = `${firstName} ${lastName}`.trim();
        const name = publicName !== 'null null' ? publicName : privateName;

        // Student hand_raised value and timestamp
        const handRaisedValue = std.hand_raised;
        const handRaisedTS = std.hand_raised_ts;

        return {
          name, uuid, handRaisedValue, handRaisedTS
        };
      });
    }
    // Return an empty array if studentInfo is not an array
    return [];
  }, [studentInfo]);

  // return the memoized students array.
  return students;
};
