import { Dispatch, SetStateAction } from 'react';
import { Session } from '@opentok/client';
import { PreviousRoomLayoutDataType } from '../context/Types';
import { CustomMutationDataLessonDetailFragment$data } from '../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
/**
 * 
 * @param roomLayoutData 
 * @param initialPageLoadData 
 * @returns return the updated session id and token based on current room View
 * It picks the things form initial lesson data
 */
export const getSessionToConnectWith = (roomLayoutData: any, initialPageLoadData: any) => {
  const audioVideo = initialPageLoadData.av;
  const streamingTo = roomLayoutData.teacherStreamingTo[0];
  // teacher only mode.
  if (roomLayoutData.roomView === 1 && streamingTo.entity === 1) {
    const sessionId = audioVideo.room1?.session;
    const token = audioVideo.room1?.token;
    return { sessionId, token };
  }
  // group chat mode.
  if (roomLayoutData.roomView === 2 && streamingTo.entity === 2) {
    const { studentGroupRooms } = audioVideo;
    // connect to group 1.
    if (streamingTo.value === studentGroupRooms[0].uuid) {
      const sessionId = studentGroupRooms[0].session;
      const { token } = studentGroupRooms[0];
      return { sessionId, token };
    }
    // connect to group 2.
    if (streamingTo.value === studentGroupRooms[1].uuid) {
      const sessionId = studentGroupRooms[1].session;
      const { token } = studentGroupRooms[1];
      return { sessionId, token };
    }
  }
  // connect to nobody. In this case streamingTo.entity = 4.
  return null;
};

/**
 * 
 * @param startVonage 
 * @param previousRoomLayoutData 
 * @param RoomLayoutNode 
 * @param initialClassroomData 
 * @param vonageSession 
 * @param disconnectFromSessionAndConnectToNewSession 
 * @param setPreviousRoomLayoutData 
 * @param InitVonageAndSetPreviousStates 
 * @param isTeacherScreensharing 
 * @param stopScreenSharingStream 
 * responsible for connecting to new vonage session and disconnect form old session
 * and set the previous session detail for later comparison. comparison is important
 * becasue, we don't want to connect with same session again and again.
 */
export const initializeNewSession = (
  startVonage: boolean,
  previousRoomLayoutData: any,
  RoomLayoutNode: any,
  initialClassroomData: CustomMutationDataLessonDetailFragment$data,
  vonageSession: Session | undefined,
  disconnectFromSessionAndConnectToNewSession: (
    _arg1: {
      sessionId: string,
      token: string,
    }
  ) => void,
  setPreviousRoomLayoutData: Dispatch<SetStateAction<PreviousRoomLayoutDataType | undefined>>,
  initializeSession: any,
  isTeacherScreensharing: boolean,
  stopScreenSharingStream: () => void
) => {
  // Delay the execution of VonageTesting.initAndConnect
  if (startVonage) {
    // getting new session id and token.
    const newSessionCredentials: any = getSessionToConnectWith(
      RoomLayoutNode,
      initialClassroomData
    );

    /** There are two main situations here:
     *  1. Pageload
     *    On pageload, previousRoomLayoutData will be undefined. In this case we init vonage
     *    and set up some initial state data
     *  2. After pageload, layout data changing
     *    In this situation, we may -- or may not -- need to connect/disconnect to a
     *    vonage session. To know whether we need to connect, init, etc. we compare
     *    the previousRoomLayoutData to the new layout data; that will tell us if there is
     *    a change that warrants doing something. For example, if the teacher switched
     *    from group chat to lecture mode, then the teacher needs to disconnect from the
     *    group session and connect to the new lecture session.
     */
    if (previousRoomLayoutData === undefined) {
      if (newSessionCredentials !== null) {
        initializeSession(newSessionCredentials.sessionId);
      }
    } else {
      /**
       * connect to new session only if
       * 1- teacher changes the roomView (i-e teacher only to group chat and vice versa)
       * 2- teacher wanted to connect with either group 1 or 2
       */
      const previousValues = previousRoomLayoutData.teacherStreamingTo[0];
      const currentValues = RoomLayoutNode.teacherStreamingTo[0];
      if (
        previousRoomLayoutData.roomView !== RoomLayoutNode.roomView
      || previousValues.entity !== currentValues.entity
      || previousValues.value !== currentValues.value
      ) {
      // In case of "speaking to nobody" there will be no vonageSession, so don't need to
      // disconnect, only initilize.
        if (vonageSession) {
          disconnectFromSessionAndConnectToNewSession(newSessionCredentials);
        } else if (newSessionCredentials !== null) {
          initializeSession(newSessionCredentials.sessionId);
        }
        if (isTeacherScreensharing) {
          stopScreenSharingStream();
        }
      }
    }

    // setting state, so in next layout change this will be helpful for comparison
    setPreviousRoomLayoutData({
      roomView: RoomLayoutNode.roomView,
      teacherStreamingTo: RoomLayoutNode.teacherStreamingTo,
    });
  }
};
