export const handleJWTCookie = async (payload: {action: string, data?: {jwt: string}}) => {
  const response = await fetch(`${process.env.REACT_APP_LEGACYURL}/studenthome/external/`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      domain: 'external',
      ...payload
    }),
  });

  const jsonResponse = await response.json();

  if (Array.isArray(jsonResponse.errors)) {
    throw new Error(
      `Error setting Cookie: ${JSON.stringify(
        jsonResponse.errors,
      )}`,
    );
  }

  return jsonResponse;
};
