import React from 'react';
import {
  CardContent, Grid2, Grid2Ct, Card, Button, Ty, Divider, Link
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';

// eslint-disable-next-line
const lorem="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed. Urna porttitor rhoncus dolor purus. Risus nec feugiat in fermentum posuere urna nec. In hendrerit gravida rutrum quisque non tellus orci ac. Malesuada fames ac turpis egestas. Eu mi bibendum neque egestas congue quisque egestas. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Tellus elementum sagittis vitae et leo duis ut diam.";
// eslint-disable-next-line
const loremShort="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed. Urna porttitor rhoncus dolor purus."

const Typography = () => {
  // dont allow access to this pagein prod, yet
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  return (
    <Grid2Ct>
      {/* back to ds */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Button to="/ds" component={NavLink} variant="outlined">Back To DS</Button>
          </CardContent>
        </Card>
      </Grid2>

      {/* Heading H1 */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h1">
                  Heading H1
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* Heading H2 */}
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Heading H2
                </Ty>
                <Ty v="p">
                  {/* eslint-disable-next-line */}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed. Urna porttitor rhoncus dolor purus. Risus nec feugiat in fermentum posuere urna nec. In hendrerit gravida rutrum quisque non tellus orci ac. Malesuada fames ac turpis egestas. Eu mi bibendum neque egestas congue quisque egestas. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Tellus elementum sagittis vitae et leo duis ut diam.
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Heading H2
                </Ty>
                <Ty v="p">
                  {/* eslint-disable-next-line */}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed. Urna porttitor rhoncus dolor purus. Risus nec feugiat in fermentum posuere urna nec. In hendrerit gravida rutrum quisque non tellus orci ac. Malesuada fames ac turpis egestas. Eu mi bibendum neque egestas congue quisque egestas. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Tellus elementum sagittis vitae et leo duis ut diam.
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* Dividers */}
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Dividers
                </Ty>
                <Ty v="p">
                  {lorem}
                  Regular divider:
                </Ty>
                <Divider cp={{ sx: { mt: 3, mb: 3 } }} />
                <Ty v="p">
                  {lorem}
                  Thin divider:
                </Ty>
                <Divider cp={{ sx: { mt: 3, mb: 3 } }} strength="thin" />
                <Ty v="p">
                  {loremShort}
                  Bold divider:
                </Ty>
                <Divider cp={{ sx: { mt: 3, mb: 3 } }} strength="bold" />
                <Ty v="p">
                  {loremShort}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
      {/* Subheaders */}
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Subheaders
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
                <Ty v="subheading">
                  Section Two
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
                <Ty v="subheading">
                  Section Three
                </Ty>
                <Ty v="p">
                  {loremShort}
                </Ty>
                <Ty v="subheading">
                  Section Four
                </Ty>
                <Ty v="p">
                  {loremShort}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* Links */}
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  <Link>Links</Link>
                </Ty>
                <Ty v="p">
                  {/* eslint-disable-next-line */}
                  Lorem ipsum dolor sit amet, <Link>consectetur adipiscing</Link> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed. Urna porttitor rhoncus dolor purus. Risus nec feugiat in fermentum posuere urna nec. In <Link>hendrerit gravida</Link> rutrum quisque non tellus orci ac. Malesuada fames ac turpis <Link>egestas</Link>. Eu mi bibendum neque egestas congue quisque egestas. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Interdum velit laoreet id donec ultrices <Link>tincidunt arcu non</Link> sodales. Tellus elementum sagittis <Link>vitae et leo duis</Link> ut diam.
                </Ty>
                <Ty v="subheading">
                  <Link>Section Two</Link>
                </Ty>
                <Ty v="p">
                  {loremShort}
                </Ty>
                <Ty v="subheading">
                  <Link>Section Three</Link>
                </Ty>
                <Ty v="p">
                  {loremShort}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  <Link color="secondary">Links</Link>
                </Ty>
                <Ty v="p">
                  {/* eslint-disable-next-line */}
                  Lorem ipsum dolor sit amet, <Link color="secondary">consectetur adipiscing</Link> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis massa sed elementum tempus egestas sed. Urna porttitor rhoncus dolor purus. Risus nec feugiat in fermentum posuere urna nec. In <Link color="secondary">hendrerit gravida</Link> rutrum quisque non tellus orci ac. Malesuada fames ac turpis <Link color="secondary">egestas</Link>. Eu mi bibendum neque egestas congue quisque egestas. Diam donec adipiscing tristique risus nec feugiat in fermentum posuere. Interdum velit laoreet id donec ultrices <Link color="secondary">tincidunt arcu non</Link> sodales. Tellus elementum sagittis <Link color="secondary">vitae et leo duis</Link> ut diam.
                </Ty>
                <Ty v="subheading">
                  Section Two
                </Ty>
                <Ty v="p">
                  {loremShort}
                </Ty>
                <Ty v="subheading">
                  Section Three
                </Ty>
                <Ty v="p">
                  {loremShort}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* Long Paragraphs */}
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Long Paragraphs
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 xs={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Long Paragraphs
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

export default Typography;
