import React from 'react';
import {
  Stack, Ic, Link, useTheme,
} from '@languageconvo/wcl';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
// components, styles
// import { AccountPopover } from './components/accountpopover/AccountPopover';
// import { LanguagePopover } from './components/languagepopover/LanguagePopover';
// import { NotificationPopover } from './NotificationPopover';
import * as S from './Header.styles';

type Props = {
  onOpenSidebar: () => void;
};

export const DashboardHeader = ({
  onOpenSidebar,
}: Props) => {
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    // RootStyle overriding MUI appbar with some custom styling, instead of messing up
    // here with sx prop we made a styled component and used it.
    <S.RootStyle isDesktop={isDesktop}>
      <S.ToolBar>
        <Stack minWidth={{ lg: theme?.ourTheme.components.navbar.navbarWidthExpandedStack }} direction="row" alignItems="center" justifyContent="space-between">
          {isDesktop && (
            <Link onClick={() => {
              navigate('/home');
            }}
            >
              <S.Logo src={`${process.env.REACT_APP_CDNURL}/common/logo/logo.svg`} data-testid="logo" alt="Logo" width="160" />
            </Link>
          )}

          {/* hamburger icon, to open the drawer. only on small screens */}
          {!isDesktop && (
          <S.Hamburger
            onClick={onOpenSidebar}
            onKeyDown={onOpenSidebar}
            role="presentation"
          >
            <Ic iconName="bars" size="rel-lg" />
          </S.Hamburger>
          )}
        </Stack>
        {/* moving rest of the below components to right side of header */}
        <S.HeaderSpace />
        {/* <Grid2 container spacing={2} columnSpacing={2}>
          <Grid2 xs={4}>
            <NotificationPopover />
          </Grid2>
          <Grid2 xs={4}>
            <LanguagePopover />
          </Grid2>
          <Grid2 xs={4}>
            { Object.keys(queryReference).length
              ? <AccountPopover queryReference={queryReference || {}} />
              : <Skeleton width={40} height={40} variant="rectangular" /> }
          </Grid2>
        </Grid2> */}
      </S.ToolBar>
    </S.RootStyle>
  );
};
