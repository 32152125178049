/**
 * @generated SignedSource<<a139fd9a5598685afa63812131c9a04c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetEnrolledStudentsFragment$data = {
  readonly appt_group_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appt_group_reservations: ReadonlyArray<{
          readonly hand_raised: any | null;
          readonly hand_raised_ts: any | null;
          readonly users: {
            readonly appt_group_tchblocks: ReadonlyArray<{
              readonly users: {
                readonly user_publicinfo: {
                  readonly uuid: any;
                } | null;
              };
            }>;
            readonly first_name: string | null;
            readonly last_name: string | null;
            readonly user_publicinfo: {
              readonly appt_group_stublocks: ReadonlyArray<{
                readonly uuid: any;
              }>;
              readonly display_firstname: string | null;
              readonly display_lastname: any | null;
              readonly displayname_approved: any;
              readonly uuid: any;
            } | null;
          };
        }>;
      };
    }>;
  };
  readonly " $fragmentType": "GetEnrolledStudentsFragment";
};
export type GetEnrolledStudentsFragment$key = {
  readonly " $data"?: GetEnrolledStudentsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetEnrolledStudentsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "lessonUuid"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetEnrolledStudentsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_eq",
                  "variableName": "lessonUuid"
                }
              ],
              "kind": "ObjectValue",
              "name": "uuid"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "appt_groupConnection",
      "kind": "LinkedField",
      "name": "appt_group_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "appt_groupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "appt_group",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "appt_group_reservations",
                  "kind": "LinkedField",
                  "name": "appt_group_reservations",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hand_raised",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hand_raised_ts",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "users",
                      "kind": "LinkedField",
                      "name": "users",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "first_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "last_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user_publicinfo",
                          "kind": "LinkedField",
                          "name": "user_publicinfo",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "display_firstname",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "display_lastname",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "displayname_approved",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "appt_group_stublocks",
                              "kind": "LinkedField",
                              "name": "appt_group_stublocks",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": "uuid",
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "other_user_publicuuid",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "appt_group_tchblocks",
                          "kind": "LinkedField",
                          "name": "appt_group_tchblocks",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "users",
                              "kind": "LinkedField",
                              "name": "users",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "user_publicinfo",
                                  "kind": "LinkedField",
                                  "name": "user_publicinfo",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "subscription_root",
  "abstractKey": null
};
})();

(node as any).hash = "5f633c49ff0314222616bdf33038b870";

export default node;
