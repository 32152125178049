// responsible for Header height.
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 77,
  DASHBOARD_DESKTOP_HEIGHT: 83
};

// responsible for Header width and elements height.
export const NAVBAR = {
  BASE_WIDTH: 260,
  // // comes from wcl now
  // DASHBOARD_WIDTH: 260,
  // comes from wcl now
  // DASHBOARD_COLLAPSE_WIDTH: 96,
  // comes from wcl now
  // DASHBOARD_ITEM_ROOT_HEIGHT: 54,
  DASHBOARD_ITEM_SUB_HEIGHT: 40, // 40
};
