const graphql = require('babel-plugin-relay/macro');

export const VerifyPasscodeMutation = graphql`
  mutation VerifyPasscodeMutation($email: String!, , $passcode: String!) {
    resources_auth_verify_passcode (email: $email, passcode: $passcode) {
        data {
            email
            image
            name
            resourceId
            languageId
            timezone
          }
          jwt
    }
  }
`;
