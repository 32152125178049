/**
 * @generated SignedSource<<333d9541de44910960db4127f30ef918>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryDataRoomLayoutFragment$data = {
  readonly appt_group_layout: {
    readonly id: string;
    readonly layout: string;
  } | null;
  readonly " $fragmentType": "QueryDataRoomLayoutFragment";
};
export type QueryDataRoomLayoutFragment$key = {
  readonly " $data"?: QueryDataRoomLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QueryDataRoomLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QueryDataRoomLayoutFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "appt_group_layout",
      "kind": "LinkedField",
      "name": "appt_group_layout",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "appt_group",
  "abstractKey": null
};

(node as any).hash = "43b9e1f7227da1874698ba683f6483db";

export default node;
