/**
 * @generated SignedSource<<d6fa96bd8462671aad09be0b24519470>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyAuthTokenMutation$variables = {
  authToken: string;
};
export type VerifyAuthTokenMutation$data = {
  readonly resources_auth_verify_token: {
    readonly data: {
      readonly email: string;
      readonly image: string;
      readonly languageId: number;
      readonly name: string;
      readonly resourceId: number;
      readonly timezone: string;
    };
    readonly jwt: string;
  } | null;
};
export type VerifyAuthTokenMutation = {
  response: VerifyAuthTokenMutation$data;
  variables: VerifyAuthTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "authToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "authToken",
        "variableName": "authToken"
      }
    ],
    "concreteType": "ResourcesAuthVerifyToken",
    "kind": "LinkedField",
    "name": "resources_auth_verify_token",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResourcesAuthVerifyTokenData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "languageId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyAuthTokenMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyAuthTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5032960919a91919b3b75e6c3fd61488",
    "id": null,
    "metadata": {},
    "name": "VerifyAuthTokenMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyAuthTokenMutation(\n  $authToken: String!\n) {\n  resources_auth_verify_token(authToken: $authToken) {\n    jwt\n    data {\n      resourceId\n      languageId\n      email\n      image\n      name\n      timezone\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0217749c1bff3df5700653ba48914b96";

export default node;
