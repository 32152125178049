import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Card, CardContent, Grid2, Grid2Ct, CardSmallColor, IcSvgList, Dialog, Ty, Button, Box, Ic,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { useUpdateRoomLayout } from '../../hooks/useUpdateRoomLayout';
import { ClassControlsSpeakingTo } from './ClassControlsSpeakingTo';
import { ClassControlsScreenshare } from './ClassControlsScreenshare';
import { QueryDataRoomLayoutFragment } from '../../relay/QueryData';
import { QueryDataRoomLayoutFragment$key } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';
import { CustomMutationDataLessonDetailFragment } from '../../relay/CustomMutationData';
import { CustomMutationDataLessonDetailFragment$key } from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';

interface Props {
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key,
  layoutFragmentRef: QueryDataRoomLayoutFragment$key;
}

export const ClassControls = ({
  classroomDataFragmentRef,
  layoutFragmentRef
}: Props) => {
  // #region reading data from fragments to decide to whom teacher is speaking to

  const lessonDetailsData = useFragment(
    CustomMutationDataLessonDetailFragment,
    classroomDataFragmentRef,
  );
  const roomLayoutData: any = useFragment(QueryDataRoomLayoutFragment, layoutFragmentRef);
  const layoutData = JSON.parse(roomLayoutData.appt_group_layout.layout);
  // we do not allow the teacher to change the room mode to group chat until they have put
  // at least one student in a group
  const isGroupChatEnabled = layoutData.rooms.studentGroupRooms.length > 0;

  // #endregion

  // #region mutating room layout/handling changes in it

  /**
   * there are multiple places from where we are mutating room layout data, so
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { loading, mutateRoomLayout } = useUpdateRoomLayout();

  /* When teacher changes roomView, this function runs. It builds the new layout data based on
    the teachers selection, and then attempts to mutate the database layout data
  */
  const updateRoomViewForLayout = (
    uuid: string,
    roomLayoutHash: any,
    isTeacherOnlyButton: boolean
  ) => {
    handleModalClose();
    // No need to set session value with entity. Reason: In both apps (UIS, UIT) we have generic
    // getSessionToConnectWith which is taking layout data and initial classroom data and check
    // to which session users need to connect with, it return that session.
    const updatedHash = isTeacherOnlyButton
      ? { ...roomLayoutHash, roomView: 1, teacherStreamingTo: [{ entity: 1, value: null }] }
      : {
        ...roomLayoutHash,
        roomView: 2,
        teacherStreamingTo: [{ entity: 4, value: null }],
        rooms: {
          ...roomLayoutHash.rooms,
          studentGroupRooms: [
            {
              students: roomLayoutHash.rooms.studentGroupRooms[0].students,
              uuid: lessonDetailsData.av.studentGroupRooms[0]!.uuid
            },
            {
              students: roomLayoutHash.rooms.studentGroupRooms[1].students,
              uuid: lessonDetailsData.av.studentGroupRooms[1]!.uuid
            }
          ]
        }
      };

    // mutate the layout data in the db
    mutateRoomLayout(uuid, updatedHash, isTeacherOnlyButton);
  };

  // #endregion

  // #region room mode small card

  // the text and icon inside of the small card
  const [modeText, setModeText] = useState('');
  const [modeIcon, setModeIcon] = useState(IcSvgList.desk1);

  // we use this to set the contents of the modal that appears when the teacher clicks on the
  // room mode small card. the possible values are:
  //  1 = loading
  //  2 = currently in lecture, can switch to group chat
  //  3 = current in group chat, can switch to lecture
  //  4 = current in lecture, but CANNOT switch to group chat. the teacher first needs to put
  //      at least one student into a group
  const [modeModalContents, setModeModalContents] = useState(1);

  // set icon, text, and modal contents
  useEffect(() => {
    if (loading) {
      setModeText('Switching...');
      setModeModalContents(1);

      // current room mode is lecture
    } else if (layoutData.roomView === 1) {
      setModeText('Lecture');
      setModeIcon(IcSvgList.desk1);

      // we don't allow teachers to switch to group chat until they have moved at least one student
      // into a group. otherwise switching to group chat is disabled
      if (isGroupChatEnabled) {
        setModeModalContents(2);
      } else {
        setModeModalContents(4);
      }

      // current room mode is group chat
    } else if (layoutData.roomView === 2) {
      setModeText('Group Chat');
      setModeIcon(IcSvgList.group1);
      setModeModalContents(3);
    }
  }, [loading, layoutData.roomView, isGroupChatEnabled]);

  // opening/closing the modal
  const [modalState, setModalState] = useState<boolean>(false);
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      {/* main controls card */}
      <Grid2Ct>
        <Grid2 xs={12}>
          <Card>
            <CardContent>
              <Grid2Ct>
                {/* Room Mode */}
                <Grid2 xs={12} lg={4}>
                  <Grid2Ct>
                    <Grid2 xs={12} md={10} mdOffset={1}>
                      <CardSmallColor
                        smallerfont
                        icon={modeIcon}
                        text={modeText}
                        color="accentGreen1"
                        hovercursor="pointer"
                        onClick={handleModalOpen}
                      />
                    </Grid2>
                  </Grid2Ct>
                </Grid2>

                {/* Speaking To */}
                <Grid2 xs={12} lg={4}>
                  <Grid2Ct>
                    <Grid2 xs={12} md={10} mdOffset={1}>
                      <ClassControlsSpeakingTo
                        classroomDataFragmentRef={classroomDataFragmentRef}
                        layoutFragmentRef={layoutFragmentRef}
                      />
                    </Grid2>
                  </Grid2Ct>
                </Grid2>

                {/* Screen Sharing icon */}
                <Grid2 xs={12} lg={4}>
                  <Grid2Ct>
                    <Grid2 xs={12} md={10} mdOffset={1}>
                      <ClassControlsScreenshare layoutFragmentRef={layoutFragmentRef} />
                    </Grid2>
                  </Grid2Ct>
                </Grid2>
              </Grid2Ct>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2Ct>

      {/* modal when teacher clicks on the room mode small card (lecture / group chat) */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="xs"
        color="accentGreen1"
      >
        <ModalContents
          contents={modeModalContents}
          lsnuuid={lessonDetailsData.lessonUuid}
          layoutDt={layoutData}
          updateLayout={updateRoomViewForLayout}
        />
      </Dialog>
    </>
  );
};

// #region room mode modal

// this modal appears when the teacher clicks on the room mode small card
// contents details
//  1 = loading
//  2 = currently in lecture, can switch to group chat
//  3 = current in group chat, can switch to lecture
//  4 = current in lecture, but CANNOT switch to group chat. the teacher first needs to put
//      at least one student into a group
interface ModalContentsProps {
  contents: number
  lsnuuid: string
  layoutDt: any
  updateLayout: any
}
const ModalContents = ({
  contents,
  lsnuuid,
  layoutDt,
  updateLayout,
}: ModalContentsProps) => {
  // loading -- the room mode is currently being changed
  if (contents === 1) {
    return (
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty align="center">... Loading ...</Ty>
        </Grid2>
      </Grid2Ct>
    );
  }

  // currently in lecture, can switch to group chat
  if (contents === 2) {
    return (
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty align="center">Switch the classroom mode to Group Chat?</Ty>
          <Box display="flex" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
            <Button
              color="accentGreen1"
              onClick={() => updateLayout(
                lsnuuid,
                layoutDt,
                false,
              )}
            >
              Yes, Switch to Group Chat
            </Button>
          </Box>
        </Grid2>
      </Grid2Ct>
    );
  }

  // currently in group chat, can switch to lecture
  if (contents === 3) {
    return (
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty align="center">Switch the classroom mode to Lecture?</Ty>
          <Box display="flex" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
            <Button
              color="accentGreen1"
              onClick={() => updateLayout(
                lsnuuid,
                layoutDt,
                true,
              )}
            >
              Yes, Switch to Lecture
            </Button>
          </Box>
        </Grid2>
      </Grid2Ct>
    );
  }

  // currently in lecture, but CANNOT switch to group chat. the teacher first needs to put
  // at least one student into a group
  if (contents === 4) {
    return (
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty align="center">
            <Ic iconName="traffic-cone" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;
            Error - you must first move at least one student into a group
            before you can start group chat mode.
          </Ty>
        </Grid2>
      </Grid2Ct>
    );
  }

  // should never happen, log to sentry
  Sentry.captureException(
    new Error('ModalContents unexpected contents'),
    {
      extra: {
        ct: contents,
      }
    }
  );
  return (<Ty align="center">An error occurred, please let us know about this.</Ty>);
};

// #endregion
