import React from 'react';
import {
  Grid2Ct, Grid2, CardStandard, IcSvgList
} from '@languageconvo/wcl';
import useSettings from '../../hooks/useSettings';
import useAuth from '../../hooks/useAuth';
import { handleLogout } from '../../utils';

const SettingsPage = () => {
  // when user changes light / dark mode
  const { onToggleMode } = useSettings();
  const { setLogoutInProgress } = useAuth();

  return (
    <>
      {/* main content */}
      <Grid2Ct>
        <Grid2 xs={12} sm={10} smOffset={1} md={12} mdOffset={0} xl={10} xlOffset={1}>
          <Grid2Ct>
            {/* dark / light mode */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Dark / Light Mode"
                titleIcon={IcSvgList.sun1}
                color="accentYellow1"
                titleIconRight={0}
                titleRemoveMb
                hovercursor="pointer"
                onClickEntireCard={onToggleMode}
              />
            </Grid2>

            {/* log out */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Log Out"
                titleIcon={IcSvgList.exit1}
                color="accentRed1"
                titleRemoveMb
                hovercursor="pointer"
                titleIconRight={0}
                onClickEntireCard={
                  () => handleLogout(setLogoutInProgress)
                }
              />
            </Grid2>
          </Grid2Ct>
        </Grid2>
      </Grid2Ct>
    </>
  );
};

export default SettingsPage;
