import React from 'react';
import {
  Box,
  Ic,
} from '@languageconvo/wcl';
import { NavSectionProps } from '../../../../types/nav-section.type';
import { NavbarInnerList } from './NavbarInnerList';
import {
  ListStyleParent,
  ListItemStyleParent,
  ListItemIconStyle,
} from './style';
import useCollapseDrawer from '../../../../hooks/useCollapseDrawer';
import useResponsive from '../../../../hooks/useResponsive';

// this builds the main content of the left side navbar
export const NavbarInner = ({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) => {
  const isDesktop = useResponsive('up', 'lg');
  const { onToggleCollapse } = useCollapseDrawer();

  return (
    <Box {...other}>

      {/* Removing default mui list padding. */}
      <ListStyleParent
        disablePadding
      >

        {/* on desktop, this is the button that allows the user to
        expand or collapse the navbar */}
        {isDesktop && (
          <ListItemStyleParent isCollapse={isCollapse} onClick={onToggleCollapse}>
            <ListItemIconStyle>
              <Ic
                iconName={isCollapse ? 'arrow-right-from-line' : 'arrow-left-to-line'}
              />
            </ListItemIconStyle>
          </ListItemStyleParent>
        )}

        {/* the main contents of the left-side navbar */}
        {navConfig.items.map((list: any) => (
          <NavbarInnerList key={list.title} list={list} isCollapse={isCollapse} />
        ))}
      </ListStyleParent>
    </Box>
  );
};
