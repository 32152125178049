import { Dispatch, SetStateAction } from 'react';
import { DateTime } from 'luxon';
import { CustomMutationDataLessonDateTimeFragment$data } from '../../relay/__generated__/CustomMutationDataLessonDateTimeFragment.graphql';

/** Calculate the difference between what the teacher's browser says the current time is, and
 * what the backend told us the current time is. If they are fairly close, we'll assume the
 * teacher's browser has the exact correct time; if the difference is large, we'll assume
 * the teacher's browser time is not correct. In the case we believe the teacher's browser
 * time is not correct, we'll adjust the lesson start and end time to account for the difference
 * between what the teacher's browser "thinks" the current time is, and what the backend told
 * us the current time is.
 */
export const validateAndSetBrowserTimeDifference = (
  lessonInfo: CustomMutationDataLessonDateTimeFragment$data,
  setTimeDifference: Dispatch<SetStateAction<number>>,
  setmodalviewContents: any,
  setmodalviewState: any,
) => {
  const currentBrowserTime = DateTime.now();

  // getting the current time which backend is giving us
  const currentTimeFromBackend = DateTime.fromISO(lessonInfo.dt.current);

  // differnce between current time and currentTime received from backend
  // currentBrowserTime <=> greater <=> future <=> we get + number.
  // currentBrowserTime <=> lesser <=> past <=> we get - number.
  const difference = currentBrowserTime.diff(currentTimeFromBackend, [
    'second',
  ]);
  const currentTimeDifference: number = difference.toObject().seconds!;

  // if the diff between backend and frontend times is less than 30 seconds, we assume the user's
  // browser is correct. 30 seconds is pretty large; the reason for it being so large is we
  // have to account for the fact that the api call(s) on classroom load can take awhile sometimes
  // and we don't want to incorrectly notify the user in those cases
  if (
    lessonInfo
    && currentTimeDifference <= 30
    && currentTimeDifference >= -30
  ) {
    // using the browser's current time.
    setTimeDifference(0);

    // notify the user of a potential problem with their clock, and set the time difference so
    // that the app uses the backend time rather than the user's browser time
  } else {
    setmodalviewContents(950);
    setmodalviewState(true);
    setTimeDifference(currentTimeDifference);
  }
};

// TODO: need to consider big picture of how a lesson starts...right now, each student
//  will experience slightly different lesson start times, based on their browser clock. probably
//  need a signal from teacher's browser (via changing the layout data?) that the lesson
//  has begun. Potential problems to consider:
//    - Backend is *really* slow, maybe takes 30 seconds to respond. In this case, our code
//      here will determine "the teacher's browser time is wrong, bc it's very different than
//      what the backend told us!". The lesson start/end time for the teacher will be adjusted
//      incorrectly; students will experience the lesson starting or ending incorrectly
//      TODO: ensure we have a short timeout for uit api calls

/* lectureTimeTracker function is responsible for calculating start and end time of lesson.
*   It's a very important function, as it will start and end the lesson for the teacher.
*   If it is incorrect, the lesson will start and/or end at the wrong time.
*
* If teacher's browser time seems to be correct
* (meaning that the teacher's browser current time closely matched what the backend told us
* the current time is):
*   When calculating how much time it is until the lesson starts and ends, we use the
*   teacher's browser to tell us exactly what the current time is. We "trust" it's correct
* 
* If teacher's browser time seems to NOT be correct
* (meaning that the teacher's browser current time did NOT closely match what the backend
* told us the current time is):
*   When calculating how much time it is until the lesson starts and ends, we "correct" the
*   teacher's browser time by adding or subtracting from what it tells us the current time is.
*   We do that using timeDifference --- this was the difference between what the teacher's browser
*   told us the current time is and the backend told us the current time is.
*/
export const lectureTimeTracker = (
  timeDifference: number,
  lessonInfo: CustomMutationDataLessonDateTimeFragment$data,
  setSecondsRemainingInLectureStart: Dispatch<SetStateAction<number>>,
  setSecondsRemainingInLectureEnd: Dispatch<SetStateAction<number>>
) => {
  let timeToBeUsed;
  if (timeDifference > 0) {
    timeToBeUsed = DateTime.now().minus({ seconds: timeDifference });
  } else {
    timeToBeUsed = DateTime.now().plus({ seconds: timeDifference });
  }

  // getting the lesson start time from lessonInfo.
  const startTime = DateTime.fromISO(lessonInfo.dt.startsAt);
  // calculating the time remaining in lesson start
  const timeRemainingInStart = startTime.diff(timeToBeUsed, ['second']);
  // converting the time remaining in lesson start in seconds
  const remainingStartTimeInSeconds = timeRemainingInStart.toObject().seconds;

  // getting the end time from lessonInfo
  const endTime = DateTime.fromISO(lessonInfo.dt.endsAt);
  // calculating the time remaining in lesson end
  const timeRemainingInEnd = endTime.diff(timeToBeUsed, ['second']);
  // converting the time remaining in lesson end in seconds
  const remainingEndTimeInSeconds = timeRemainingInEnd.toObject().seconds;

  // setting the seconds remaing in lesson start to a state variable
  setSecondsRemainingInLectureStart(() => remainingStartTimeInSeconds!);
  // setting the seconds remaing in lesson end to a state variable
  setSecondsRemainingInLectureEnd(() => remainingEndTimeInSeconds!);
};
