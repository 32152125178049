import React from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
// layouts
import { DashboardLayout } from '../layouts/dashboard';
// components
import AuthOnlyLayout from '../layouts/authOnly';
import Login from '../pages/login/Login';
import Auth from '../pages/auth/Auth';
import Logout from '../pages/auth/Logout';
import PwdForgot from '../pages/updatePassword/PwdForgot';
import PwdReset from '../pages/updatePassword/PwdReset';
import TestInputs from '../pages/testInputs';
import PreLoadedContainer from '../components/pagewrapper/PreLoadedContainer';
import SettingsPage from '../pages/settings/SettingsPage';
import { RouteLevelError } from '../common/components/errorfallback/RouteLevelError';
import { PageLevelError } from '../common/components/errorfallback/PageLevelError';
// group classes
import { GroupClassroom } from '../pages/group/classroom/GroupClassroom';
// pages - home
import HomePage from '../pages/home/Home';
// pages - ds folder
import DsIndex from '../pages/ds/index';
import Typography from '../pages/ds/typography/typography';
import Form from '../pages/ds/forms/forms';
import Grid from '../pages/ds/grid/grid';
import QuickAuth from '../pages/quickAuth/QuickAuth';
import { getVersionTimeStamp } from '../utils/getVersionTimeStamp';

const Router = () => {
  // In v6.4, new routers were introduced that support the new data APIs.
  // It means, by using this, you can load data during rendering phase, not
  // after complete render: https://reactrouter.com/en/main/routers/picking-a-router
  // check the settings page route where we have used it.
  const router = createBrowserRouter([
    // main app pages
    {
      element: <DashboardLayout />,
      errorElement: <RouteLevelError />,
      loader: async () => {
        // gets a versionTimeStamp 
        const data = await getVersionTimeStamp();
        return data;
      },
      children: [
        { path: '/', element: <Navigate to="/home" replace />, index: true },

        // home
        {
          path: 'home',
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'Home',
                'page-home',
                <HomePage />,
                null,
              ),
              errorElement: <PageLevelError />,
            },
          ]
        },

        // group classroom
        {
          path: 'group',
          children: [
            {
              path: 'classroom',
              element: PreLoadedContainer(
                'Group Classroom',
                'group-id',
                <GroupClassroom />,
                null,
              ),
              errorElement: <PageLevelError />
            }
          ]
        },

        // desgin system
        {
          path: 'ds',
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'Design System',
                'ds-index',
                <DsIndex />,
                '/ds'
              ),
            },
            {
              path: 'forms',
              element: PreLoadedContainer(
                'Forms',
                'ds-forms',
                <Form />,
                '/ds'
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'typography',
              element: PreLoadedContainer(
                'Typography',
                'ds-typography',
                <Typography />,
                '/ds'
              ),
            },
            {
              path: 'grid',
              element: PreLoadedContainer(
                'Grid',
                'ds-grid',
                <Grid />,
                '/ds'
              ),
            },
          ]
        },

        // more
        {
          path: 'more',
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'More',
                'settings-home',
                <SettingsPage />,
                null,
              ),
              errorElement: <PageLevelError />
            },
          ]
        },
      ],
    },

    // auth pages (login, quick auth, etc.)
    {
      element: <AuthOnlyLayout />,
      errorElement: <RouteLevelError />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'auth', element: <Auth /> },
        { path: 'logout', element: <Logout /> },
        { path: 'pwdforgot', element: <PwdForgot /> },
        { path: 'pwdreset', element: <PwdReset /> },
        // route for quick authentication page
        { path: 'quickauth', element: <QuickAuth /> }
      ]
    },

    // redirect to the home page if the user attempts to access a non-existent page
    {
      path: '*',
      children: [
        { path: '*', element: <Navigate to="/home" replace /> },
      ],
    },

    // testing some input elements. this should be moved to main app pages, ds folder
    { path: 'inputs', element: <TestInputs /> },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
