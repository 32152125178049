import React, { useState } from 'react';
import {
  Grid2, Grid2Ct, CardSmallColor, IcSvgList, useTheme, useMediaQuery,
} from '@languageconvo/wcl';
import { useClientQuery, useFragment } from 'react-relay';
import { AudioVideoControlsMicDialog } from './AudioVideoControlsMicDialog';
import { AudioVideoControlsCamDialog } from './AudioVideoControlsCamDialog';
import { MicAndCameraDetailsQuery } from '../../../../../common/relay/queries/MicAndCameraDetails';
import { MicDetailsFragment } from '../../../../../common/relay/queries/fragments/MicDetails';
import { CameraDetailsFragment } from '../../../../../common/relay/queries/fragments/CameraDetails';

// enable teacher to change his/her mic, cam, and share screen
export const AudioVideoControls = () => {
  // #region general

  // on xs screens, text for the boxes needs to be really small
  const theme = useTheme();
  const screensizeXs = useMediaQuery(theme.breakpoints.down('sm'));

  // reading mic & camera details from relay store
  const response: any = useClientQuery(MicAndCameraDetailsQuery, {});
  const fragmentR = response.RelayAppSettings;
  // reading mic details from relay store (single source of truth)
  // to show current mic name , 
  const micData = useFragment(MicDetailsFragment, fragmentR);
  const micInfo = micData.microphone;
  // reading camera details from relay store (single source of truth)
  // to show current camera name , 
  const cameraData = useFragment(CameraDetailsFragment, fragmentR);
  const cameraInfo = cameraData.camera;

  // #endregion

  // #region mic small card display

  /* Here we determine:
      micName: this is the text we'll display in the mic card
      micAlign: whether to left or center align the text in the mic card
      micBgColor: the bg color of the mic card (red or white)
      micIcon: the icon in the mic card (a microphone, or an X)
  */
  // defaults
  let micName = '';
  let micAlign = { }; // this centers the text within the card
  let micBgColor: any = 'cardstandard' as const; // purple bg color
  let micIcon: any = IcSvgList.microphone2; // microphone icon

  // once the user has given device permissions can we determine
  // what title/color/icon etc should be set. until then, we display the above defaults

  // no mic found on the user's device, so we need to show a red error and X icon
  if (!micInfo.current.title) {
    micAlign = { }; // center the text
    micName = 'No Mic Found!';
    if (screensizeXs) {
      micName = 'Mic Error!';
    }
    micBgColor = 'accentRed1' as const;
    micIcon = IcSvgList.cancel1;

    // mic is muted. show red error and X icon
  } else if (micInfo.current.isMuted) {
    micAlign = { }; // center the text
    micName = 'Mic Muted!';
    if (screensizeXs) {
      micName = 'Muted!';
    }
    micBgColor = 'accentRed1' as const;
    micIcon = IcSvgList.cancel1;

    // a mic exists, and its not muted!
  } else if (micInfo.current?.micId) {
    // on large screens, we display the name of the mic left aligned so that the user can see as
    // much of the name of their mic as possible
    micAlign = { align: 'left' as const };
    // it's very remotely possible mic title is null (although our get devices function *should*
    // not allow that), we have this if just to be sure we don't get an error if it's null
    if (micInfo.current?.title) {
      micName = micInfo.current.title;
    }

    // on small screens we don't have room for the name of the mic so we just show "Mic" centered
    if (screensizeXs) {
      micName = 'Mic';
      micAlign = { }; // center the text
    }

    micBgColor = 'cardstandard' as const;
    micIcon = IcSvgList.microphone2;
  }

  // #endregion

  // #region mic small card display

  /* Here we determine:
      cameraName: this is the text we'll display in the camera card
      cameraAlign: whether to left or center align the text in the camera card
      cameraBgColor: the bg color of the camera card (red or white)
      camIcon: the icon in the camera card (a webcamera, or an X)
  */
  // defaults
  let cameraName = '';
  let cameraAlign = { }; // this centers the text within the card
  let cameraBgColor: any = 'cardstandard' as const; // green bg color
  let camIcon: any = IcSvgList.webcam1; // camera icon

  // once the user has given device permissions can we determine
  // what title/color/icon etc should be set

  // no camera found on the user's device, so we need to show a red error and X icon
  if (!cameraInfo.current.title) {
    cameraAlign = { }; // center the text
    cameraName = 'No Camera Found!';
    if (screensizeXs) {
      cameraName = 'Cam Error!';
    }
    cameraBgColor = 'accentRed1' as const;
    camIcon = IcSvgList.cancel1;

  // camera is off. show red error and X icon
  } else if (cameraInfo.current.isCameraOff) {
    cameraAlign = { }; // center the text
    cameraName = 'Camera Off!';
    if (screensizeXs) {
      cameraName = 'Camera Off!';
    }
    cameraBgColor = 'accentRed1' as const;
    camIcon = IcSvgList.cancel1;

  // a camera exists, and its not off!
  } else if (cameraInfo.current?.camId) {
  // on large screens, we display the name of the camera left aligned so that the user can see as
  // much of the name of their camera as possible
    cameraAlign = { align: 'left' as const };
    // it's very remotely possible camera title is null (although our get devices function *should*
    // not allow that), we have this if just to be sure we don't get an error if it's null
    if (cameraInfo.current?.title) {
      cameraName = cameraInfo.current?.title;
    }

    // on small screens we don't have room for the name of the mic so we just show "Mic" centered
    if (screensizeXs) {
      cameraName = 'Cam';
      cameraAlign = { }; // center the text
    }

    cameraBgColor = 'cardstandard' as const;
    camIcon = IcSvgList.webcam1;
  }

  // #endregion

  // #region vars

  // anchor element for mic popup
  const [anchorElForMic, setAnchorElForMic] = useState<HTMLButtonElement | null>(null);
  // anchor element for camera popup
  const [anchorElForCamera, setAnchorElForCamera] = useState<HTMLButtonElement | null>(null);

  // #endregion

  // #region for handling click events for audio, video components

  // the `handleClickForMic` is responsible for switching between the available microphone
  const handleClickForMic = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // preventing onClick to auto reload
    setAnchorElForMic(event.currentTarget);
  };

  // click event for closing the mic popup
  const handleCloseForMic = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault(); // preventing onClick to auto reload
    }
    setAnchorElForMic(null);
  };

  // the `handleClickForCamera` is responsible for switching between the available camera devices
  const handleClickForCamera = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // preventing onClick to auto reload
    setAnchorElForCamera(event.currentTarget);
  };

  // click event for closing the Camera popup
  const handleCloseForCamera = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault(); // preventing onClick to auto reload
    }
    setAnchorElForCamera(null);
  };

  // #endregion

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        {/* icons: camera, mic, screenshare */}
        <Grid2Ct>
          <Grid2 xs={12}>
            {/* icons */}
            <Grid2Ct>
              {/* mic */}
              <Grid2 xs={12} sm={6}>
                <CardSmallColor
                  {...micAlign}
                  align="left"
                  smallerfont
                    // icon will be an X if the user has muted their mic or has no mics at all
                  icon={micIcon}
                  text={micName}
                  color={micBgColor}
                  hovercursor="pointer"
                  onClick={handleClickForMic}
                />
              </Grid2>

              {/* camera */}
              <Grid2 xs={12} sm={6}>
                <CardSmallColor
                  {...cameraAlign}
                  align="left"
                  smallerfont
                    // icon will be an X if the teacher has off their camera or has no camera at all
                  icon={camIcon}
                  text={cameraName}
                  color={cameraBgColor}
                  hovercursor="pointer"
                  onClick={handleClickForCamera}
                />
              </Grid2>
            </Grid2Ct>
          </Grid2>
        </Grid2Ct>

        {/* Mic Dialog */}
        <AudioVideoControlsMicDialog
          anchorElForMic={anchorElForMic}
          handleCloseForMic={handleCloseForMic}
        />

        {/* Camera Dialog */}
        <AudioVideoControlsCamDialog
          anchorElForCamera={anchorElForCamera}
          handleCloseForCamera={handleCloseForCamera}
        />
      </Grid2>
    </Grid2Ct>
  );
};
