import {
  Box, styled, WCLThemeProps
} from '@languageconvo/wcl';
import { HEADER } from '../../config';

type MainStyleProps = {
    collapseClick: boolean | undefined;
  };

export const MainContentContainer = styled(Box, {
})(({ theme }: WCLThemeProps) => ({
  width: '100%',
  marginTop: HEADER.MOBILE_HEIGHT,
  height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)`,
  [theme!.breakpoints.up('lg')]: {
    marginTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
  },
}));

export const MainContentBackground = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps & WCLThemeProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: 0,
  minHeight: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)`,
  transition: theme?.ourTheme.components.transition.page,
  [theme!.breakpoints.up('lg')]: {
    paddingBottom: 0,
    minHeight: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
    ...(collapseClick && {
      marginLeft: theme.ourTheme.components.navbar.navbarWidthCollapse,
    }),
    ...(!collapseClick && {
      marginLeft: theme?.ourTheme.components.navbar.navbarWidthExpanded,
    }),
  },
  borderTopLeftRadius: '8px',
  border: 'none',
  boxShadow: 'none',
  // main page background color
  background: `hsl(${theme?.ourTheme.colors.app.page})`,
}));

export const InnerContentContainer = styled('div')(() => ({
  paddingTop: '24px'
}));

export const MaxWidthContainer = styled('div')(() => ({
  width: '100%',
  maxWidth: '1800px',
  margin: '0 auto',
}));
