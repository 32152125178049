/** this utility file contains the localStorage setter/getter
 * and helpers functions which are helping setter/getter to check types in
 * case if someone manipulated with localstored values.
 * this file also contains "typeGuard" helper which helps us to check localStored
 * object types and if there is any issue, set the localStored with default values
 *
 * NOTE:
 *     In future, if we wanted to add new key in localstored object, we need to add
 * these new keys in our interface file then also need to add type check in our "typeGuard"
 * helper.
 */

// navConfig have all info related to our sideNav, we are looping on navConfig
// and displaying sideNav data in our app.
import * as Sentry from '@sentry/react';
import navConfig from '../layouts/dashboard/navbar/NavbarConfig';
import { AppVersionData, GroupLesson, LcTchSettings } from './lcStorageInterface';
import { teacherLocalStorageKeys } from './teacherLocalStorageKeys';

// #region Deprecated fucntions but still used in  will update

// TODO: we are using these functions will update these funciton once we move towards UIT
// this will fetch data from localStorage with "key",
// update specific node receiving in params.
// and update again in localStorage with same "key"
export const setDataToLCStorage = (key: string, node: Partial<LcTchSettings>) => {
  let updatedData = node;

  const storedValues:string | null = localStorage.getItem(key);
  if (storedValues) {
    const parsedValues = JSON.parse(storedValues);
    updatedData = {
      ...parsedValues,
      ...node,
    };
  }
  localStorage.setItem(key, JSON.stringify(updatedData));
};

// return the localStorage stored object based on the key.
// if someone has manipulated the key OR localStored object does not have
// expected type, then initialized it with default object.
export const getDataToLCStorage = (key: string) => {
  const storedValues:string | null = localStorage.getItem(key);
  if (storedValues) {
    const lcSettings = JSON.parse(storedValues || '');
    return key === 'lc_tchsettings' ? typeGuard(key, lcSettings) : lcSettings;
  }
  return {};
};

/// //////////////////Helper functions starts////////////////////

/** purpose of this object is type checking inside our "typeGuard" function */
const LCTchSettingsVar = {
  login: {
    rememberMeSettings: {
      rememberme: false,
      email: '',
    }
  },
  navbar: {
    collapse: false,
    expandedItems: [{
      id: 1,
      isOpen: false,
    }]
  },
  theme: {
    mode: 'light',
  },
};

/** this helper is our Type Guard which is checking our local stored object types
 * The is keyword is actually casting the type of localStored obj and can catch type errors
 * later in the code as local stoed object can have any value.
 * Purpose:
 * it is looping on all keys and checking type of each key value if type doen't match with
 * our defined interface, it initialized that specific key with default value. for example
 * if someone manipulated with one key, we are resettting that key instead of initilaizing
 * whole object with default values.
 * if there is any error during type checking, we initialized the object with default state
 */
function typeGuard(key: string, localStoredObject: any): localStoredObject is LcTchSettings {
  // need to create updatedLCSettings due to this rule //no-param-reassign
  let updatedLCSettings = localStoredObject;
  try {
    if (Object.keys(updatedLCSettings).length) {
      // check comments on processOldLcSettings helper for more detail.
      updatedLCSettings = processOldLcSettings(key, updatedLCSettings);

      // looping on all keys to check validity.
      Object.keys(updatedLCSettings).forEach((property) => {
        // looping on login node to check validity.
        if (property === 'login') {
          Object.keys(updatedLCSettings[property]).forEach((property1) => {
            if (property1 === 'rememberMeSettings') {
              Object.keys(updatedLCSettings[property][property1]).forEach((property11) => {
                if (property11 === 'rememeberMe') {
                  if (typeof updatedLCSettings[property][property1][property11]
                      !== typeof LCTchSettingsVar.login?.rememberMeSettings?.rememberme) {
                    // reset rememberMe value.
                    updatedLCSettings[property][property1][property11] = false;
                  }
                } else if (property11 === 'email') {
                  if (typeof updatedLCSettings[property][property1][property11]
                      !== typeof LCTchSettingsVar.login?.rememberMeSettings?.email) {
                    // reset email value.
                    updatedLCSettings[property][property1][property11] = '';
                  }
                }
              });
            }
          });
        } else if (property === 'navbar') { // looping on navbar node to check validity.
          Object.keys(updatedLCSettings[property]).forEach((property2) => {
            if (property2 === 'collapse') {
              if (typeof updatedLCSettings[property][property2]
                  !== typeof LCTchSettingsVar.navbar?.collapse) {
                updatedLCSettings[property][property2] = false;
              }
            } else if (property2 === 'expandedItems') {
              // expandedItems must be an array and its lenght must be equal to
              // our navConfig items else reinialize it with default values.
              if (Array.isArray(updatedLCSettings[property][property2])
                && updatedLCSettings[property][property2].length === navConfig.items.length) {
                // looping on expandedItems array and checking types.
                Object.keys(updatedLCSettings[property][property2]).forEach((property22, index) => {
                  // looping on expandedItems array individual item and checking types.
                  Object.keys(updatedLCSettings[property][property2][property22]).forEach(
                    (property222) => {
                      if (property222 === 'id') {
                        if (typeof updatedLCSettings[property][property2][property22][property222]
                          !== typeof LCTchSettingsVar.navbar?.expandedItems[0].id) {
                          updatedLCSettings[property][property2][property22][property222] = index;
                        }
                      } else if (property222 === 'isOpen') {
                        if (typeof updatedLCSettings[property][property2][property22][property222]
                          !== typeof LCTchSettingsVar.navbar?.expandedItems[0].isOpen) {
                          updatedLCSettings[property][property2][property22][property222] = false;
                        }
                      }
                    }
                  );
                });
              } else {
                // if expandedItems is not in proper form and does not match with expexted type
                // and lenght map through on navConfig/sideNav Items and set it with
                // default values...
                updatedLCSettings[property][property2] = navConfig.items.map((item) => (
                  {
                    id: item.id,
                    isOpen: item.isOpen
                  }
                ));
              }
            }
          });
        } else if (property === 'theme') { // looping on theme node to check validity.
          Object.keys(updatedLCSettings[property]).forEach((property3) => {
            if (property3 === 'mode') {
              if (typeof updatedLCSettings[property][property3]
                  !== typeof LCTchSettingsVar.theme?.mode) {
                updatedLCSettings[property][property3] = 'light';
              }
            }
          });
        }
      });
    } else {
      updatedLCSettings = setDefaultLCSettings();
    }
  } catch (err) {
    // we can send this err to sentry to check what kind of err we have in local stored object
    updatedLCSettings = setDefaultLCSettings();
  }
  return updatedLCSettings;
}

/** in case of any error during localstored object type checking, we
 * initialize our localstored object to default values. don't need to
 * assign any value to login and thmee objects as once user interact with
 * our app, these values will be initialized.
 */
function setDefaultLCSettings() {
  const array = {
    login: {},
    theme: {},
    navbar: {
      collapse: false,
      expandedItems: setDefaultSideNav()
    }
  };
  return array;
}

/** navConfig object is responsible of displaying our site sideNav
 * we are persisting user actions on sideNav for example if user have some
 * items open inside sideNav and user visit our site after few days, we display
 * open items as open and closed one as close.
 * here we are saving each item id and isOpen flag in localStorage so once our page
 * load or reload, we compare our navConfig with this stored values and update it
 * with stored values.
 */
export function setDefaultSideNav() {
  const arr = navConfig.items.map((item) => (
    {
      id: item.id,
      isOpen: item.isOpen
    }
  ));
  return arr;
}

/** purpose:  processOldLcSettings helper
 *  previously we do have lc_tchsettings structure like below
 * export type LcTchSettings = {
      ui: {
        navbar: {
          collapse: boolean,
          expandedItems: {
            id: number,
            isOpen: boolean
          }[]
        },
        theme: {
          rtl: boolean,
          mode: 'light' | 'dark'
        }
      },
      login: {
        rememberMeSettings: {
          rememberme: boolean,
          email: string
        }
      }
    };
    after deployment, we don't want users to lose their settings, that's why, we
    are processing the old local stored object and converting it with our new
    defined strcuture, so all our users have same settings.
 */
function processOldLcSettings(key:string, obj:any) {
  // need to create newObj due to this rule //no-param-reassign
  const newObj = obj;
  if (newObj?.ui) {
    newObj.navbar = newObj.ui.navbar;
    newObj.theme = newObj.ui.theme;
    // set localStored sideNav open items to navConfig Object so users can see updated sideNav
    const storedItems = newObj?.ui?.navbar?.expandedItems[0]?.items;
    newObj.navbar.expandedItems = navConfig.items.map((item, index) => ({
      id: item.id,
      isOpen: storedItems[index]?.isOpen
    }));
    delete newObj?.ui;
    // after converting to new structure, need to update localStored object otherwise, we always
    // see previous records too.
    localStorage.setItem(key, JSON.stringify(newObj));
  }
  return newObj;
}

/// //////////////////Helper functions ends////////////////////
// #endregion 

// #region for defaultTchSettings 

export const defaultGroupLesson: GroupLesson = {
  isMute: false,
  micId: null, // by default micId is null.
  cameraId: null, // by default cameraId is null.
  isCameraOff: false
};

export const defaultAppVersionData: AppVersionData = {
  hardRefreshCount: 0,
  timeStampInLC: null
};

// IMPORTANT: In future if we need to add/update lc_tchsettings then this is the 
// default object. We need to edit this.
const defaultTchSettings = {
  groupLesson: defaultGroupLesson,
  appVersionData: defaultAppVersionData
};
// #endregion  

// #region lc_tchSettings getter/setter

/*
  Getting lc_tchSettings from local storage. You may see its return type.
  This will always return valid LcTchSettings obj
  For new teachers, we'r returning the default values.
  For existing teachers, we'r checking value validity via typegaurd and
  Reconstruct a valid LcTchSettings object based on the parsed value
*/
export const getTchSettingsFromLCStorage = ():LcTchSettings => {
  const storedValue = localStorage.getItem(teacherLocalStorageKeys.teacherSettings);
  let parsedValue: LcTchSettings = defaultTchSettings;

  if (storedValue) {
    try {
      // it can throw error if not valid JSON
      parsedValue = JSON.parse(storedValue);
      // check isValidPartialLcTchSettings comments.
      if (isValidPartialLcTchSettings(parsedValue)) {
        // Reconstruct a valid LcTchSettings object based on the parsed value
        const reconstructedValue: LcTchSettings = {
          groupLesson: { ...defaultTchSettings.groupLesson, ...parsedValue.groupLesson },
          appVersionData: { ...defaultTchSettings.appVersionData, ...parsedValue.appVersionData }
        };
        return reconstructedValue;
      }
      parsedValue = defaultTchSettings;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  // Return the default valid values
  return parsedValue;
};

// this method will not always accept full lc_tchsettings It can accept 
// partial values i-e sometime can accept *theme: {mode: 'light'}* etc.
// what it do, it get the existing saved object and update it with new
// values and persist the old vlaues.
export const setTchSettingsToLCStorage = (node: Partial<LcTchSettings>) => {
  const storedValues:LcTchSettings = getTchSettingsFromLCStorage();
  const updatedData = {
    ...storedValues,
    ...node,
  };
  localStorage.setItem(teacherLocalStorageKeys.teacherSettings, JSON.stringify(updatedData));
};

// #endregion 

// # region for the validation of LcTchSettings
// The function checks if the parsed value is a valid partial LcTchSettings object. 
// It allows properties to be undefined, indicating partial objects are valid.
// NOTE: this function will return false if any value manipulated by dirty user.
const isValidPartialLcTchSettings = (obj: any): obj is Partial<LcTchSettings> => (
  obj
    && typeof obj === 'object'
    && (obj.groupLesson === undefined || isValidGroupLesson(obj.groupLesson))
    && (obj.appVersionData === undefined || isValidVersionData(obj.appVersionData))
);

// type checking for valid group lesson obj.
const isValidGroupLesson = (groupLesson: any): groupLesson is GroupLesson => (
  groupLesson
  && typeof groupLesson === 'object'
  && 'isMute' in groupLesson
  && (typeof groupLesson.isMute === 'boolean')
  && 'micId' in groupLesson
  && (typeof groupLesson.micId === 'string' || groupLesson.micId === null)
  && 'cameraId' in groupLesson
  && (typeof groupLesson.cameraId === 'string' || groupLesson.cameraId === null)
  && 'isCameraOff' in groupLesson
  && (typeof groupLesson.isCameraOff === 'boolean')
);

const isValidVersionData = (appVersionData: any): appVersionData is AppVersionData => (
  appVersionData
  && typeof appVersionData === 'object'
  && 'hardRefreshCount' in appVersionData
  && (typeof appVersionData.hardRefreshCount === 'number')
  && 'timeStampInLC' in appVersionData
  && (typeof appVersionData.timeStampInLC === 'number' || appVersionData.timeStampInLC === null)
);

// #endregion
