import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Button, Dialog, Divider, Grid2, Grid2Ct, Ic, Select, SelectChangeEvent,
  Ty
} from '@languageconvo/wcl';
import { useClientQuery, useFragment } from 'react-relay';
import useVonage from '../../hooks/useVonage';
import { MicDetailsFragment } from '../../../../../common/relay/queries/fragments/MicDetails';
import { MicAndCameraDetailsQuery } from '../../../../../common/relay/queries/MicAndCameraDetails';
import { setAllAvailableMicsInRelayStore, setCurrentMicInRelayStore } from '../../../../../common/relay/clientschema/relayappsettings/microphoneFunctionality';
import { getAvailableMics } from '../../context/VonageProviderHelper';
import { UpdateMicIDInLocalStorage } from '../../../../../common/microphone/localStore/toggleMicInLCStorage';

// enable user to change mic and allowed to toggle mic state.
export const AudioVideoControlsMicDialog = (props: any) => {
  // our client query to fetch data from relay store.
  const response: any = useClientQuery(
    MicAndCameraDetailsQuery,
    {}
  );
  const fragmentR = response.RelayAppSettings;
  // reading data from relay store because relay store is our single source
  //  of truth for mic related functionality.
  const micData = useFragment(MicDetailsFragment, fragmentR);
  // micInfo hold all the information related to microphone
  // we need to show current micInfo , all microphone in 
  // select dropdown, and need to show microphone on/off state 
  const micInfo = micData.microphone;

  // #region general setup react state variables.

  // state to hold the previous audio device for showing notification when new
  // audio device pluged in/out.
  const [prevAudioDevice, setPrevAudioDevice] = useState<number>(0);

  const { anchorElForMic, handleCloseForMic } = props;

  // state for storing teacher's avalilable audio sources
  // used to display all available audio devices in a dropdown,
  // allowing the user to select any of the available options.
  const [audioSources, setAudioSources] = useState<Array<{ name: string; value: string }>>([]);

  // state to store the ID of the currently selected microphone.
  // this is used to display the current microphone in the dropdown,
  // with the initial value coming from the relay store.
  const [selectedMic, setSelectedMic] = useState<string | null>(micInfo.current.micId
    || null);

  // Boolean value to check if anchor element is open or closed
  const open = Boolean(anchorElForMic);

  // Destructuring the functions from useVonage hook
  const {
    muteMicrophone, unMuteMicrophone,
  } = useVonage();

  // #endregion

  // #region for getting audio device

  const fetchAudioSourceAndDisplay = () => {
    const audioOptions = micInfo.all.edges.map((device: any) => ({
      name: device.title,
      value: device.id,
    }));
    // transform the data into the required format and update the state
    // to populate the dropdown options with available audio devices.
    setAudioSources(audioOptions);
  };

  // this useEffect fetches the available audio sources when the page loads
  // to ensure the dropdown is populated with up-to-date options.
  useEffect(() => {
    fetchAudioSourceAndDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micInfo]);

  // get all the audio devices the user has plugged in. update the relay store custom value that
  // holds this list, and then update our dropdown to display that data
  const getCurrentAttachedAudioDevices = async () => {
    await getAvailableMics().then((devices: any) => {
      // here we storing newly plugged in audio devices in relay store
      setAllAvailableMicsInRelayStore(devices);

      // log and update our relay store if an error getting devices occurred. note that we do
      // NOT want to change the mic.current value in the relay store as that would change the
      // mic the teacher is currently streaming with. we just need to update the list of
      // all available mics
    }).catch((e: any) => {
      Sentry.captureException(e);
      setAllAvailableMicsInRelayStore([]);
    });
  };

  // any time the user opens the modal, we get all the user's microphones to ensure that
  // our list of mics in the dropdown is updated/correct
  useEffect(() => {
    if (open) {
      getCurrentAttachedAudioDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // #endregion 

  // #region Microphone Handling

  // this function is responsible for selecting the mic from dropdown 
  const handleMicrophoneChange = (event: SelectChangeEvent) => {
    setSelectedMic(event.target.value);
  };

  // when the teacher saves the mic they want to use, we update the relay store and localstorage
  // with the new mic. if the teacher is streaming, once the relay store current mic value changes
  // the new mic will automatically be used in the streaming
  const saveSelectedMicToLC = () => {
    /* Usually, the mic the user selected in the dropdown will still exist in the relay store.
        In that case, selectedMicDetails.length is true. But in some rare scenarios, the
        mic the user has selected in the dropdown won't actually exist in the relay store
        anymore (for example, they are publishing with that mic, but then it becomes
        unplugged). That rare case is the else statement below
    */
    const selectedMicDetails = micInfo.all.edges.filter((mic: any) => mic.id === selectedMic);
    if (selectedMicDetails.length) {
      // update the current mic in the relay store
      setCurrentMicInRelayStore(selectedMicDetails[0].id, selectedMicDetails[0].title);

      // update the current mic in localstorage
      UpdateMicIDInLocalStorage(selectedMicDetails[0].id);
    } else {
      // TODO: future, after group lessons v1 -- add a notif within the modal that the device
      //  the user has selected is no longer available. For now, we are doing nothing; the
      //  select options will automatically change IF the mic that was unplugged is the mic
      //  the user is publishing but they will NOT change if the mic unplugged is any other
    }

    handleCloseForMic();
  };

  // function to handle microphone mute/unmute state based on user action.
  // we are saving these values in relay store and localStorage,for more details 
  // see comments in muteMicrophone/unMuteMicrophone
  const toggleMicState = () => {
    if (!micInfo.current.isMuted) {
      muteMicrophone();
    } else {
      unMuteMicrophone();
    }
    handleCloseForMic();
  };

  // #endregion 

  // Checking new audio devices plugged in OR unplugged
  useEffect(() => {
    // need to keep record of previously available audio devices lenght so we can compare
    // it with new connected devices and notify teachers.
    if (prevAudioDevice === 0) {
      // need to do comparison either new device added or removed.
      setPrevAudioDevice(audioSources.length);
    }
    if (prevAudioDevice > 0) {
      // Check if the length has changed
      if (audioSources.length > prevAudioDevice) {
        setPrevAudioDevice(audioSources.length);
      } else if (audioSources.length < prevAudioDevice) {
        setPrevAudioDevice(audioSources.length);
        // here we are just updating the mic in state from relay store 
        // to show the current audio device which he/she is connected with.
        setSelectedMic(micInfo.current.micId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSources]);

  return (
    <Dialog
      isOpen={open}
      onClose={handleCloseForMic}
      width="xs"
      color="accentBlue1"
    >
      <Grid2Ct sx={{ mt: 2 }}>
        <Grid2 xs={12}>
          {/* show a warning that no mics exist */}
          {audioSources.length === 0 ? <NoMicsExist /> : <div />}
          {/* select a microphone, save setting */}
          <Select
            label="Select Your Microphone"
            value={selectedMic!}
            onChange={handleMicrophoneChange}
            options={audioSources}
          />

          <Button
            color="accentBlue1"
            fullWidth
            disabled={audioSources.length === 0}
            onClick={saveSelectedMicToLC}
            cp={{ sx: { mt: 1 } }}
          > Save
          </Button>

          {/* divider */}
          <Divider cp={{ sx: { mt: 4, mb: 4 } }} />

          {/* text to be clear to the user whether their mic is on or off */}
          <Ty v="p">
            {micInfo.current.isMuted ? <MicOffText />
              : <MicOnText />}
          </Ty>

          {/* button to turn mic on or off */}
          {micInfo.current.isMuted
            ? <MicOffButton onClick={toggleMicState} />
            : <MicOnButton onClick={toggleMicState} />}
        </Grid2>
      </Grid2Ct>

    </Dialog>
  );
};

// #region sub components

const MicOffText = () => (
  <span>
    <Ic iconName="triangle-exclamation" color="accentRed1" iconStyle="duotone" />&nbsp;
    <strong>Your microphone is off!</strong> Students will&nbsp;
    <strong>not</strong> be able to hear you. Click the button below to turn it back on.
  </span>
);

const MicOffButton: any = (props: any) => (
  <Button
    color="accentBlue1"
    fullWidth
    onClick={props.onClick}
    cp={{ sx: { mt: 1, mb: 2 } }}
  >Turn Mic&nbsp;<strong>On</strong>
  </Button>
);

const MicOnText = () => (
  <span>
    Your microphone is&nbsp;
    <strong>on</strong>. All students should be able to hear you.
  </span>
);

const MicOnButton: any = (props: any) => (
  <Button
    color="accentBlue1"
    fullWidth
    onClick={props.onClick}
    cp={{ sx: { mt: 1, mb: 2 } }}
  >Turn Mic&nbsp;<strong>Off</strong>
  </Button>

);

const NoMicsExist = () => (
  <Grid2Ct sx={{ mb: 2 }}>
    <Grid2 xs={12}>
      <Ty>
        <Ic iconName="triangle-exclamation" color="accentRed1" iconStyle="duotone" />&nbsp;
        <strong>We could not find any microphones!</strong> Check to make sure you have a microphone
        plugged in to your device. If you do, try refreshing the page.
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// #endregion
