/**
 * @generated SignedSource<<ce43454082b44ded49994cf28ac79c34>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomMutationDataPusherDetailsFragment$data = {
  readonly av: {
    readonly studentGroupRooms: ReadonlyArray<{
      readonly uuid: string;
    } | null>;
  };
  readonly pusherChannel: string;
  readonly " $fragmentType": "CustomMutationDataPusherDetailsFragment";
};
export type CustomMutationDataPusherDetailsFragment$key = {
  readonly " $data"?: CustomMutationDataPusherDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomMutationDataPusherDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomMutationDataPusherDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pusherChannel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApptGroupClassroomTchEnterAv",
      "kind": "LinkedField",
      "name": "av",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ApptGroupClassroomTchEnterRoomStudGrp",
          "kind": "LinkedField",
          "name": "studentGroupRooms",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uuid",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApptGroupClassroomTchEnterOutput",
  "abstractKey": null
};

(node as any).hash = "e006928b2ab5daf764e4b30383aa0144";

export default node;
