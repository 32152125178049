import { graphql } from 'babel-plugin-relay/macro';

export const TchApptgroupClassroomeventMutation = graphql`
mutation TchApptgroupClassroomeventMutation(
    $lessonUuid: String!,
    $eventName: String!
) {
  tch_apptgroup_classroomevent(
    lessonUuid: $lessonUuid,
    eventName: $eventName
) {
    success
  }
}`;
