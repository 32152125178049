import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import { TchApptgroupUpdatelayoutMutation } from '../relay/TchApptgroupUpdatelayout';
import { useReportClassroomEvent } from './useReportClassroomEvent';
import { ClassroomEvent } from '../utils/classRoomEvents';

export const useUpdateRoomLayout = () => {
  // state for enable and disable UI buttons for optimistic update 
  const [loading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [UpdateRoomLayoutMutation] = useMutation(TchApptgroupUpdatelayoutMutation);

  /**
   * there are multiple places from where we are reporting classroom related events, so
   * instead of duplicating same code, we created a hook, which is reporting classroom related 
   * events from single place and exposing reportClassroomEvent.
   */
  const { reportClassroomEvent } = useReportClassroomEvent();

  const noOfRetries = 2;
  let retryCount = 0;
  useEffect(() => {
    if (error) {
      throw new Error('Failed to update room layout');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * Updates the room layout and reports the event based on the room view.
   * 
   * @param uuid - lesson uuid
   * @param updatedHash - The updated layout data as an object.
   * @param whichRoomView - Optional flag indicating the room view mode.
   *    - `true` for lecture mode
   *    - `false` for group chat mode
   *    - `undefined` for other cases when teacher changing 
   *    - the speaking to or moving students between groups
 */
  const mutateRoomLayout = (uuid: string, updatedHash: any, whichRoomView?: boolean) => {
    const updatedClassRoomData = JSON.stringify(updatedHash);
    UpdateRoomLayoutMutation({
      variables: {
        lessonUuid: uuid,
        data: updatedClassRoomData
      },
      // optimisticUpdater will update the relay store as the mutation called
      optimisticUpdater: () => {
        setIsLoading(true);
      },
      onCompleted() {
        setIsLoading(false);
        // Report the layout change event based on the room view
        if (whichRoomView === true) {
          // Reporting event when switching to lecture mode
          reportClassroomEvent(uuid, ClassroomEvent.LayoutChangeLecture);
        } else if (whichRoomView === false) {
          // Reporting event when switching to group chat mode
          reportClassroomEvent(uuid, ClassroomEvent.LayoutChangeGroup);
        } else {
          // do nothing here
          // Reason: because we only want to record these events when teacher changing the 
          // roomView, which is 1 or 2, in the above conditions we have checked if the whichRoomView
          // is true it means it's teacher only mode, if it's false it means roomView is group chat,
          // this flag can be undefined as well.
          // For Example: when teacher changing the speaking to or moving students between groups,
          // in these cases we don't want to record that events.
        }
      },
      onError(err: any) {
        if (retryCount < noOfRetries) {
          retryCount += 1;
          setTimeout(() => {
            mutateRoomLayout(uuid, updatedHash, whichRoomView);
          }, 2500);
        } else {
          setIsLoading(false);
          retryCount = 0;
          setError(err);
          Sentry.captureException(`UpdateRoomLayoutMutation: ${err}`);
        }
      },
    });
  };

  return { loading, error, mutateRoomLayout };
};
