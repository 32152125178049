import React, {
  Suspense,
  useEffect, useState
} from 'react';
import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-relay';
import { Step3RegularQueryData } from './Step3RegularQueryData';
import { CustomMutationDataMutation } from '../../relay/CustomMutationData';
import { CustomErrBoundaries } from '../views/CustomErrBoundary';

interface Props {
  setMainView: any;
  setmodalviewContents: any;
  setmodalviewState: any;
}

enum MutationErrResponses {
  LessonStartFarFuture = 'LessonStartFarFuture',
  LessonEnded = 'LessonEnded',
  LessonNoLongerExists = 'LessonNoLongerExists',
  GeneralError = 'GeneralError'
}

/**
 * executing our custom action which gets the initial classroom data whcih contains lesson time 
 * like when to start, session info (group1, group2, teachersonly)
 */
export const Step2CustomMutationData = ({
  setMainView,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // #region for Mutation call

  // getting lesson UUID from the search parameters in the URL
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  // hold the lesson data references.
  const [classroomDataFragmentRef, setClassroomDataFragmentRef] = useState();
  // mutation call
  const [ClassRoomMutation] = useMutation(CustomMutationDataMutation);
  const [isError, setIsError] = useState(false);

  // this useEffect will triger and execute the mutation call.
  useEffect(() => {
    /**
     * why is mutation being called in useEffect/on pageload. But I think that’s because…
     * This is not actually mutating any data, it’s getting data 
     * Reason it’s a mutation is just the problem with Hasura, if we want a custom api
     * call it must be a mutation
     */
    ClassRoomMutation({
      variables: {
        // need to send lesson uuid from params.
        lessonUuid: uuid,
      },
      onCompleted(data: any) {
        // in this callback we will get fragmentRef
        setClassroomDataFragmentRef(data.apptgroup_classroom_tch_enter);
      },
      onError(err: any) {
        // get the error code our backend sends us
        const fullErr = JSON.parse(err.message);
        const errCode = fullErr.extensions.code;
        handleMutationError(errCode, err);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  // #region handle mutation error

  const handleMutationError = (err: string, fullError: any) => {
    // this lesson has already ended
    if (err === MutationErrResponses.LessonEnded) {
      setMainView(102);

      // lesson starts more than 3 hours from now
    } else if (err === MutationErrResponses.LessonStartFarFuture) {
      setMainView(103);

      // lesson was canceled, or teacher missed it so it is no longer going to occur
    } else if (err === MutationErrResponses.LessonNoLongerExists) {
      setMainView(104);

      // general error -- we'll show our general error in custom error boundary
    } else if (err === MutationErrResponses.GeneralError) {
      setIsError(true);

      // some unknown/unexpected error, log and show general error
    } else {
      Sentry.captureException(
        new Error('ClassRoomMutation unexpected error'),
        {
          extra: {
            theErr: fullError,
          }
        }
      );
      setIsError(true);
    }
  };

  // throw in some cases, e.g. an unexpected error. will trigger our custom error boundary
  if (isError) {
    throw new Error(CustomErrBoundaries.GeneralError);
  }

  // #endregion

  // waiting for the lesson data
  if (!classroomDataFragmentRef) {
    return (null);
  }

  return (
    <Suspense>
      <Step3RegularQueryData
        setMainView={setMainView}
        classroomDataFragmentRef={classroomDataFragmentRef!}
        uuid={uuid!}
        setmodalviewContents={setmodalviewContents}
        setmodalviewState={setmodalviewState}
      />
    </Suspense>
  );
};
