import React, { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { useSubscription } from 'react-relay';
import { GetEnrolledStudentsFragment$data } from '../relay/__generated__/GetEnrolledStudentsFragment.graphql';
import { GetEnrolledStudentsSubscription } from '../relay/GetEnrolledStudents';
import { GetEnrolledStudentsSubscription as SubscriptionType } from '../relay/__generated__/GetEnrolledStudentsSubscription.graphql';

export const GetEnrolledStudents = ({
  setStudentFragmentRef
}: any) => {
  // #region for `ApptgroupRootTchReservationsubs` subscription 

  const [searchParams] = useSearchParams();
  // Extracting the lesson UUID from the search parameters in the URL.
  // lessonUUID is required to fetch lesson related data via subscription
  const uuid = searchParams.get('uuid');

  // subsctiption configurations for studentReservation,
  // that will be used later inside "useCallback" hook/
  const registeredStudents: any = useMemo(() => ({
    // Define a subscription object
    subscription: GetEnrolledStudentsSubscription,
    // Define variables for the subscription
    variables: {
      lessonUuid: uuid,
    },
    // a callback that is executed when a subscription payload is received
    onNext: (res: GetEnrolledStudentsFragment$data | null | undefined) => {
      // Setting the fragment reference using the received payload
      setStudentFragmentRef(res);
    },
    // a callback that is executed when the subscription errors.
    onError: (err: any) => {
      // TODO: need to decide what can we do
      Sentry.captureException(err);
      Sentry.captureMessage('UIT Registered Student Subsription!');
    },
    // a callback that is executed when the server ends the subscription
    onCompleted: () => {
      // TODO: need to decide what can we do
      // Sentry.captureMessage('UIT Registered Student Subsription completed!');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  // executing the subscription for student reservation.
  useSubscription<SubscriptionType>(registeredStudents);

  // #endregion

  return (
    <div />
  );
};
