import React from 'react';
import { Box, Ty } from '@languageconvo/wcl';
import { StudentData } from './StudentsSection';
import { StudentOnlineIndicator } from '../CommonGroupComponents';

interface Props {
  isOnline: boolean;
  student: StudentData;
  groupUUID: string;
  handleClickForStudent: any;
}

// displaying individual student with online/offline status and 
// when teacher click on this student, it notify studentsSection component 
// which opens a dialog and show details(student info and student current group ).
export const StudentsSection4IndividualStudent = ({
  isOnline, student, groupUUID, handleClickForStudent,
}: Props) => (
  <Box
    sx={{
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginTop: '5px',
    }}
    // @ts-ignore
    onClick={(event) => handleClickForStudent(event, student, groupUUID)}
  >
    <StudentOnlineIndicator isOnline={isOnline} />
    <Ty removeMb noWrap>{student.name}</Ty>
  </Box>
);
