import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Button, Dialog, Divider, Select, SelectChangeEvent,
  Ty,
  Grid2Ct,
  Grid2,
  Ic
} from '@languageconvo/wcl';
import { useClientQuery, useFragment } from 'react-relay';
import useVonage from '../../hooks/useVonage';
import { MicAndCameraDetailsQuery } from '../../../../../common/relay/queries/MicAndCameraDetails';
import { CameraDetailsFragment } from '../../../../../common/relay/queries/fragments/CameraDetails';
import { getAvailableCam } from '../../context/VonageProviderHelper';
import { setAllAvailableCameraInRelayStore, setCurrentCameraInRelayStore } from '../../../../../common/relay/clientschema/relayappsettings/cameraFunctionality';
import { UpdateCameraIDInLocalStorage } from '../../../../../common/camera/localStore/toggleCameraInLCStorage';

// enable user to change camera and allowed to toggle camera state.
export const AudioVideoControlsCamDialog = (props: any) => {
  // our client query to fetch data from relay store.
  const response: any = useClientQuery(
    MicAndCameraDetailsQuery,
    {}
  );
  const fragmentR = response.RelayAppSettings;
  // reading camera details from Relay store (single source of truth)
  // to show current camera info, all camera in select dropdown, and
  // and need to show camera on/off state 
  const cameraData = useFragment(CameraDetailsFragment, fragmentR);
  const cameraInfo = cameraData.camera;

  // #region general setup react state variables.

  // Destructuring the functions from useVonage hook
  const { turnCameraOff, turnCameraON } = useVonage();
  const { anchorElForCamera, handleCloseForCamera } = props;

  // state for storing teacher's avalilable video sources
  // need to show in select component 
  const [videoSources, setVideoSources] = useState<Array<{ name: string; value: string }>>([]);

  // state for storing the currently selected camera ID
  // initialized with the current camera ID from relay store or null to show select camera
  // in select component
  const [selectedCamera, setSelectedCamera] = useState<string | null>(
    cameraInfo.current.camId || null
  );

  // Boolean value to check if anchor element is open or closed
  const open = Boolean(anchorElForCamera);

  // #endregion

  // #region for camera handling

  // this function is responsible for selceting the camera from dropdown(UI)
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedCamera(event.target.value);
  };

  // here we are stroing the selected camera to our relay store
  const saveSelectedCamera = () => {
    /* Usually, the camera the user selected in the dropdown will still exist in the relay store.
        In that case, selectedCameraDetails.length is true. But in some rare scenarios, the
        camera the user has selected in the dropdown won't actually exist in the relay store
        anymore (for example, they are publishing with that camera, but then it becomes
        unplugged). That rare case is the else statement below
    */
    const selectedCameraDetails = cameraInfo.all.edges.filter(
      (cam: any) => cam.id === selectedCamera
    );

    if (selectedCameraDetails.length) {
      // updating the current obj in relay store so we can get the updated value 
      // in whole group lesson
      setCurrentCameraInRelayStore(selectedCameraDetails[0].id, selectedCameraDetails[0].title);

      // storing cameraID in localStorage this helps maintain the current camera across 
      // page reloads. since the relay store will be empty on page reload,
      //  we retrieve the current camera info from localStorage and update the 
      // relay store accordingly.
      UpdateCameraIDInLocalStorage(selectedCameraDetails[0].id);
    } else {
      // TODO: future, after group lessons v1 -- add a notif within the modal that the device
      //  the user has selected is no longer available. For now, we are doing nothing; the
      //  select options will automatically change IF the camera that was unplugged is the camera
      //  the user is publishing but they will NOT change if the camera unplugged is any other
    }
    // closing dialog after camera selected from dropdown
    handleCloseForCamera();
  };

  // function toggle camera state in relay store and update the
  // localStorage accordingly 
  const toggleCameraState = () => {
    if (!cameraInfo.current.isCameraOff) {
      turnCameraOff();
    } else {
      turnCameraON();
    }
    handleCloseForCamera();
  };

  // #endregion

  // #region for getting video device

  // fetching current attached video sources from relay store to show teacher's 
  // currently available video devices.
  const fetchVideoSourceAndDisplay = () => {
    const videoOptions = cameraInfo.all.edges.map((device: any) => ({
      name: device.title,
      value: device.id,
    }));
    // transform the data into the required format and update the state
    // to populate the dropdown options with available video devices.
    setVideoSources(videoOptions);
  };

  // this useEffect will get the available video sources from relay store
  useEffect(() => {
    fetchVideoSourceAndDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraInfo]);

  // get all the video devices that user has plugged in. update the relay store `all` obj
  // which holds list of all available video devices.
  const getCurrentAttachedDevices = async () => {
    await getAvailableCam().then((devices: any) => {
      // here we storing newly plugged in audio devices in relay store
      setAllAvailableCameraInRelayStore(devices);
    }).catch(() => {
      // for some reason, we failed to get audio devices. update the relay store
      setAllAvailableCameraInRelayStore([]);
      // in case of no audio device found we are showing error in pageView
    });
  };

  // any time the teacher opens the modal, we get all the user's camera to ensure that
  // our list of camera in the dropdown is updated/correct
  useLayoutEffect(() => {
    if (open) {
      getCurrentAttachedDevices();
    }
  }, [open]);

  // #endregion

  return (
    <Dialog
      isOpen={open}
      onClose={handleCloseForCamera}
      width="xs"
      color="accentBlue1"
    >
      <Grid2Ct sx={{ mt: 2 }}>
        <Grid2 xs={12}>
          {/* show a warning that no camera exist */}
          {videoSources.length === 0 ? <NoCameraExist /> : <div />}
          {/* select a camera, save setting */}
          <Select label="Select Your Camera" value={selectedCamera!} onChange={handleSelectChange} options={videoSources} />

          <Button color="accentBlue1" disabled={videoSources.length === 0} fullWidth onClick={saveSelectedCamera} cp={{ sx: { mt: 1 } }}> Save </Button>

          {/* divider */}
          <Divider cp={{ sx: { mt: 4, mb: 4 } }} />

          <Ty v="p">
            {cameraInfo.current.isCameraOff ? <CameraOffText /> : <CameraOnText />}
          </Ty>

          {/* button to turn camera on or off */}
          {cameraInfo.current.isCameraOff
            ? <CameraOffButton onClick={toggleCameraState} />
            : <CameraOnButton onClick={toggleCameraState} />}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};

// #region sub components

const CameraOffText = () => (
  <span>
    <Ic iconName="triangle-exclamation" color="accentRed1" iconStyle="duotone" />&nbsp;
    <strong>Your camera is off!</strong> Students will&nbsp;
    <strong>not</strong> be able to see your video. Click the button below to turn it back on.
  </span>
);

const CameraOffButton: any = (props: any) => (
  <Button color="accentBlue1" fullWidth onClick={props.onClick} cp={{ sx: { mt: 1, mb: 2 } }}>
    Turn Camera&nbsp;<strong>On</strong>
  </Button>
);

const CameraOnText = () => (
  <span>
    Your Camera is&nbsp;
    <strong>on</strong>. Students should now be able to see your video
  </span>
);

const CameraOnButton: any = (props: any) => (
  <Button color="accentBlue1" fullWidth onClick={props.onClick} cp={{ sx: { mt: 1, mb: 2 } }}>
    Turn Camera&nbsp;<strong>Off</strong>
  </Button>
);

const NoCameraExist = () => (
  <Grid2Ct sx={{ mb: 2 }}>
    <Grid2 xs={12}>
      <Ty>
        <Ic iconName="triangle-exclamation" color="accentRed1" iconStyle="duotone" />&nbsp;
        <strong>We could not find any camera!</strong> Check to make sure you have a camera
        plugged in to your device. If you do, try refreshing the page.
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// #endregion
