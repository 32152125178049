import React from 'react';
import {
  Box, Card, CardContent, Grid2, Grid2Ct, styled, WCLThemeProps,
} from '@languageconvo/wcl';

// UI for teacher video and screen sharing components.
export const TeacherStreamDisplay = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Grid2Ct>
      {/* regular video. don't display anything if the teacher is speaking to nobody */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="center">
              <VideoContainer id="publisher" />
            </Box>
          </CardContent>
        </Card>
      </Grid2>

      {/* screen sharing */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="center">
              <VideoContainer id="screen-sharing" />
            </Box>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

// container for the video
const VideoContainer = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  aspectRatio: '16/9',
  width: 'auto',
  height: '270px',
  overflow: 'hidden',
}));
