// Generates an array of options based on room layout and initial room data.
export const optionsArray = (initialRoomData: any, roomLayoutData: any) => {
  let options: any = [];
  if (roomLayoutData.roomView === 1) {
    options = [
      {
        name: 'Everyone',
        value: JSON.stringify({ entity: 1, value: null }),
      },
      {
        name: 'Nobody',
        value: JSON.stringify({ entity: 4, value: null }),
      },
    ];
  } else if (roomLayoutData?.roomView === 2) {
    options = [
      {
        name: 'Group 1',
        value: JSON.stringify({
          entity: 2,
          value: initialRoomData?.av?.studentGroupRooms[0]?.uuid
        }),
      },
      {
        name: 'Group 2',
        value: JSON.stringify({
          entity: 2,
          value: initialRoomData?.av?.studentGroupRooms[1]?.uuid
        }),
      },
      {
        name: 'Nobody',
        value: JSON.stringify({ entity: 4, value: null }),
      },
    ];
  }
  return options;
};
