const graphql = require('babel-plugin-relay/macro');

export const VerifyAuthToken = graphql`
mutation VerifyAuthTokenMutation($authToken: String!) {
    resources_auth_verify_token(authToken: $authToken) {
        jwt
        data {
          resourceId
          languageId
          email
          image
          name
          timezone
        }
      }
    }
`;
