import React, {
  ReactNode, createContext, useState, useEffect,
} from 'react';
import useResponsive from '../hooks/useResponsive';
// hooks
import { CollapseDrawerContextProps } from '../types/drawer.type';
import { getDataToLCStorage, setDataToLCStorage } from '../utils/lcStorage';
// ----------------------------------------------------------------------

const initialState: CollapseDrawerContextProps = {
  isCollapse: false,
  onToggleCollapse: () => {},
};

const CollapseDrawerContext = createContext(initialState);
  type CollapseDrawerProviderProps = {
    children: ReactNode;
  };

const CollapseDrawerProvider = ({ children }: CollapseDrawerProviderProps) => {
  const oldettings = getDataToLCStorage('lc_tchsettings');
  // set the drawer collapse state based on previous saved value stored in localStorage.
  const [isCollapse, setIsCollapse] = useState(oldettings?.navbar?.collapse || false);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    // on mobile devies, close collapse drawer if already open
    if (!isDesktop) {
      setIsCollapse(false);
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    setIsCollapse(!isCollapse);
    // updating sidenav collapse state...

    const storedSettings = getDataToLCStorage('lc_tchsettings');
    const newSettings = {
      navbar: {
        ...storedSettings.navbar,
        collapse: !isCollapse
      },
    };
    setDataToLCStorage('lc_tchsettings', newSettings);
  };

  const value = React.useMemo(() => ({
    isCollapse,
    onToggleCollapse: handleToggleCollapse,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [isCollapse]);

  return (
    <CollapseDrawerContext.Provider value={value}>
      {children}
    </CollapseDrawerContext.Provider>
  );
};

export { CollapseDrawerProvider, CollapseDrawerContext };
