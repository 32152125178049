import React, { useState } from 'react';
import {
  Alert,
  TextField,
  Button,
  Card,
  Grid,
  Container,
  Select,
  SelectChangeEvent
} from '@languageconvo/wcl';

export const options = [
  {
    name: 'Five',
    value: 10,
  },
  {
    name: 'Twenty',
    value: 20,
  },
  {
    name: 'Thirty',
    value: 30,
  }
];

const ButtonTest = () => {
  const [, setOpen] = useState(true);
  const [selectValue, setSelectValue] = useState<string>();

  const onClick = () => {
    setOpen(false);
  };

  const HandleSelectChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
  };
  const buttonVariant = 'outlined';
  const longText = 'Buttonverylongtexthasanoverflowproblem';
  return (
    // all pages should probably have a container, but we need to do more research on this
    <Container maxWidth="xl">
      {/* at all breakpoints, this section is the same. a button 4 columns wide */}
      <Grid container>
        <Grid item xs={4}>
          <Button fullWidth variant={buttonVariant}>Button</Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant={buttonVariant}>Button</Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant={buttonVariant}>Button</Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant={buttonVariant}>{longText}</Button>
        </Grid>
      </Grid>

      {/* different column widths at md */}
      <Grid container>
        <Grid item xs={8} md={6}>
          <Button fullWidth variant={buttonVariant} color="secondary">Button</Button>
        </Grid>
        <Grid item xs={4} md={6}>
          <Button fullWidth variant={buttonVariant} color="secondary">Button</Button>
        </Grid>
      </Grid>

      {/* different column widths at xl */}
      <Grid container>
        <Grid item xs={8} xl={3}>
          <Button fullWidth variant={buttonVariant} color="info">Button</Button>
        </Grid>
        <Grid item xs={4} xl={3}>
          <Button fullWidth variant={buttonVariant} color="info">Button</Button>
        </Grid>
        <Grid item xs={4} xl={3}>
          <Button fullWidth variant={buttonVariant} color="info">Button</Button>
        </Grid>
        <Grid item xs={4} xl={3}>
          <Button fullWidth variant={buttonVariant} color="info">Button</Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} md={3}>
          <Select label="Enter Your Age" value={selectValue} onChange={HandleSelectChange} options={options} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select label="Disabled" disabled value={selectValue} onChange={HandleSelectChange} options={options} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select label="Error" error value={selectValue} onChange={HandleSelectChange} options={options} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select label="Small Sized" size="small" value={selectValue} onChange={HandleSelectChange} options={options} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} md={4}>
          <TextField id="login-email" type="email" label="Outlined" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} md={4}>
          <TextField id="login-email" type="email" label="Disabled" disabled />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField id="login-email" type="email" label="Email Address" error helpertext="Email is required" />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField id="login-email" type="text" label="Standard" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Alert onClick={onClick} variant="outlined" title="You may test toggle alert here" severity="error">This is an error alert with variant outlined & toggle working</Alert>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Alert variant="outlined" title="Warning" severity="warning">This is a warning alert with variant outlined</Alert>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Alert variant="outlined" title="Info" severity="info">This is an info alert with variant outlined</Alert>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Alert variant="outlined" title="Success" severity="success">This is a success alert with variant outlined</Alert>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Alert variant="filled" title="Error" severity="error">This is an error alert with variant filled</Alert>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Alert variant="filled" title="Warning" severity="warning">This is a warning alert with variant filled</Alert>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Alert variant="filled" title="Info" severity="info">This is an info alert with variant filled</Alert>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Alert variant="filled" title="Sucess" severity="success">This is a success alert with variant filled</Alert>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Card />
        </Grid>
        <Grid item xs={6}>
          <Card />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ButtonTest;
