import React, { useState } from 'react';
import {
  Dialog, Ty, Box, Select, Button, SelectChangeEvent, Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { useUpdateRoomLayout } from '../../hooks/useUpdateRoomLayout';
import { moveStudentIntoGroup } from '../../utils/moveInGroup';
import { SelectedStudentData } from './StudentsSection';
import { CustomMutationDataLessonDetailFragment } from '../../relay/CustomMutationData';
import {
  CustomMutationDataLessonDetailFragment$key,
  CustomMutationDataLessonDetailFragment$data
} from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
import { StudentOnlineIndicator } from '../CommonGroupComponents';

interface Props {
  anchorElForStudent: HTMLButtonElement | null;
  handleCloseForStudent: () => void;
  selectedStudent: SelectedStudentData;
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  layoutNode: any;
  onlineUsers: string[];
  isLessonStarted: boolean;
}

// hold selected student info and student online/offline status via pusher
export const StudentsSection6Dialog = ({
  anchorElForStudent,
  handleCloseForStudent,
  selectedStudent,
  classroomDataFragmentRef,
  layoutNode,
  onlineUsers,
  isLessonStarted,
}: Props) => {
  // #region general region setup for react state and varriable

  // reading classroom data from fragment.
  const initialClassroomData: CustomMutationDataLessonDetailFragment$data = useFragment(
    CustomMutationDataLessonDetailFragment,
    classroomDataFragmentRef
  );

  // these options will be pass to select compoennt so, teacher able to change student group.
  const options: any = [
    {
      name: 'Group 1',
      value: initialClassroomData.av.studentGroupRooms[0]!.uuid,
    },
    {
      name: 'Group 2',
      value: initialClassroomData.av.studentGroupRooms[1]!.uuid,
    },
  ];

  // state to show in which group student is
  // need to show the current group in divide into group dropdown
  const [studentGroup, setStudentGroup] = useState<string>(selectedStudent.groupUUID);
  // Boolean value to check if anchor element is open or closed
  const open = Boolean(anchorElForStudent);

  /**
   * there are multiple places from where we are mutating room layout data, so
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { mutateRoomLayout } = useUpdateRoomLayout();

  // this handleChange is reponsible for updating the selected value 
  // which is selected by teacher from Select component
  const handleChange = (event: SelectChangeEvent) => {
    const selectedGroup: any = event.target.value;
    setStudentGroup(selectedGroup);
  };

  // add student's into groups
  // responsible to move student between groups and update the roomlayout
  // so correct changes reflected to the student.
  const addStudentIntoGroup = (uuid: string, layoutHash: any) => {
    moveStudentIntoGroup(
      layoutHash,
      uuid,
      studentGroup!,
      selectedStudent,
      handleCloseForStudent,
      mutateRoomLayout,
      initialClassroomData
    );
  };

  const isOnline = onlineUsers.includes(selectedStudent.uuid);
  let onlineText = 'Offline';
  if (isOnline) {
    onlineText = 'Online';
  }

  // #endregion

  return (
    <Dialog
      isOpen={open}
      onClose={handleCloseForStudent}
      width="sm"
      color="accentGreen1"
    >
      {/* name, online status */}
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty>{selectedStudent.name}</Ty>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StudentOnlineIndicator isOnline={isOnline} />
            <Ty removeMb>{onlineText}</Ty>
          </Box>
        </Grid2>
      </Grid2Ct>

      {/* change group */}
      <Grid2Ct sx={{ mt: 3 }}>
        <Grid2 xs={6}>
          <Select
            label="Change Group"
            value={studentGroup}
            options={options}
            fullWidth
            size="medium"
            onChange={handleChange}
          />
        </Grid2>
        <Grid2 xs={6}>
          <Button
            color="accentGreen1"
            size="large"
            // don't allow the teacher to move students between groups until the class has started,
            // because the teacher really needs to know who is online before setting groups
            disabled={!isLessonStarted}
            onClick={() => addStudentIntoGroup(
              initialClassroomData.lessonUuid,
              layoutNode
            )}
          >
            Save Group
          </Button>
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
