import { Device } from '@opentok/client';
import { GetCameraIDFromLCStorage, UpdateCameraIDInLocalStorage } from './localStore/toggleCameraInLCStorage';
import { setAllAvailableCameraInRelayStore, setCurrentCameraInRelayStore } from '../relay/clientschema/relayappsettings/cameraFunctionality';

/** Manages camera selection and persistence.
 * 
 * Purpose:
 * - To handle the scenario where a user may have a preferred camera device for communication.
 * - Ensures the preferred camera is reselected after a page reload by storing the camera ID 
 *  in localStorage.
 * - If the preferred camera is unavailable, it defaults to the first available camera.
 * - Updates the relay store with the current and all available cameras for consistency across 
 *  the groupLesson.
 * 
 * @param {Array} allCamera - List of all available camera devices.
 */
export const cameraManager = (allCamera: any) => {
  let cameraID = allCamera[0]?.deviceId;
  let cameraName = allCamera[0]?.label;

  if (allCamera.length === 0) {
    cameraID = null;
    cameraName = null;
    UpdateCameraIDInLocalStorage(null);
  } else {
    // if previously used camera found in localStorage, find that mic in avaialbe devices 
    // and used it. otherwise set first available micId in localStorage
    const previousCamera = GetCameraIDFromLCStorage();

    if (previousCamera === null) {
      // updating relay store with cameraId and cameraName in relay store present at
      // 0 index of allCamera
      setCurrentCameraInRelayStore(cameraID, cameraName);
      //  update the micID in localStore
      UpdateCameraIDInLocalStorage(cameraID);
    } else {
      // checking previously used camera is available
      const found = allCamera.find((option: Device) => option.deviceId === previousCamera);

      if (found) {
        cameraID = found.deviceId;
        cameraName = found.label;
      }
    }
  }

  // we are updating the relay store no need to update the localStorage here because 
  // cameraID already presetn in localStorage
  setCurrentCameraInRelayStore(cameraID, cameraName);

  // updating all devices in relay store.
  setAllAvailableCameraInRelayStore(allCamera);

  // we return the camera id and name that are now set in the relay store; there are cases where
  // these values are needed immediately, but the relay store update may take time to propogate
  // through react components. in those cases, these return values can be used
  const ret = {
    theCamId: cameraID,
    theCamName: cameraName,
  };
  return ret;
};
