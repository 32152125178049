import React, { useState, useEffect } from 'react';
import {
  Outlet, useNavigate, useLocation
} from 'react-router-dom';
import {
  Grid2, Card, ScrollArea, Link, CardContent,
} from '@languageconvo/wcl';
import { RelayEnvironmentProvider } from 'react-relay';
// before auth environment.
import AuthEnvironment from '../../relay/AuthEnvironment';
// Styles...
import * as Style from './index.style';
import { LoginPageImagesInfo } from '../../constants/loginPageImagesInfo';
import { getDataToLCStorage } from '../../utils/lcStorage';

const LoginLayout = () => {
  const imagesInfo = LoginPageImagesInfo.images;
  const [randomImage, setRandomImage] = useState<{id: number, location: string}>();
  const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * imagesInfo.length));
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const excludedPathToVerifyJWT = ['/logout', '/session-timeout'];

  // if JWT is present, just redirect user to home page.
  // don't need to check JWT validity, In "home" route (parent layout).
  // it will be automatically check jwt validity and do appropriate actions. (optimistic approach)
  useEffect(() => {
    if (!excludedPathToVerifyJWT.includes(pathname)) {
      const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');
      if (storedSecurityValues?.jwt) {
        navigate('/home');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (imagesInfo.length > 0) {
      setRandomIndex(Math.floor(Math.random() * imagesInfo.length));
    }
  }, [imagesInfo]);

  React.useEffect(() => {
    if (imagesInfo.length > 0 && randomIndex < imagesInfo.length) {
      setRandomImage(imagesInfo[randomIndex]);
    } else {
      setRandomIndex(0);
      setRandomImage(imagesInfo[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomIndex]);

  return (
    // All before auth pages requires slightly different environment as we are not sending any
    // JWT there for authentication.
    // Adding Authenvironment here again, although, we already have environment set in
    // App.tsx, Reason is: we'r using hooks, which will automatically use the current environment
    // from it's nearest parent provider (RelayEnvironmentProvider)
    <RelayEnvironmentProvider environment={AuthEnvironment}>
      {/* In the theme, we added row and column spacing along with paddintTop is equal to 3 which
      is not good for top level Grids, so we need to specifically overrding these props
      otherwise, see extra width in container: https://github.com/mui/material-ui/issues/7466 */}
      <Grid2 container sx={{ paddingTop: '0px' }}>
        <Grid2 xs={12} md={6} lg={4} xl={3} sx={{ paddingTop: '0px' }}>
          <Grid2 height="100vh">
            <ScrollArea>
              <Grid2 display="flex" justifyContent="center">
                <Style.Logo src={`${process.env.REACT_APP_CDNURL}/common/logo/logo.svg`} data-testid="logo" alt="Logo" height="70" width="256" />
              </Grid2>
              <Grid2>
                <Outlet />
              </Grid2>
            </ScrollArea>
          </Grid2>
        </Grid2>
        <Grid2 xs={0} md={6} lg={8} xl={9} sx={{ paddingTop: '0px' }} data-testconfig="doNotVisualTest">
          <Style.BackgroundDiv data-testid="div-imageContainer" imageurl={`${process.env.REACT_APP_CDNURL}/common/loginbackgroundimages/${imagesInfo[randomIndex]?.id || 1}.jpg`} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && (
            <Style.CardMain>
              <Card>
                <CardContent cp={{ sx: { width: '100%' } }}>
                  <Style.CardInner>
                    <Style.LocationName cp={{ 'data-testid': 'typography-locationName' }}> {randomImage?.location} </Style.LocationName>
                    <br />
                    <Link cp={{ 'data-testid': 'link-explore' }} onClick={() => setRandomIndex(Math.floor(Math.random() * imagesInfo.length))}>
                      explore
                    </Link>
                  </Style.CardInner>
                </CardContent>
              </Card>
            </Style.CardMain>
            )}
          </Style.BackgroundDiv>
        </Grid2>
      </Grid2>
    </RelayEnvironmentProvider>
  );
};
export default LoginLayout;
