import React from 'react';
import { Ty, Grid2Ct, Grid2 } from '@languageconvo/wcl';
import { StudentsSection4IndividualStudent } from './StudentsSection4IndividualStudent';
import { SelectedStudentData } from './StudentsSection';

interface Props {
  studentsNotInGroups: { name: string; uuid: string; }[],
  onlineUsers: string[],
  handleClickForStudent: (
    _event: React.MouseEvent<HTMLButtonElement>,
    _student: SelectedStudentData, _groupUUID: string) => void
}

// Component displaying a list of students not assigned to any group in a classroom.
// It fetches the initial classroom data and utilizes Pusher for real-time 
// updates on student status.
export const StudentsSection3NoGroup = ({
  onlineUsers,
  studentsNotInGroups, handleClickForStudent
}: Props) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <Ty>No Group </Ty>
      <hr />
      {/* display each student */}
      {studentsNotInGroups.map((stu: any) => {
        const isOnline = onlineUsers.includes(stu.uuid);
        return (
          <StudentsSection4IndividualStudent
            isOnline={isOnline}
            student={stu}
            groupUUID=""
            handleClickForStudent={handleClickForStudent}
          />
        );
      })}
    </Grid2>
  </Grid2Ct>
);
