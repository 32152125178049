import React from 'react';
import {
  Grid2Ct, Grid2, CardStandard, IcSvgList, Ty, Link,
} from '@languageconvo/wcl';

// our welcome component for now we are showing welcome text
export const Welcome = () => (
  <Grid2Ct>
    <Grid2
      xs={12}
      md={7}
      lg={8}
      sx={{
        display: 'flex',
      }}
    >
      <CardStandard
        titleText="Welcome"
        titleIcon={IcSvgList.desk1}
        color="accentOrange1"
        titleIconRight={0}
        cp={{ sx: { width: '100%' } }}
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty cp={{ sx: { mt: 1 } }}>
              Welcome &mdash; this app will host just a few things that are too complex to
              build in UI Bakery. For now, the group classroom is here and in the future,
              the private 1-on-1 lesson classroom will also be here. We&apos;ve tried to
              make it as easy as possible to get here from UI Bakery; you should be able to
              click a button to automatically authenticate (log in) to this app.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </CardStandard>
    </Grid2>
    <Grid2
      xs={12}
      md={5}
      lg={4}
      sx={{
        display: 'flex',
      }}
    >
      <CardStandard
        titleText="Links"
        titleIcon={IcSvgList.earth1}
        color="accentPurple1"
        titleIconRight={0}
        cp={{ sx: { width: '100%' } }}
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Link
              color="accentPurple1"
              href="https://cloud.uibakery.io/"
              target="_blank"
              rel="noopener"
            >
              UI Bakery
            </Link><br />
            <Link
              color="accentPurple1"
              href="https://cloud.uibakery.io/"
              target="_blank"
              rel="noopener"
            >
              Legacy App
            </Link><br />
            <Link
              color="accentPurple1"
              href="https://www.notion.so/lcteam/"
              target="_blank"
              rel="noopener"
            >
              Knowledge Base
            </Link><br />
            <Link
              color="accentPurple1"
              href="https://teamchat.languageconvo.com/home"
              target="_blank"
              rel="noopener"
            >
              Team Chat
            </Link>
          </Grid2>
        </Grid2Ct>
      </CardStandard>
    </Grid2>
  </Grid2Ct>
);
