import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import {
  Box, Card, CardContent, Skeleton, Ty, Grid2Ct, Grid2, styled, WCLThemeProps,
} from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import { useFragment } from 'react-relay';
import {
  lectureTimeTracker,
  validateAndSetBrowserTimeDifference,
} from './LessonInfoHelper';
import useVonage from '../../hooks/useVonage';
import { QueryDataLessonDetailsFragment } from '../../relay/QueryData';
import { QueryDataLessonDetailsFragment$key } from '../../relay/__generated__/QueryDataLessonDetailsFragment.graphql';
import { CustomMutationDataLessonDetailFragment$key } from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
import { CustomMutationDataLessonDateTimeFragment } from '../../relay/CustomMutationData';
import { heightStudentLsnInfo } from '../CommonGroupComponents';

interface Props {
  lessonDetailsFragmentRef: QueryDataLessonDetailsFragment$key;
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  reservedStudentLenght: number;
  isLessonStarted: boolean;
  setIsLessonStarted: Dispatch<SetStateAction<boolean>>;
  lessonEnded: boolean;
  setLessonEnded: Dispatch<SetStateAction<boolean>>;
  startVonage: boolean;
  setStartVonage: Dispatch<SetStateAction<boolean>>;
  setmodalviewContents: any;
  setmodalviewState: any;
  stdWhoRaisedHand: any
}

/* Here we are showing lesson info (time remaining in lesson end, no of student 
  in the lesson, etc.)  This componnet also responsible to start vonage stream if
  lesson time started. */
export const LessonInfo = ({
  lessonDetailsFragmentRef,
  classroomDataFragmentRef,
  reservedStudentLenght,
  isLessonStarted,
  setIsLessonStarted,
  lessonEnded,
  setLessonEnded,
  startVonage,
  setStartVonage,
  setmodalviewContents,
  setmodalviewState,
  stdWhoRaisedHand
}: Props) => {
  // #region lesson details from relay

  // lesson details from our custom data/mutation
  const lessonInfo: any = useFragment(
    CustomMutationDataLessonDateTimeFragment,
    classroomDataFragmentRef
  );
  const {
    secondsUntilEnd, secondsUntilStart, startsAt, endsAt
  } = lessonInfo.dt;

  // lesson data from our regular query
  const lessonBasicData = useFragment(
    QueryDataLessonDetailsFragment,
    lessonDetailsFragmentRef,
  );
  const lessonTitle = `Skill #${lessonBasicData.skills.display_order} - ${lessonBasicData.skills.title_en}`;

  // #endregion

  // #region general vars

  // destructuring the disconnect function from useVonage hook ,we need to disconnect 
  // the teacher from vonage sesssion when lesson end
  const {
    disconnect
  } = useVonage();

  // state variable for storing the seconds remaining in lesson end
  // we are showing time remaining in lesson end on UI this state is 
  // being updated after each second and calculating the exact time reamining in lesson end
  const [secondsRemainingInLessonEnd, setSecondsRemainingInLectureEnd] = useState<number>(
    secondsUntilEnd
  );

  // state variable for storing the seconds remaining in lesson start
  // with the help of this state we are updating our flags startVonage & isLessonStarted 
  const [secondsRemainingInLessonStart, setSecondsRemainingInLectureStart] = useState<number>(
    secondsUntilStart
  );

  // #endregion

  // #region for checking is the user's browser time is correct.

  // to store diff between browsers & time received from backend.
  // need to show the exact lesson time that's why we are calculating `timeDifference`
  // for more details check comments on  `validateAndSetBrowserTimeDifference` 
  const [timeDifference, setTimeDifference] = useState(0);
  useEffect(() => {
    validateAndSetBrowserTimeDifference(
      lessonInfo,
      setTimeDifference,
      setmodalviewContents,
      setmodalviewState,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  // #region for calculating start and end time of lesson.

  // `lectureTimeTracker` function is responsible for
  // calculating start and end time of lesson. check comments on funciton for more detail.
  // we are showing Time Remaining in lesson end on the UI.
  useEffect(() => {
    const intervalCall = setInterval(() => {
      lectureTimeTracker(
        timeDifference,
        lessonInfo,
        setSecondsRemainingInLectureStart,
        setSecondsRemainingInLectureEnd
      );
    }, 1000);
    return () => {
      // clean up, for clearing the interval
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDifference]);

  // #endregion

  // #region for decision making either we have to initiate the lesson or end the leeson

  // this responsible for initiating the lesson and connecting the teacher
  // to the vonage session
  useEffect(() => {
    if (secondsRemainingInLessonStart < 0 && !isLessonStarted) {
      setIsLessonStarted(() => true);
    }
    if (Math.trunc(secondsRemainingInLessonStart) <= 10 && !startVonage) {
      setStartVonage(() => true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsRemainingInLessonStart]);

  /* 
    when lesson ended we are showing a popup and disconnecting teacher from session
    this function is responsible for disconnecting the teacher from vonage 
    session and showing lesson ended UI
  */
  useEffect(() => {
    if (secondsRemainingInLessonEnd < 0 && !lessonEnded) {
      // Disconnect the teacher from the Vonage session
      // to end the lesson session.
      disconnect();

      // setting `setLessonEnded` state true so we can show Lesson ended Dialog to the teacher 
      setLessonEnded(() => true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsRemainingInLessonEnd, lessonEnded]);

  // #endregion

  // #region getting lesson start and end time

  // Extracting the start and end times from lessonInfo using Luxon and converting
  // time into hour options for toLocaleString
  const opts: any = {
    // hourCycle for teachers is always h12. we should probably set this as a common util/var
    hourCycle: 'h12',
    // here we difine what type of output we want. in this example, we'll get something 
    // like "5:03 PM"
    timeStyle: 'short',
  };
  const startTime = DateTime.fromISO(startsAt).toLocaleString(opts);
  const endTime = DateTime.fromISO(endsAt).toLocaleString(opts);

  // calculating remaining lesson time
  const timeRemaining = secondsRemainingInLessonEnd >= 3600
    ? `${Math.floor(secondsRemainingInLessonEnd / 3600)} h : ${
      String((Math.floor(secondsRemainingInLessonEnd / 60) % 60).toFixed(0)).padStart(2, '0')
    } m`
    : `${Math.floor(secondsRemainingInLessonEnd / 60)}:${
      String(Math.floor(secondsRemainingInLessonEnd % 60).toFixed(0)).padStart(2, '0')
    }`;

  // #endregion

  return (
    <Card>
      <CardContent cp={{ sx: { minHeight: heightStudentLsnInfo } }}>
        {/* countdown until lesson starts, and lesson ends */}
        {isLessonStarted ? (<ContdownUntilEnd count={timeRemaining} />)
          : (<ContdownUntilStart count={secondsRemainingInLessonStart} />)}

        {/* lesson title, time, etc. */}
        <Grid2Ct sx={{ mt: 2 }}>
          <Grid2 xs={12}>
            <Ty>{lessonTitle}</Ty>

            {/* lesson length, start end time */}
            <Ty>
              {' '}
              {startTime} - {endTime}{' '}
            </Ty>
            <Ty>{lessonInfo.dt.duration} Minutes</Ty>

            {/* Displaying  the number of students reserved,  */}
            {/* or an empty array if studentInfo is undefined  */}
            {reservedStudentLenght > 0 ? (
              <Ty>
                {reservedStudentLenght} Students Reserved
              </Ty>
            ) : (
              <Skeleton animation="wave" width={150} height={20} />
            )}

            <br />

            {
              stdWhoRaisedHand.length > 0 ? (
              // sorting the students by the time they raised their hands,
              // to ensures that the most recent hand raises are displayed on the top
                stdWhoRaisedHand
                  .sort((a: any, b: any) => DateTime.fromISO(b.handRaisedTS).diff(DateTime.fromISO(
                    a.handRaisedTS
                  )).milliseconds)
                  .map((std: any) => {
                    // spliting the student's name into first and last names as we are
                    // showing first and first letter of the last name
                    const [firstName, lastName] = std.name.split(' ');
                    // picking the first letter of last name
                    const lastInitial = lastName.charAt(0);

                    // enum for student feedback 
                    const feedbackMap: any = {
                      1: 'Can hear well!',
                      2: 'Cannot hear anything',
                      3: 'Can hear, but audio is bad'
                    };
                    const studentFeedback = feedbackMap[std.handRaisedValue] || '';

                    return (
                      <Grid2Ct>
                        {/* student name */}
                        <Grid2 xs={12} sx={{ pb: 0 }}>
                          <Ty removeMb>
                            <strong>{firstName} {lastInitial}.</strong>
                          </Ty>
                        </Grid2>

                        {/* time and notification */}
                        <Grid2 xs={12} sx={{ pt: 0 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* showing student feedback to the teacher */}
                            <Ty>{studentFeedback}</Ty>
                            {/* displaying the time the student raised their hand, 
                          formatted with medium(allow us to show time with seconds) time style */}
                            <Ty>
                              {DateTime.fromISO(std.handRaisedTS).toLocaleString({
                                ...opts,
                                timeStyle: 'medium',
                              })}
                            </Ty>
                          </Box>
                        </Grid2>
                      </Grid2Ct>
                    );
                  })
              ) : (null)
            }

          </Grid2>
        </Grid2Ct>
      </CardContent>
    </Card>
  );
};

// #region countdown until start

interface CountStartProps {
  count: number
}
export const ContdownUntilStart = ({ count }: CountStartProps) => (
  <CountdownUntilStartContainer>
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty v="h2New" align="center" removeMb>Class starts in...&nbsp;{Math.floor(count / 60).toString()}:{String(Math.floor(count % 60).toFixed(0)).padStart(2, '0')}</Ty>
      </Grid2>
    </Grid2Ct>
  </CountdownUntilStartContainer>
);

export const CountdownUntilStartContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentRed1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '20px 20px 20px 20px',
}));

// #endregion

// #region countdown until end

interface CountEndProps {
  count: string
}
export const ContdownUntilEnd = ({ count }: CountEndProps) => (
  <CountdownUntilEndContainer>
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty v="h2New" align="center" removeMb>Class ends in...&nbsp;{count}</Ty>
      </Grid2>
    </Grid2Ct>
  </CountdownUntilEndContainer>
);

export const CountdownUntilEndContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '20px 20px 20px 20px',
}));

// #endregion
