import compNavbar_FR from './compNavbar.json';
import pageHome_FR from './pageHome.json';
import pageEarningsHistory_FR from './pageEarningsHistory.json';
import pageSetting_FR from './pageSetting.json';
import accountPopover_FR from './accountPopover.json';
import languagePopover_FR from './languagePopover.json';
import groupLessonLevel_FR from './groupLessonLevel.json';

export default {
  compNavbar: compNavbar_FR,
  pageHome: pageHome_FR,
  pageEarningsHistory: pageEarningsHistory_FR,
  pageSetting: pageSetting_FR,
  accountPopover: accountPopover_FR,
  languagePopover: languagePopover_FR,
  groupLessonLevel: groupLessonLevel_FR
};
