import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  Alert, TextField, Button,
  Grid2, Link, Ty
} from '@languageconvo/wcl';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useMutation } from 'react-relay';
import { emailProps, emailValidationSchema } from '../../schema/emailChange-schema';
// Mutation...
import { ForgotPassword } from './relay/ForgotPassword';
import Page from '../../components/pagewrapper/Page';

const ForgotPWD = () => {
  const [ForgotPasswordMutation] = useMutation(ForgotPassword);
  // to managing routes
  const {
    control, handleSubmit, reset, setValue
  } = useForm <any>({
    resolver: yupResolver(emailValidationSchema)
  });
  const navigate = useNavigate();

  // starting: alert states
  const [alertText, setAlertText] = useState(
    `Enter your email address below, and we'll email you a link you 
     can use to reset your password.`
  );
  const [alertSeverity, setSlertSeverity] = useState<'info' | 'success' | 'error' | 'warning' | undefined>('info');
  // ending: alert states

  useEffect(() => {
    const sessionEmail = sessionStorage.getItem('email');
    if (sessionEmail) {
      setValue('email', sessionEmail);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePwdForgot = (data:emailProps) => {
    ForgotPasswordMutation({
      variables: { email: data.email },
      onCompleted() { /* In future, we can use this to make decisions... */ },
      onError(err: any) {
        Sentry.captureException(err);
      }
    });
    reset();
    setSlertSeverity('success');
    setAlertText(`You're all set, please check your email. You should receive an email with a 
      link to reset your password. If you don't within a few minutes, please try again, making 
      sure you spell your email address correctly.`);
  };

  return (
    <Page title="Forgot Password" dataTestId="page-updatepassword_pwdforgot">
      {/* default value of excludelogoheight is 120 in styled componnet but in some cases like
        here in pwdForgot page, content seems little low that's why instead of negative margin
        we can increase the height and move content a bit above. */}
      <Grid2 display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Grid2 xs={10}>
          <Grid2>
            <Alert severity={alertSeverity} cp={{ 'data-testid': 'alert-checkyouremail' }}>{ alertText }</Alert>
          </Grid2>
          {alertSeverity === 'info'
      && (
        <Grid2 marginTop="20px">
          <Controller
            name="email"
            control={control}
            render={
            ({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="forgot-email"
                inputCp={{ 'data-testid': 'textfield-email' }}
                type="email"
                label="Email Address"
                value={value}
                onChange={onChange}
                error={!!error}
                helpertext={error ? error.message : null}
              />
            )
          }
          />
          <Grid2 marginTop="10px">
            <Button dataTestId="button-requestreset" fullWidth onClick={handleSubmit(handlePwdForgot)}>
              Request Password Reset
            </Button>
          </Grid2>
        </Grid2>
      )}
          <Grid2 marginTop="25px">
            <Ty align="center">
              <Link cp={{ 'data-testid': 'link-backtologin' }} onClick={() => navigate('/login')}>Back to Log In</Link>
            </Ty>
          </Grid2>
        </Grid2>
      </Grid2>
    </Page>
  );
};

export default ForgotPWD;
