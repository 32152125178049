import compNavbar_EN from './compNavbar.json';
import pageHome_EN from './pageHome.json';
import pageEarningsHistory_EN from './pageEarningsHistory.json';
import pageSetting_EN from './pageSetting.json';
import languagePopover_EN from './languagePopover.json';
import groupLessonLevel_EN from './groupLessonLevel.json';
import ApptgroupClassroomTchEnterCode_En from './ApptgroupClassroomTchEnterCodes.json';
import ApptgroupClassroomTchEnterMessages_En from './ApptgroupClassroomTchEnterCodesMsg.json';

export default {
  compNavbar: compNavbar_EN,
  pageHome: pageHome_EN,
  pageEarningsHistory: pageEarningsHistory_EN,
  pageSetting: pageSetting_EN,
  languagePopover: languagePopover_EN,
  groupLessonLevel: groupLessonLevel_EN,
  teacherEnterCodes: ApptgroupClassroomTchEnterCode_En,
  teacherEnterMessages: ApptgroupClassroomTchEnterMessages_En
};
