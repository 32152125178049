/**
 * @generated SignedSource<<0b88eb1ab42b255ced2ee66dfc52599b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TchAuthQuickLoginMutation$variables = {
  uuid: string;
};
export type TchAuthQuickLoginMutation$data = {
  readonly tch_auth_quicklogin: {
    readonly data: {
      readonly jwt: string;
    };
    readonly success: boolean;
  } | null;
};
export type TchAuthQuickLoginMutation = {
  response: TchAuthQuickLoginMutation$data;
  variables: TchAuthQuickLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "concreteType": "TchAuthQuickloginOutput",
    "kind": "LinkedField",
    "name": "tch_auth_quicklogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TchAuthQuickloginOutputData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jwt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TchAuthQuickLoginMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TchAuthQuickLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "df329beef9eb7ff6d82b286f40fde352",
    "id": null,
    "metadata": {},
    "name": "TchAuthQuickLoginMutation",
    "operationKind": "mutation",
    "text": "mutation TchAuthQuickLoginMutation(\n  $uuid: String!\n) {\n  tch_auth_quicklogin(uuid: $uuid) {\n    success\n    data {\n      jwt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55f7fe5d4186b69b894ef845a5b88138";

export default node;
