import React from 'react';
import {
  CardContent, Grid2, Grid2Ct, Ty, Card, Select, TextField, Button, Checkbox, FormControlLabel,
  RadioGroup,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';

const options = [
  {
    name: 'One',
    value: 1,
  },
  {
    name: 'Two',
    value: 2,
  },
  {
    name: 'Three',
    value: 3,
  },
  {
    name: 'Four',
    value: 4,
  }
];
const optionsRadio = [
  { label: 'Payoneer', value: 'payoneer' },
  { label: 'Paypal', value: 'paypal' },
  { label: 'Wire Transfer', value: 'wireTransfer' },
];
// eslint-disable-next-line
const lorem='Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.';

const Form = () => {
  // dont allow access to this pagein prod, yet
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  return (
    <Grid2Ct>
      {/* back to ds */}
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Button to="/ds" component={NavLink} variant="outlined">Back To DS</Button>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - Standard */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - Standard
                </Ty>
              </Grid2>

              <Grid2Ct sx={{ pt: 3 }}>
                <Grid2 xs={12} lg={6}>
                  <Select label="Experience" options={options} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Age" options={options} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Country" options={options} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" label="Address" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} />
                </Grid2>
              </Grid2Ct>

              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button>Save Changes</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - Filled */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - Standard
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ pt: 3 }}>
                <Grid2 xs={12} lg={6}>
                  <Select label="Experience" options={options} value={1} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Age" options={options} value={2} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Country" options={options} value={3} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" label="Address" value="123 Some Road, Toronto, CA" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} value={lorem} />
                </Grid2>
              </Grid2Ct>

              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button>Save Changes</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - Standard - No Labels */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - Standard - No Labels
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ pt: 3 }}>
                <Grid2 xs={12} lg={6}>
                  <Select options={options} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select options={options} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select options={options} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" multiline multilineMaxRows={4} multilineMinRows={3} />
                </Grid2>
              </Grid2Ct>

              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button>Save Changes</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - Secondary */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - Secondary
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ pt: 4 }}>
                <Grid2 xs={12} lg={6}>
                  <Select label="Experience" options={options} value={1} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Age" options={options} value={2} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Country" options={options} value={3} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" label="Address" value="123 Some Road, Toronto, CA" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} />
                </Grid2>
              </Grid2Ct>

              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button color="secondary">Save Changes</Button>
                <br />
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      checked
                    />
                    )}
                  label="Remember Me"
                  sx={{ pt: 2 }}
                />
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* Button */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Button Sizes
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ mt: 4 }}>
                <Grid2 xs={12} lg={4}>
                  <TextField id="tf1" fullWidth />
                </Grid2>
                <Grid2 xs={12} lg={4}>
                  <Button size="large" fullWidth>Large Button</Button>
                </Grid2>
                <Grid2 xs={12}>
                  <Ty v="small">
                    Note: this is a size=large button with a *regular* sized TextField. We do not
                    have a large size text field, just the default (medium), and small.
                  </Ty>
                </Grid2>
              </Grid2Ct>

              <Grid2Ct xs={12} sx={{ mt: 4 }}>
                <Grid2 xs={12} lg={4}>
                  <TextField id="tf1" fullWidth />
                </Grid2>
                <Grid2 xs={12} lg={4}>
                  <Button fullWidth>Medium Button</Button>
                </Grid2>
              </Grid2Ct>

              <Grid2Ct xs={12} sx={{ mt: 4 }}>
                <Grid2 xs={12} lg={4}>
                  <TextField size="small" id="tf1" fullWidth />
                </Grid2>
                <Grid2 xs={12} lg={4}>
                  <Button size="small" fullWidth>Small Button</Button>
                </Grid2>
              </Grid2Ct>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* Match Sizes */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  Match Sizes
                </Ty>
                <Ty v="p">
                  This is a common design for small forms. If the select and button were different
                  heights, things would look strange. So we set the button to size large.
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ mt: 4 }}>
                <Grid2 xs={12} lg={6} lgOffset={3}>
                  <Ty v="subheading" align="center">
                    Select a Camera
                  </Ty>
                  <Select options={options} cp={{ sx: { mt: 2 } }} />
                  <Button size="large" fullWidth cp={{ sx: { mt: 1, mb: 3 } }}>Save Changes</Button>
                </Grid2>
              </Grid2Ct>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - Radio */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - Radio
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ pt: 3 }}>
                <Grid2 xs={12} lg={6}>
                  <Select label="Experience" options={options} value={1} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Age" options={options} value={2} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Country" options={options} value={3} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" label="Address" value="123 Some Road, Toronto, CA" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} value={lorem} />
                  <br />
                  <br />
                  <RadioGroup
                    groupLabel="Payment Options"
                    color="primary"
                    options={optionsRadio}
                    defaultValue={options[0]}
                  />
                </Grid2>
              </Grid2Ct>

              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button>Save Changes</Button>
                <br />
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked
                    />
                    )}
                  label="Remember Me"
                  sx={{ pt: 2 }}
                />
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - Small Sizes */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - Small Sizes
                </Ty>
              </Grid2>

              <Grid2Ct xs={12} sx={{ pt: 3 }}>
                <Grid2 xs={12} lg={6}>
                  <Select size="small" label="Experience" options={options} value={1} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select size="small" label="Age" options={options} value={2} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select size="small" label="Country" options={options} value={3} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField size="small" id="tf1" label="Address" value="123 Some Road, Toronto, CA" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField size="small" id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} value={lorem} />
                </Grid2>
              </Grid2Ct>

              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button size="small">Save Changes</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* About Me - With Sections */}
      <Grid2 xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty v="h2">
                  About Me - With Sections
                </Ty>
                <Ty v="p">
                  {lorem}
                </Ty>
              </Grid2>

              {/* Basic Information */}
              <Grid2Ct xs={12} sx={{ pt: 4 }}>
                <Grid2 xs={12}>
                  <Ty v="subheading">
                    Basic Information
                  </Ty>
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Experience" options={options} value={1} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Age" options={options} value={2} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Country" options={options} value={3} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" label="Address" value="123 Some Road, Toronto, CA" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} value={lorem} />
                </Grid2>
              </Grid2Ct>
              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button color="primary">Save Changes</Button>
              </Grid2>

              {/* Detailed Information */}
              <Grid2Ct xs={12} sx={{ pt: 4 }}>
                <Grid2 xs={12}>
                  <Ty v="subheading">
                    Detailed Information
                  </Ty>
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Professional Certification" options={options} value={1} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Highest Level Achieved" options={options} value={2} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <Select label="Current Status" options={options} value={3} />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <TextField id="tf1" label="Full Name" value="Lionel Messi" />
                </Grid2>
                <Grid2 xs={12}>
                  <TextField id="id-10" type="text" label="About Me" multiline multilineMaxRows={4} multilineMinRows={3} value={lorem} />
                </Grid2>
              </Grid2Ct>
              <Grid2 xs={12} sx={{ pt: 2 }}>
                <Button color="primary">Save Changes</Button>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

export default Form;
