import { graphql } from 'babel-plugin-relay/macro';

/* Use this query to get mic data from the relay store. */
export const MicAndCameraDetailsQuery = graphql`
  query MicAndCameraDetailsQuery {
    RelayAppSettings {
      ...MicDetailsFragment
      ...CameraDetailsFragment

    }
  }
`;
