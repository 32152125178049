import React, {
  createContext, useEffect, useRef, useState
} from 'react';
import OT, { Publisher, Session } from '@opentok/client';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import {
  PreviousRoomLayoutDataType, VonageProviderInitialStateType, VonageProviderProps, RoomViewTy,
  TeacherStreamNameTy,
} from './Types';
import {
  getAvailableCam, getAvailableMics, validateLocalData
} from './VonageProviderHelper';
import { getTchSettingsFromLCStorage, setTchSettingsToLCStorage } from '../../../../utils/lcStorage';
import { VonagePermissionMessages } from '../messages/VonagePermissions';
import { GroupLesson, LcTchSettings } from '../../../../utils/lcStorageInterface';
import {
  setCurrentMicMuteStateInRelayStore,
} from '../../../../common/relay/clientschema/relayappsettings/microphoneFunctionality';
import { micManager } from '../../../../common/microphone/micManager';
import { cameraManager } from '../../../../common/camera/cameraManager';
import {
  setAllAvailableCameraInRelayStore,
  setCurrentCameraInRelayStore,
  setCurrentCameraStateInRelayStore,
} from '../../../../common/relay/clientschema/relayappsettings/cameraFunctionality';
import { UpdateCameraIDInLocalStorage } from '../../../../common/camera/localStore/toggleCameraInLCStorage';
import {
  ErrorsInitPublisher,
  ErrorsConnectToSession,
  ErrorsSessionPublish
} from '../../../../common/utils/vonage/Errors';
import { useReportClassroomEvent } from '../hooks/useReportClassroomEvent';
import { ClassroomEvent } from '../utils/classRoomEvents';

const apiKey = process.env.REACT_APP_VONAGE_GROUP_KEY!;

// we have explain each item type in VonageProviderInitialStateType. please check.
const initialState: VonageProviderInitialStateType = {
  videoPublisher: undefined,
  demoPublish: () => { },
  accessGranted: false,
  vonageFirstInitialized: false,
  initializeSession: () => null,
  connectToSession: () => { },
  vonageSession: undefined,
  sessionConnected: false,
  disconnect: () => { },
  isTeacherScreensharing: false,
  publishVideoStream: () => { },
  stopScreenSharingStream: () => { },
  publishScreenSharingStream: () => { },
  subscribeToStream: () => { },
  disconnectFromSessionAndConnectToNewSession: () => { },
  muteMicrophone: () => { },
  unMuteMicrophone: () => { },
  turnCameraOff: () => { },
  turnCameraON: () => { },
};

// creating context which later used in hook and give us provider
const VonageContext = createContext(initialState);

/* VonageProvider contains communication api(OpenTok) which is being used
   in the whole group lesson module, no other part of application
   using this, that's why, we created its context so, it can be shared
   in the whole group lesson compoennts without drilling dwon the props.
*/
const VonageProvider = ({
  children,
  setmodalviewContents,
  setmodalviewState,
}: VonageProviderProps) => {
  // #region for demo publisher required on pageload to get permissions of devices.

  // to track if Vonage has been initialized for the first time
  const [vonageFirstInitialized, setVonageFirstInitialized] = useState<boolean>(false);

  // to track whether access of mic/camer has been granted or not
  const [accessGranted, setAccessGranted] = useState<boolean>(false);

  /** Here we "fake" publish so that the user is asked to accept cam/mic permissions.
   * If they deny access, we'll show them an error
   */
  const demoPublish = () => {
    const publisher = OT.initPublisher('demo-publish', {
      // to avoid unnecessary default UI that appears when a pubisher creates
      insertDefaultUI: false,
    }, handleDemoPublishError);

    // trigger, once we get permissions
    publisher.on('accessAllowed', () => {
      setAccessGranted(true);
      setVonageFirstInitialized(true);
      publisher.destroy();

      // after gettting permission, need to get all avaialble audio devices and set in relay store.
      // in the start relay store is empty
      initializeMicRelayValues();
      initializeCamRelayValues();
    });

    // if the teacher denies access to their cam/mic. we'll show them an error page
    publisher.on('accessDenied', () => {
      setAccessGranted(false);
      setVonageFirstInitialized(true);
      publisher.destroy();
    });
  };

  // we're basically ignoring errors during demo publish, so that the teacher can continue into
  // the classroom. *hopefully* whatever error occurred will not occur when they really publish
  function handleDemoPublishError(error: any) {
    if (error) {
      // eslint-disable-next-line no-console
      console.log('error in demoPublish: ', error);

      try {
        // set all these so that the teacher can continue to the next steps in loading the class
        setAccessGranted(true);
        setVonageFirstInitialized(true);
        initializeMicRelayValues();
        initializeCamRelayValues();

        // these are errors that shouldn't ever occur. we'll log them
        if (error.name === ErrorsInitPublisher.OT_INVALID_PARAMETER
          || error.name === ErrorsInitPublisher.OT_NO_VALID_CONSTRAINTS
          || error.name === ErrorsInitPublisher.OT_PROXY_URL_ALREADY_SET_ERROR
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_UNABLE_TO_CAPTURE_SCREEN
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED
        ) {
          Sentry.captureException(
            new Error('unexpected, but known, error in demoPublish'),
            {
              extra: {
                errorCode: error.code,
                errorName: error.name,
                errorMsg: error.message,
              }
            }
          );

          // if any of these errors occur, that's ok, they are expected sometimes (like when user
          // loses internet connection during demoPublish process)
        } else if (error.name === ErrorsInitPublisher.OT_MEDIA_ENDED
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_ABORTED
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_DECODE
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_NETWORK
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_SRC_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_HARDWARE_UNAVAILABLE
          || error.name === ErrorsInitPublisher.OT_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_NO_DEVICES_FOUND
          || error.name === ErrorsInitPublisher.OT_REQUESTED_DEVICE_PERMISSION_DENIED
        ) {
          // do nothing

          // else the error was unknown, we should definitely log so we know this is occurring
        } else {
          Sentry.captureException(
            new Error('completely unexpected error in demoPublish'),
            {
              extra: {
                err: error,
              }
            }
          );
        }
      } catch (e) {
        // some error within this function itself. here we do not notify the user bc we may have
        // already notified them above. this should be a very rare occurrence
        Sentry.captureException(
          e,
          {
            extra: {
              note: 'An unexpected error occurred in handleDemoPublishError',
            }
          }
        );
      }
    }
  }

  /** initialize the mic values in relay store
   * why we created this function:
   *  this function will gets called from the demoPublish, the reason for creating this
   *  function is that, in the page load when the accessAllowed event triggers we need to set the
   *  initial values in the relay store because on page load relay store becomes empty, so we need
   *  to re-initialize the relay store.
  */
  const initializeMicRelayValues = () => {
    getAvailableMics().then((devices: any) => {
      // here we are passing devices = `allAvailableAudioDevices` in micManager to perform
      // calculations (i-e update mic in relay store & localStore)
      micManager(devices);

      // getting localStorage to get the previous/save setting of the user. either his 
      // mic is mute/unmute we need to maintain the mic state during page load/reloaod
      // as relay store get empty on page load/reload
      const localData: LcTchSettings = getTchSettingsFromLCStorage();

      // validating the groupLesson object type via ZOD.
      const validGroupLessonData: GroupLesson = validateLocalData(localData.groupLesson);

      // setting the currentMicState in relay store in the start relay store will be empty so 
      // we need to get isMute state from local storage.
      setCurrentMicMuteStateInRelayStore(validGroupLessonData.isMute);

      // log and update our relay store if an error getting devices occurred. note that we do
      // want to update current mic id and all avialable mics in relay store, so we can use
      // micManager
    }).catch((e: any) => {
      Sentry.captureException(e);
      micManager([]);
    });
  };

  /** initialize the camera values in relay store
   * why we created that function:
   *  this function will gets called from the demoPublish, the reason for creating this
   *  function is that, in the page load when the accessAllowed event triggers we need to set the
   *  initial values in the relay store because on page load relay store becomes empty, so we need
   *  to re-initialize the relay store.
  */
  const initializeCamRelayValues = () => {
    getAvailableCam().then((devices: any) => {
      // here we are passing devices = `allVideoDevices` in cameraManager to perform
      // calculations (i-e update camera in relay store OR localStore)
      cameraManager(devices);

      // getting localStorage to get the previous/save setting of the user. either his 
      // camera is on/off we need to maintain the camera state during page load/reloaod
      // as relay store get empty on page load/reload
      const localData: LcTchSettings = getTchSettingsFromLCStorage();

      // validating the groupLesson object type via ZOD.
      const validGroupLessonData: GroupLesson = validateLocalData(localData.groupLesson);

      // setting the currentCameraState in relay store in the start relay store will be empty so 
      // we need to get isCameraOff state from localStorage. so we update the relay 
      // store accordingly.
      // here we are updating the current camera state in relay store.
      setCurrentCameraStateInRelayStore(validGroupLessonData.isCameraOff);
    }).catch((e: any) => {
      Sentry.captureException(e);

      // if we call cameraManager here our localStorage will also update to null cameraId what
      //  if user has any cameraId present in LocalStorage and forget to attached
      setAllAvailableCameraInRelayStore([]);
    });
  };

  // #endregion

  // #region for initializeSession and connectToSession

  // state to hold the Vonage session object
  // after initializing the session we store the session object in this state because
  // this session object provide us different methods for start publishing or subscribing 
  // in the current room 
  const [vonageSession, setVonageSession] = useState<Session>();

  // to get the updated state of vonageSessionRef we are using useRef hook.
  // in the callback/EventListener method we don't get the updated state values, so we have to 
  // store the state in a reference to always get the updated state, We are using this ref in 
  // 2 places.
  // 1- initializeSession (when session is created we store the session in this refrence)
  // 2- during unmounting when we had to turn the session off and disconnect the user from session. 
  const vonageSessionRef = useRef<Session>();

  // state to track if the session is connected or not
  // this state is important because we had a check in our VonageSessionManager, where we 
  // check if session is connected only then we start publishing or subscribing to stream
  const [sessionConnected, setSessionConnected] = useState<boolean>(false);

  // To save the previous state of roomLayout data roomView and teacherStreamingTo 
  // It is required for comparison  while connecting to new session,
  // this state is importan because we need to track either there is a change occured in
  //  the previous layout value or not on the basis of this change we need to decide which session 
  // teacher needs to be connect (-i.e from teacher only mode to group chat mode)
  const [previousRoomLayoutData, setPreviousRoomLayoutData] = useState<
    PreviousRoomLayoutDataType
  >();

  // initializes vonage session if it does not already exists, using providd token, session ID
  // and API key.
  // this is the first step before connecting to the session, we have to initlize the session
  // OT.initSession return us the session object which we will use connect to session and 
  // for publishing and subscribing to during the session.
  const initializeSession = (sessionId: string) => {
    // OT.initSession not initiate communications with the cloud. It simply initializes 
    // the Session object that you can use to connect (and to perform other operations 
    // once connected). that's why it does not have any error state.
    const session = OT.initSession(apiKey, sessionId);

    // eslint-disable-next-line no-console
    console.log('OT.initSession successful');

    // It is important to set vonage session in state, once, it is porperly set
    // in the vonageSessionManager component via useEffect hook, we are connecting
    // with this new session.
    setVonageSession(session);

    // storing the session in refrence because in the callback/EventListener 
    // method we don't get the updated state values, so we have to 
    // store the state in a reference to always get the updated state,
    vonageSessionRef.current = session;
  };

  // getting the uuid of the lesson from param will be used in reportClassroomEvent
  const [searchParams] = useSearchParams();
  const lsnUuid = searchParams.get('uuid');
  /**
   * there are multiple places from where we are reporting classroom related events, so
   * instead of duplicating same code, we created a hook, which is reporting classroom related 
   * events from single place and exposing reportClassroomEvent.
   */
  const { reportClassroomEvent } = useReportClassroomEvent();

  // connect to the VonageSession using the provided token
  // this is the step 2, here we have to connect the user to session using the session
  // object returned by (OT.initSession). this is important because without connecting to session 
  // user can neither publish to the session nor subscribe to any stream in the session
  const connectToSession = (token: string) => {
    if (vonageSession) {
      vonageSession.connect(token, (error: any) => {
        if (!error) {
          // eslint-disable-next-line no-console
          console.log('session.connect start');
          setSessionConnected(true);
        } else {
          handleSessionConnectionError(error);
        }
      });

      vonageSession.on('sessionConnected', (event) => {
        // eslint-disable-next-line no-console
        console.log('session.connect sessionConnected event successful');

        // every time teacher is connected to session we are recording this event to our BE
        if (lsnUuid) {
          reportClassroomEvent(lsnUuid, ClassroomEvent.VonageConnected);
        } else {
          // eslint-disable-next-line no-console
          console.log('Failed to get lesson uuid in session connected evnt');
          Sentry.captureException(
            new Error('PROBLEM: lsnUuid did not exist when attempting reportClassroomEvent')
          );
        }

        // once connected, subscribing to all other available streams of other users. should
        // only run if the teacher is in a group classroom
        // @ts-ignore
        event.target.streams.forEach((stream: any) => {
          // eslint-disable-next-line no-console
          console.log('sessionConnected, subscribing to existing stream of this user.............', stream.name);

          vonageSession.subscribe(stream, undefined, {
            insertDefaultUI: false,
          });
        });
      });
    }
  };

  // handles errors for vonageSession.connect. we'll display a message to the user
  // and potentially log to sentry if it is unexpected
  function handleSessionConnectionError(error: any) {
    try {
      // if any of these occur, notify the user their internet might be down
      if (error.name === ErrorsConnectToSession.OT_CONNECT_FAILED
        || error.name === ErrorsConnectToSession.OT_NOT_CONNECTED
      ) {
        setmodalviewContents(920);
        setmodalviewState(true);

        // if this occurs, notify user they need a different browser
      } else if (error.name === ErrorsConnectToSession.OT_UNSUPPORTED_BROWSER) {
        setmodalviewContents(921);
        setmodalviewState(true);

        // these errors should not occur, and would likely indicate a problem in our 
        // code if they do. log to sentry and display general error
      } else if (error.name === ErrorsConnectToSession.OT_AUTHENTICATION_ERROR
        || error.name === ErrorsConnectToSession.OT_BADLY_FORMED_RESPONSE
        || error.name === ErrorsConnectToSession.OT_CONNECTION_LIMIT_EXCEEDED
        || error.name === ErrorsConnectToSession.OT_EMPTY_RESPONSE_BODY
        || error.name === ErrorsConnectToSession.OT_INVALID_SESSION_ID
        || error.name === ErrorsConnectToSession.OT_INVALID_PARAMETER
        || error.name === ErrorsConnectToSession.OT_TERMS_OF_SERVICE_FAILURE
        || error.name === ErrorsConnectToSession.OT_INVALID_HTTP_STATUS
        || error.name === ErrorsConnectToSession.OT_XDOMAIN_OR_PARSING_ERROR
        || error.name === ErrorsConnectToSession.OT_INVALID_ENCRYPTION_SECRET
      ) {
        Sentry.captureException(
          new Error('unexpected, but known, error in vonageSession.connect'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );

        // displays a general error
        setmodalviewContents(900);
        setmodalviewState(true);

        // error.name was not known
      } else {
        Sentry.captureException(
          new Error('an unknown error in vonageSession.connect'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );

        // displays a general error
        setmodalviewContents(900);
        setmodalviewState(true);
      }

      // an error occurred within this function. should be very rare
    } catch (e) {
      Sentry.captureException(
        e,
        {
          extra: {
            note: 'An unexpected error occurred in handleSessionConnectionError',
          }
        }
      );
    }

    // log to the console for detailed debugging if a student is having an issue in prod
    // eslint-disable-next-line no-console
    console.log('vonageSession.connect error occurred. see handleSessionConnectionError');
    // eslint-disable-next-line no-console
    console.log(error);
  }

  // #endregion

  // #region for publishVideoStream and subscribeToStream

  /* responsible for publishing teacher's video using vonage platform.
     we are initlizing publisher with custom options and error handling.
     setting up audio and video devices from relay store
     publishes the stream to the Vonage session if connected
     we are calling this function when session connected becomes true 
     because of this teacher is able to publish his audio/video stream to students
  */
  async function publishVideoStream(
    micInfo: any,
    cameraInfo: any,
    roomView: RoomViewTy,
  ) {
    // the name we give to the stream, based on whether we're publishing to lecture or to
    // group chat. uis will listen for these stream names to display video correctly
    let streamName = TeacherStreamNameTy.TeacherStreamLecture;
    if (roomView === RoomViewTy.GroupChat) {
      streamName = TeacherStreamNameTy.TeacherStreamGroup;
    }

    // it's very possible that the teacher has unplugged the microphone that we have stored
    // in our relay value. if that's the case, if we try to publish with it we'll get an error.
    // so here we check to ensure that mic is still available and if it is NOT available we'll
    // set any available mic to the relay store so that it will be used. we also return that mic
    // id for use here; the relay store takes some time to update, so we don't want to rely on
    // using it immediately. also note we are ignorning errors that might occur in getAvailableMics
    // bc again, if theMic is not available, initPublisher will fail and show the teacher an error
    const theMicId = await checkIfMicAvailableReturnAvailableMicId(micInfo.current.micId);

    // it's very possible that the teacher has unplugged the camera that we have stored
    // in our relay value. if that's the case, if we try to publish with it we'll get an error.
    // so here we check to ensure that camera is still available and if it is NOT available we'll
    // set any available camera to the relay store so that it will be used. we also return that cam
    // id for use here; the relay store takes some time to update, so we don't want to rely on
    // using it immediately. also note we are ignorning errors that might occur in getAvailableCam
    // bc again, if theCam is not available, initPublisher will fail and show the teacher an error
    const theCameraId = await checkIfCamAvailableReturnAvailableCamId(cameraInfo.current.camId);

    // init and publish
    // https://tokbox.com/developer/sdks/js/reference/OT.html#initPublisher
    const publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      publishAudio: !micInfo.current.isMuted,
      height: '100%',
      width: 'inherit',
      mirror: true,
      fitMode: 'contain',
      publishVideo: !cameraInfo.current.isCameraOff,
      showControls: false,
      name: streamName,
      audioSource: theMicId,
      videoSource: theCameraId,
      resolution: '1280x720',
      audioFilter: {
        type: 'advancedNoiseSuppression',
      },
    }, handleInitPublisherError);

    // eslint-disable-next-line no-console
    console.log('OT.initPublisher successful');

    // storing video publisher in state later we will use to update the publisher audio/video
    // source mute/unmute the publisher
    setVideoPublisher(publisher);

    // storing video publisher in refrence to always get the updated state in callback eventListener
    videoPublisherRef.current = publisher;

    // starts stream publish
    if (vonageSession) {
      vonageSession.publish(publisher, (err: any) => {
        if (err) {
          handlePublishStreamError(err);
        } else {
          // do nothing, user successfully published
          // eslint-disable-next-line no-console
          console.log('vonageSession.publish successful');
        }
      });
    }

    // this event listener trigger when any Publisher has stopped sharing 
    // one or all media types (video, audio, or screen). note that this only triggers when the
    // user unplugs the mic or camera they are currently using to stream to the session; it
    // does not trigger when a device the user is not using, is unplugged
    publisher.on('mediaStopped', (event: any) => {
      // if the user unplugged their microphone
      if (event.track && event.track.readyState === 'ended' && event.track.kind === 'audio') {
        // eslint-disable-next-line no-console
        console.log('the users mic was unplugged!');

        // get attached mics, and attempt to use one
        getAvailableMics().then((currentDevices: any) => {
          // if there is at least one mic, set that in the relay store. note this will automatically
          // trigger the vonage session to use that mic (this occurs in VonageSessionsManager.tsx)
          if (currentDevices.length) {
            micManager(currentDevices);

            // notify the user about what we did
            toast.warn('Your mic was unplugged, so we switched you to a different mic!');

            // the teacher has no audio devices now, so students cannot hear them. we tell
            // the teacher of this situation and what they need to do to fix it
          } else {
            micManager([]);
            setmodalviewContents(901);
            setmodalviewState(true);
          }

          // an error occurred getting mics; notify the user
        }).catch((e: any) => {
          Sentry.captureException(e);
          // note: we do NOT call micManager here, the reason being that the error which occurred
          // might not have had anything to do with the teacher's microphone. we dont want to
          // switch the mic in the relay store to null bc they'd stop publishing audio
          setmodalviewContents(901);
          setmodalviewState(true);
        });
      }

      // if the user unplugged their camera
      if (event.track && event.track.readyState === 'ended' && event.track.kind === 'video') {
        // eslint-disable-next-line no-console
        console.log('the users camera was unplugged!');

        // fetching currently attached video devies after the current video device has been 
        // unplugged, here we are updating our relay store all obj, so can show currently attached 
        // video devices in camera modal where user can select any available video device
        getAvailableCam().then((currentDevies: any) => {
          // updating relay store all obj so we can show the user's currently attached/
          // available devices in dropdown
          if (currentDevies.length) {
            setAllAvailableCameraInRelayStore(currentDevies);

            // the teacher has no video devices now, so students cannot watch them. we tell
            // the teacher of this situation and what they need to do to fix it
          } else {
            setAllAvailableCameraInRelayStore([]);
            setmodalviewContents(902);
            setmodalviewState(true);
          }

          // notify the user what we did
          toast.warn('Your camera was unplugged. To protect your privacy, we have turned it off.');

          // updating camera state in relay store and keep it off 
          setCurrentCameraStateInRelayStore(true);

          // setting current cameraId and cameraName as null in relay store
          // so teacher's video remains off
          setCurrentCameraInRelayStore(null, null);

          // updating camera state in localStorage to keep camera off on page reload.
          // because on page reload realy store will be empty.
          updateCameraStateInLCStorage(true);
          UpdateCameraIDInLocalStorage(null);
        }).catch((e: any) => {
          Sentry.captureException(e);
          setAllAvailableCameraInRelayStore([]);
          setmodalviewContents(902);
          setmodalviewState(true);
        });
      }
    });
  }

  /** given a micId, checks to see if it is currently available on the teacher's computer. if it
   * is not, gets a new mic id that can be used and returns that (it calls our micManager function
   * which updates the relay store and localstorage too)
   */
  function checkIfMicAvailableReturnAvailableMicId(
    theMicId: string,
  ): Promise<string> {
    return new Promise((resolve) => {
      let theMicIsAvailable = false;
      let avaialbleMicId = theMicId;

      getAvailableMics()
        .then((currentDevices: any) => {
          // if there are any available devices, we'll check to see if the mic id given is
          // in the available list. if there aren't any avialable mics we'll just return
          // the id that was passed into this function
          if (currentDevices.length) {
            currentDevices.forEach((el: any) => {
              if (el.deviceId === theMicId) {
                theMicIsAvailable = true;
              }
            });

            // if we did not find the mic, then micManager will pick a new mic from the list
            // of available mics and set it to the relay store
            if (!theMicIsAvailable) {
              const newMicDt = micManager(currentDevices);
              avaialbleMicId = newMicDt.theMicId;
              // eslint-disable-next-line no-console
              console.log('the mic the teacher had been using is no longer available, we are setting a new one. they had been using:', theMicId);
              // eslint-disable-next-line no-console
              console.log('the new mic id is: ', avaialbleMicId);
            }
          }

          // return the mic id
          resolve(avaialbleMicId);

          // in the rare case that an error occurs, we just return the mic id that was passed into
          // this function. that may cause other code to break, but for now it's easy to get done
        }).catch((e) => {
          Sentry.captureException(e);
          resolve(avaialbleMicId);
        });
    });
  }

  /** given a cameraId, checks to see if it is currently available on the teacher's computer. if it
   * is not, gets a new camera id that can be used and returns that (it calls our cameraManager 
   * function which updates the relay store and localstorage too)
   */
  function checkIfCamAvailableReturnAvailableCamId(
    theCamId: string,
  ): Promise<string> {
    return new Promise((resolve) => {
      let theCamIsAvailable = false;
      let avaialbleCamId = theCamId;

      getAvailableCam()
        .then((currentDevices: any) => {
          // if there are any available devices, we'll check to see if the camera id given is
          // in the available list. if there aren't any avialable camera we'll just return
          // the id that was passed into this function
          if (currentDevices.length) {
            currentDevices.forEach((el: any) => {
              if (el.deviceId === theCamId) {
                theCamIsAvailable = true;
              }
            });

            // if we did not find the camera, then cameraManager will pick a new camera from the 
            // list of available camera and set it to the relay store
            if (!theCamIsAvailable) {
              const newCameraDt = cameraManager(currentDevices);
              avaialbleCamId = newCameraDt.theCamId;
              // eslint-disable-next-line no-console
              console.log('the camera the teacher had been using is no longer available, we are setting a new one. they had been using:', theCamId);
              // eslint-disable-next-line no-console
              console.log('the new camera id is: ', avaialbleCamId);
            }
          }

          // return the camera id
          resolve(avaialbleCamId);

          // in the rare case that an error occurs, we just return the camrera id that was passed
          //  into this function. that may cause other code to break, but for now it's easy 
          // to get done
        }).catch((e) => {
          Sentry.captureException(e);
          resolve(avaialbleCamId);
        });
    });
  }

  // this will execute if any error occurs during OT.initPublisher
  function handleInitPublisherError(error: any) {
    // for some reason, OT always calls this handleInitPublisherError even if there is not an error.
    // to determine if an error actually occurred, need this if statement
    if (error) {
      try {
        // if any of these specific errors occur, we need to notify the user that their mic might
        // not be working correctly
        if (error.name === ErrorsInitPublisher.OT_HARDWARE_UNAVAILABLE
          || error.name === ErrorsInitPublisher.OT_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_NO_DEVICES_FOUND
          || error.name === ErrorsInitPublisher.OT_REQUESTED_DEVICE_PERMISSION_DENIED
        ) {
          setmodalviewContents(940);
          setmodalviewState(true);

          // if any of these errors occur, we need to log to sentry, they should never occur 
          // and would indicate a problem with our code. display general error
        } else if (error.name === ErrorsInitPublisher.OT_INVALID_PARAMETER
          || error.name === ErrorsInitPublisher.OT_NO_VALID_CONSTRAINTS
          || error.name === ErrorsInitPublisher.OT_PROXY_URL_ALREADY_SET_ERROR
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_UNABLE_TO_CAPTURE_SCREEN
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED
        ) {
          setmodalviewContents(941);
          setmodalviewState(true);
          Sentry.captureException(
            new Error('unexpected, but known, error in handleInitPublisherError'),
            {
              extra: {
                errorCode: error.code,
                errorName: error.name,
                errorMsg: error.message,
              }
            }
          );

          // if any of these errors occur, that's ok, they are expected sometimes (like when user
          // loses internet connection during publish process). notify them with a general error
        } else if (error.name === ErrorsInitPublisher.OT_MEDIA_ENDED
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_ABORTED
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_DECODE
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_NETWORK
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_SRC_NOT_SUPPORTED
        ) {
          setmodalviewContents(941);
          setmodalviewState(true);

          // else the error was unknown, we should definitely log so we know this is occurring and
          // display general error
        } else {
          setmodalviewContents(941);
          setmodalviewState(true);
          Sentry.captureException(
            new Error('completely unexpected error in handleInitPublisherError'),
            {
              extra: {
                err: error,
              }
            }
          );
        }
      } catch (e) {
        // some error within this function itself. here we do not notify the user bc we may have
        // already notified them above. this should be a very rare occurrence
        Sentry.captureException(
          e,
          {
            extra: {
              note: 'An unexpected error occurred in handleInitPublisherError',
            }
          }
        );
      }

      // log to the console for detailed debugging if a student is having an issue in prod
      // eslint-disable-next-line no-console
      console.log('OT.initPublisher error occurred:');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  // this will execute if any error occured during vonageSession.publish
  function handlePublishStreamError(error: any) {
    try {
      // errors that might mean the user's mic is not available, maybe it's being used
      // by another program or it was unplugged. we'll notify the user
      if (error.name === ErrorsSessionPublish.OT_HARDWARE_UNAVAILABLE
        || error.name === ErrorsSessionPublish.OT_NO_DEVICES_FOUND
        || error.name === ErrorsSessionPublish.OT_USER_MEDIA_ACCESS_DENIED
        || error.name === ErrorsSessionPublish.OT_CHROME_MICROPHONE_ACQUISITION_ERROR
      ) {
        setmodalviewContents(930);
        setmodalviewState(true);

        // errors that might mean the user's internet connection went down. notify them
      } else if (error.name === ErrorsSessionPublish.OT_MEDIA_ERR_NETWORK
        || error.name === ErrorsSessionPublish.OT_NOT_CONNECTED
      ) {
        setmodalviewContents(931);
        setmodalviewState(true);

        // errors that are expected sometimes. we dont log to sentry, but we do show
        // the user a message that something might have gone wrong
      } else if (error.name === ErrorsSessionPublish.OT_CREATE_PEER_CONNECTION_FAILED
        || error.name === ErrorsSessionPublish.OT_ICE_WORKFLOW_FAILED
        || error.name === ErrorsSessionPublish.OT_INVALID_AUDIO_OUTPUT_SOURCE
        || error.name === ErrorsSessionPublish.OT_MEDIA_ERR_ABORTED
        || error.name === ErrorsSessionPublish.OT_TIMEOUT
        || error.name === ErrorsSessionPublish.OT_UNABLE_TO_CAPTURE_MEDIA
      ) {
        setmodalviewContents(932);
        setmodalviewState(true);

        // errors we do not expect and likely indicate a problem with our code. log to sentry
      } else if (error.name === ErrorsSessionPublish.OT_CONSTRAINTS_NOT_SATISFIED
        || error.name === ErrorsSessionPublish.OT_INVALID_PARAMETER
        || error.name === ErrorsSessionPublish.OT_MEDIA_ERR_DECODE
        || error.name === ErrorsSessionPublish.OT_MEDIA_ERR_SRC_NOT_SUPPORTED
        || error.name === ErrorsSessionPublish.OT_NO_VALID_CONSTRAINTS
        || error.name === ErrorsSessionPublish.OT_NOT_SUPPORTED
        || error.name === ErrorsSessionPublish.OT_PERMISSION_DENIED
        || error.name === ErrorsSessionPublish.OT_SCREEN_SHARING_NOT_SUPPORTED
        || error.name === ErrorsSessionPublish.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED
        || error.name === ErrorsSessionPublish.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED
        || error.name === ErrorsSessionPublish.OT_SET_REMOTE_DESCRIPTION_FAILED
        || error.name === ErrorsSessionPublish.OT_STREAM_CREATE_FAILED
        || error.name === ErrorsSessionPublish.OT_UNEXPECTED_SERVER_RESPONSE
      ) {
        setmodalviewContents(932);
        setmodalviewState(true);
        Sentry.captureException(
          new Error('unexpected, but known, error in vonageSession.publish'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );

        // unknown error.name
      } else {
        setmodalviewContents(932);
        setmodalviewState(true);
        Sentry.captureException(
          new Error('an unknown error in vonageSession.publish'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );
      }

      // some error within this function itself. here we do not notify the user bc we may have
      // already notified them above. this should be a very rare occurrence
    } catch (e) {
      Sentry.captureException(
        e,
        {
          extra: {
            note: 'An unexpected error occurred in handlePublishStreamError',
          }
        }
      );
    }

    // log to the console for detailed debugging if a student is having an issue in prod
    // eslint-disable-next-line no-console
    console.log('vonageSession.publish error occurred:');
    // eslint-disable-next-line no-console
    console.log(error);
  }

  /* subscribe to streams -- occurs during group chat
    If the session is connected, it listens for the 'streamCreated' event,
    and when triggered, it subscribes to the stream with custom options
    (in this case, disabling the default UI insertion).
    we are calling this function when roomView ===2 Group chat mode 
    so, teacher can listent to the student in group chat mode
  */
  const subscribeToStream = () => {
    if (sessionConnected) {
      vonageSession!.on('streamCreated', (event: any) => {
        vonageSession!.subscribe(event.stream, undefined, {
          insertDefaultUI: false,
        });
      });
    }
  };

  // #endregion

  // #region to disconnect form vonage (i-e on lesson end)

  /*
    function `disconnect` Disconnects from the Vonage session.
    If the session is connected, it registers an event listener for
    successful disconnection. Upon disconnection, it checks the reason
    for disconnection and handles it accordingly. Then, it resets
    session-related state variables and disconnects from the session.
   */

  // state to hold the video publisher
  const [videoPublisher, setVideoPublisher] = useState<Publisher>();

  // to get the updated state of videoPublisher we are using useRef hook.
  const videoPublisherRef = useRef<Publisher>();

  // state to track if the teacher is currently screen sharing
  // this state will tell us either we need to show the screen sharing UI or simple UI
  // we make this state true once teacher start screen sharing stream
  const [isTeacherScreensharing, setIsTeacherScreensharing] = useState<boolean>(false);

  // whenever we have to disconnect the user form vonage session we are calling our disconnect 
  // function this function will do the following things
  // 1- disconnect from vonage session
  // 2- destory the video publisher
  // 3- once session is disconnected, reset all the states to the initilas values
  // we called this function in `LessonTimeCalculation` when lesson ended we are calling 
  // this function
  const disconnect = () => {
    vonageSession!.disconnect();
    videoPublisher!.destroy();
    if (sessionConnected && vonageSession) {
      // register an even listener which executes after successful disconnection.
      vonageSession.on('sessionDisconnected', (event: any) => {
        // TODO: why are we doing this? we disconnected the user on purpose, what's the point of
        // logging errors here?
        // event:
        // 1: client disconect
        // 2: force disconnect
        // 3: network disconnect
        if (event.reason === 'forceDisconnected' || event.reason === 'networkDisconnected') {
          Sentry.captureException(event.reason);
        }
        setVonageSession(undefined);
        setSessionConnected(false);
        setVideoPublisher(undefined);
        setIsTeacherScreensharing(false);
      });
    }
  };

  // #endregion

  // #region for disconnectFromOldSessionAndConnectToNewSession on layout data changes

  // disconnect old session and reconnect new session if sessionCredentials are available.
  // we are properly disconnecting teacher from previous session so teacher is connected with 
  // new session, we are doing this so two session don't overlap
  const disconnectFromSessionAndConnectToNewSession = (sessionCredentials: {
    sessionId: string,
    token: string,
  }) => {
    videoPublisher!.destroy();

    // this fires first and disconnect session.
    vonageSession!.disconnect();

    // register an even listener which executes after successful disconnection.
    vonageSession!.on('sessionDisconnected', (event: any) => {
      // event:
      // 1: client disconect
      // 2: force disconnect
      // 3: network disconnect
      if (event.reason === 'forceDisconnected' || event.reason === 'networkDisconnected') {
        Sentry.captureException(event.reason);
      }
      vonageSession!.off();
      setVonageSession(undefined);
      setSessionConnected(false);
      setVideoPublisher(undefined);
      setIsTeacherScreensharing(false);

      // in case of group chat if teacher select's speaking to nobody, we'll not initialize session.
      if (sessionCredentials !== null) {
        // make condition true to show popUp
        initializeSession(sessionCredentials.sessionId);
      }
    });
  };
  // #endregion

  // #region for handle screen sharing

  // state to hold the screen sharing publisher
  const [screenSharingPublisher, setScreenSharingPublisher] = useState<Publisher>();

  // `stopScreenSharingStream` is responsible for stop the screen sharing
  //  stream by destroying the screen sharing publisher,
  // resetting related states, and updating the teacher's screen sharing status
  const stopScreenSharingStream = () => {
    if (screenSharingPublisher) {
      screenSharingPublisher.destroy();
      setScreenSharingPublisher(undefined);
      setIsTeacherScreensharing(false);
    }
  };

  // `publishScreenSharingStream` is responsible for initializing a screen sharing publisher
  // with appropriate configurations, checking browser support, handling access permissions,
  // and updating the teacher's screen sharing status
  const publishScreenSharingStream = () => {
    // checks for support for publishing screen-sharing streams on the client browser.
    // if not allowed we are showing a toast message to notify the teacher
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported || response.extensionRegistered === false) {
        toast.warn(VonagePermissionMessages.ScreenSharingNotSupported);

        // if session is connected and vonageSession is defined we are allowing teacher to 
        // publish their screen sharing stream
      } else if (sessionConnected && vonageSession) {
        // first, we create a publisher object; this will show the teacher the browser prompt
        // to share their screen
        const publisher = OT.initPublisher('screen-sharing', {
          // the name of the stream. teachers can only screen share during lecture mode, thus we use
          // that as the name of the stream
          name: TeacherStreamNameTy.TeacherStreamLecture,
          insertMode: 'append',
          videoSource: 'screen',
          mirror: false,
          height: '100%',
          width: 'inherit',
          resolution: '1920x1080',
          publishAudio: false,
          fitMode: 'contain',
          audioFilter: {
            type: 'advancedNoiseSuppression',
          },
        });
        setScreenSharingPublisher(publisher);

        // now we wait -- the teacher could accept screen sharing, or close the dialog and
        // decide not to share their screen. here we're listening for the outcome of what
        // they choose to do
        publisher.on('accessDenied', () => {
          setIsTeacherScreensharing(false);
          setScreenSharingPublisher(undefined);
        });

        // if the teacher allows screen sharing, publish the stream to the Vonage session.
        // if the publishing fails, we capture the error.
        publisher.on('accessAllowed', () => {
          vonageSession.publish(publisher, (err: any) => {
            if (!err) {
              setIsTeacherScreensharing(true);
            } else {
              Sentry.captureException(err);
            }
          });
        });

        // listen for the event where the teacher stops sharing their screen.
        // whne this occurs, we update the state to reflect that screen sharing has stopped 
        // and reset them to their default values
        publisher.on('mediaStopped', () => {
          setIsTeacherScreensharing(false);
          setScreenSharingPublisher(undefined);
        });

        // if there is no vonageSession and teacher is not connected with any session 
        // we are not allowing teacher to publisher their screen sharing stream
      } else {
        const toastId = 'screen-share-error';

        // prevent rendering multiple toast notifications for the same event.
        // check ensures that if a toast with the same `toastId` is already active on the UI, 
        // a new one will not be rendered. This prevents multiple notifications from appearing 
        // when the teacher repeatedly clicks the screen sharing button, which would otherwise 
        // create a cluttered and less visually appealing user experience.
        if (!toast.isActive(toastId)) {
          toast.warn(VonagePermissionMessages.NoSessionForScreenSharing, { toastId });
        }
      }
    });
  };

  // #endregion

  // #region for toggle microphone and camera

  // function to mute the microphone by stoping audio stream of the video publisher
  // we will use this function in the mic dialog from where teacher can mute themself.
  const muteMicrophone = () => {
    try {
      if (videoPublisher) {
        videoPublisher.publishAudio(false);

        // updating relay store to get the updated micState,as we are using this state in 
        // micModal to show teacher is mute/unmute
        setCurrentMicMuteStateInRelayStore(true);

        // Update localStorage to preserve micState on page reload,
        // since the relay store is cleared on reload.
        updateMicStateInLCStorage(true);
      } else {
        // updating relay store to get the updated micState,as we are using this state in 
        // micModal to show teacher is mute/unmute
        setCurrentMicMuteStateInRelayStore(true);

        // maintaing the mic state in localStorage so we can get the correct state when teacher 
        // reload's the page as relay store becomes empty on page load/relaod
        updateMicStateInLCStorage(true);
      }
    } catch (e: any) {
      toast.warn(VonagePermissionMessages.FailedToMute);
      Sentry.captureException(e);
    }
  };

  // function to unmute the microphone by starting audio stream of the video publisher
  // we will use this function in the mic dialog from where teacher can unmute themself.
  const unMuteMicrophone = () => {
    try {
      if (videoPublisher) {
        videoPublisher.publishAudio(true);

        // updating relay store to get the updated micState,as we are using this state in 
        // micModal to show teacher is mute/unmute
        setCurrentMicMuteStateInRelayStore(false);

        // Update localStorage to preserve micState on page reload,
        // since the relay store is cleared on reload.
        updateMicStateInLCStorage(false);
      } else {
        // updating relay store to get the updated micState,as we are using this state in 
        // micModal to show teacher is mute/unmute
        setCurrentMicMuteStateInRelayStore(false);

        // maintaing the mic state in localStorage so we can get the correct state when teacher 
        // reload's the page as relay store becomes empty on page load/relaod
        updateMicStateInLCStorage(false);
      }
    } catch (e: any) {
      toast.warn(VonagePermissionMessages.FailedToUnMute);
      Sentry.captureException(e);
    }
  };

  /** updates the microphone state in localStorage.
   *  this helps maintain the current microphone state across page reloads.
   *  since the relay store will be empty on a page reload, we retrieve the current
   *  microphone state from localStorage and update the relay store accordingly.
   *
   * @param micState - The current state of the microphone (true for mute, false for unmute).
   */
  const updateMicStateInLCStorage = (micState: boolean) => {
    // getiing the local storgae object to always get the updated data
    const localDataObj = getTchSettingsFromLCStorage();
    const newSettings = {
      groupLesson: {
        ...localDataObj.groupLesson,
        isMute: micState
      }
    };
    setTchSettingsToLCStorage(newSettings);
  };

  // #endregion

  // #region to Toggle Camera State

  // function to turn off the camera by stopping video stream of the video publisher
  // we will use this function in the camera dialog from where teacher can trun off their camera.
  const turnCameraOff = () => {
    try {
      if (videoPublisher) {
        videoPublisher.publishVideo(false);

        // updating relay store to get the updated cameraState,as we are using this state in 
        // camera dialog to show teacher's camera is on/Off
        setCurrentCameraStateInRelayStore(true);

        // maintaing the camera state in localStorage so we can get the correct state when teacher 
        // reload's the page as relay store becomes empty on page load/relaod
        updateCameraStateInLCStorage(true);
      } else {
        // updateing the current camera state in relay store
        setCurrentCameraStateInRelayStore(true);

        // maintaing the camera state in localStorage so we can get the correct state when teacher 
        // reload's the page as relay store becomes empty on page load/relaod
        updateCameraStateInLCStorage(true);
      }
    } catch (e: any) {
      toast.warn(VonagePermissionMessages.FailedToOffCam);
      Sentry.captureException(e);
    }
  };

  // function to turn on the camera by enabling video stream of the video publisher
  // we will use this function in the camera dialog from where teacher can trun on their camera.
  const turnCameraON = () => {
    try {
      if (videoPublisher) {
        videoPublisher.publishVideo(true);

        // updating relay store to get the updated cameraState,as we are using this state in 
        // camera dialog to show teacher's camera is on/Off
        setCurrentCameraStateInRelayStore(false);

        // maintaing the camera state in localStorage so we can get the correct state when teacher 
        // reload's the page as relay store becomes empty on page load/relaod
        updateCameraStateInLCStorage(false);
      } else {
        // updating relay store so we can get the updated values in all places
        setCurrentCameraStateInRelayStore(false);

        // maintaing the mic state in localStorage so we can get the correct state when teacher 
        // reload's the page as relay store becomes empty on page load/relaod
        updateCameraStateInLCStorage(false);
      }
    } catch (e: any) {
      toast.warn(VonagePermissionMessages.FailedToONCam);
      Sentry.captureException(e);
    }
  };

  /** updates the camera state in localStorage.
   *  this helps maintain the current camera state across page reloads.
   *  since the relay store will be empty on a page reload, we retrieve the current
   *  camera state from localStorage and update the relay store accordingly.
   *
   * @param cameraState - The current state of the camera
   */
  const updateCameraStateInLCStorage = (cameraState: boolean) => {
    const localDataObj = getTchSettingsFromLCStorage();
    const newSettings = {
      groupLesson: {
        ...localDataObj.groupLesson,
        isCameraOff: cameraState
      }
    };
    setTchSettingsToLCStorage(newSettings);
  };

  // #endregion

  // this useEffect will trigger on component unMount(route change) and disconnect the teacher from 
  // session and destory the publisher 
  // as we need to reset all the states to initilaz states 
  useEffect(() => (() => {
    // we are using vonageSessionRef.current to always get the updated value 
    // if we don't do so we will not get updated values 
    if (videoPublisherRef.current && vonageSessionRef.current) {
      videoPublisherRef.current.destroy();
      vonageSessionRef.current.disconnect();
    }
  }), []);

  const value = React.useMemo(
    () => ({
      demoPublish,
      accessGranted,
      vonageFirstInitialized,
      initializeSession,
      connectToSession,
      vonageSession,
      sessionConnected,
      disconnect,
      publishVideoStream,
      isTeacherScreensharing,
      stopScreenSharingStream,
      publishScreenSharingStream,
      subscribeToStream,
      disconnectFromSessionAndConnectToNewSession,
      previousRoomLayoutData,
      setPreviousRoomLayoutData,
      muteMicrophone,
      unMuteMicrophone,
      turnCameraOff,
      turnCameraON,
      videoPublisher,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      vonageFirstInitialized,
      sessionConnected,
      vonageSession,
      isTeacherScreensharing,
      videoPublisher,
    ]
  );

  return (
    <VonageContext.Provider value={value}>
      {children}
    </VonageContext.Provider>
  );
};

export { VonageProvider, VonageContext };
