import React from 'react';
import { Ty, Grid2Ct, Grid2 } from '@languageconvo/wcl';
import { StudentsSection4IndividualStudent } from './StudentsSection4IndividualStudent';
import { SelectedStudentData } from './StudentsSection';

interface Props {
    groupUUID: string,
    group1: { name: string; uuid: string;}[],
    onlineUsers: string[],
    handleClickForStudent: (
      _event: React.MouseEvent<HTMLButtonElement>,
      _student: SelectedStudentData, _groupUUID: string) => void
}

// Component displaying a list of students in group2.
export const StudentsSection2Group = ({
  group1,
  onlineUsers,
  groupUUID,
  handleClickForStudent
}: Props) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <Ty>Group 2</Ty>
      <hr />
      {/* display each student */}
      {group1.map((stu: any) => {
        const isOnline = onlineUsers.includes(stu.uuid);
        return (
          <StudentsSection4IndividualStudent
            isOnline={isOnline}
            student={stu}
            groupUUID={groupUUID}
            handleClickForStudent={handleClickForStudent}
          />
        );
      })}
    </Grid2>
  </Grid2Ct>
);
