import { sitedata } from '../../../utils/sitedata';

const ICONS = {
  home: `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/teacher.svg`,
  settings: `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/settings.svg`,
};

/** navConfig object contains array of all items, we are showing in our sidebar
 * It may have other properties too but for now, we just have sideNav items.
 */
const navConfig = {
  items: [
    {
      id: 0,
      // note: actual title comes from translation file, dont edit this here. edit it there
      title: 'Home',
      path: sitedata.url.app.home,
      isOpen: false,
      icon: ICONS.home
    },
    {
      id: 1,
      // note: actual title comes from translation file, dont edit this here. edit it there
      title: 'Profile',
      path: sitedata.url.app.moreHome,
      isOpen: false,
      icon: ICONS.settings,
    },
  ],
};

export default navConfig;
