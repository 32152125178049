/**
 * move a selected student into a specified group and update the room layout accordingly.
 * @param layoutNode the current state of the room layout node containing room details.
 * @param uuid the UUID of the current lesson.
 * @param selectedValue the UUID of the group where the student will be moved.
 * @param selectedStudent details of the student being moved(uuid) we are doing comparsion 
 * based on uuid.
 * @param handleCloseForStudent function to close dilaog after student is move in group
 * @param mutateRoomLayout function to mutate (update) the room layout with the new data.
 * @param initialRoomData initial snapshot of the room layout data for comparison.
 */

export const moveStudentIntoGroup = (
  layoutNode: any,
  uuid: string,
  selectedValue: string,
  selectedStudent: any,
  handleCloseForStudent: () => void,
  mutateRoomLayout: (_arg0: string, _arg1: any) => void,
  initialRoomData: any
) => {
  // initially. studentGroupRooms will be empty that's why need to add ? to avoid code breaking.
  const existingStudentsFor1 = layoutNode.rooms.studentGroupRooms[0]?.students || [];
  const existingStudentsFor2 = layoutNode.rooms.studentGroupRooms[1]?.students || [];

  let studentsGroup1;
  let studentsGroup2;

  // if belongs to group1 adding selected student into group1 and filtering same student
  // from 2nd group and vice versa.
  if (selectedValue === initialRoomData.av.studentGroupRooms[0].uuid) {
    studentsGroup1 = [...existingStudentsFor1, selectedStudent.uuid];
    studentsGroup2 = existingStudentsFor2.filter(
      (student: any) => student !== selectedStudent.uuid
    );
  } else if (selectedValue === initialRoomData.av.studentGroupRooms[1].uuid) {
    studentsGroup1 = existingStudentsFor1.filter(
      (student: any) => student !== selectedStudent.uuid
    );
    studentsGroup2 = [...existingStudentsFor2, selectedStudent.uuid];
  }
  // TODO: else log error and don't continue

  const updatedHash = {
    ...layoutNode,
    rooms: {
      ...layoutNode.rooms,
      studentGroupRooms: [
        {
          ...layoutNode.rooms.studentGroupRooms[0],
          students: studentsGroup1,
        },
        {
          ...layoutNode.rooms.studentGroupRooms[1],
          students: studentsGroup2,
        },
      ],
    },
  };

  mutateRoomLayout(uuid, updatedHash);
  handleCloseForStudent();
};
