import React from 'react';
import {
  Grid2Ct, Grid2, Ty, LottiePlayer,
} from '@languageconvo/wcl';
import SettingsProblem from '../../../../../common/assets/lottie/settings_problem.json';

// cam/mic permissions denied during pageload
export const V101 = () => (
  <Grid2Ct
    // this vertically centers the children
    sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
  >
    <Grid2 xs={12}>
      <LottiePlayer animationSrc={SettingsProblem} animationHeight={180} />
    </Grid2>

    <Grid2 xs={12} sm={10} smOffset={1}>
      <Ty v="h1New" align="center">A Problem Occurred</Ty>
      <Ty>
        It looks like you accidentally denied permission to your microphone or
        camera, which the classroom needs to function correctly. You can try:
        <br /><br />
        1. Refreshing the page. When prompted, accept the microphone permissions.
        <br /><br />
        2. If you are not automatically prompted for microphone permissions when you refresh
        the page, you will need to change your browser settings to allow our website
        permissions. In most browsers, this can be done by clicking the settings option
        in the URL bar at the top of the page.
      </Ty>
    </Grid2>
  </Grid2Ct>
);
