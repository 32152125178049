import { graphql } from 'babel-plugin-relay/macro';

export const MicDetailsFragment = graphql`
  fragment MicDetailsFragment on appsetting {
    microphone {
      current {
          micId
          title
          isMuted
      }
      all {
          edges {
              id
              title
          }
      }
    }
  }
`;
