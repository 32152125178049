import React from 'react';
import {
  styled, Box, Grid2Ct, Grid2, CardContent, WCLThemeProps, LottiePlayer, Ty, ScrollArea,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import RabbitLaptop from '../../../../../common/assets/lottie/rabbit_laptop.json';
import BirdBye from '../../../../../common/assets/lottie/birdbye.json';
import LionSitting from '../../../../../common/assets/lottie/lion_sitting.json';
import { HEADER } from '../../../../../config';
import { V101 } from './V101';

interface Props {
  view: number;
}

/*
    0 = lesson is ongoing, return nothing so the class functionality is displayed
    100 = main page is loading
    101 = access not granted to devices
    102 = on pageload, the lesson had already ended
    103 = on pageload, the lesson starts more than 3 hours from now
    104 = on pageload, the lesson no longer exists
    105 = lesson is ongoing then ends
*/
export const MainView = ({ view }: Props) => {
  if (view === 0) {
    return (null);
  }

  if (view === 100) {
    return (
      <MainContainer>
        <StandardLottieScreen
          topsection={(<PageloadRabbitLottie />)}
          bottomsection={(
            <Ty v="h2New" align="center">
              Setting up classroom...
            </Ty>
          )}
        />
      </MainContainer>
    );
  }

  // device permissions denied
  if (view === 101) {
    return (
      <MainContainerScrollarea>
        <V101 />
      </MainContainerScrollarea>
    );
  }

  // on pageload, the lesson had already ended
  if (view === 102) {
    return (
      <MainContainer>
        <StandardLottieScreen
          topsection={(<LessonendBirdLottie />)}
          bottomsection={(
            <Ty v="h2New" align="center">
              This class has already ended!
            </Ty>
          )}
        />
      </MainContainer>
    );
  }

  // 103 = on pageload, the lesson starts more than 3 hours from now
  if (view === 103) {
    return (
      <MainContainer>
        <StandardLottieScreen
          topsection={(
            <LottiePlayer
              animationSrc={LionSitting}
              animationHeight={250}
            />
          )}
          bottomsection={(
            <Ty v="h2New" align="center">
              Please return later, once this class is starting within 3 hours
            </Ty>
          )}
        />
      </MainContainer>
    );
  }

  // 104 = on pageload, the lesson no longer exists
  if (view === 104) {
    return (
      <MainContainer>
        <StandardLottieScreen
          topsection={(
            <LottiePlayer
              animationSrc={LionSitting}
              animationHeight={250}
            />
          )}
          bottomsection={(
            <Ty v="h2New" align="center">
              This lesson no longer exists, it looks like it was canceled
            </Ty>
          )}
        />
      </MainContainer>
    );
  }

  // 105 = lesson is ongoing then ends
  if (view === 105) {
    return (
      <MainContainer>
        <StandardLottieScreen
          topsection={(<LessonendBirdLottie />)}
          bottomsection={(
            <Ty v="h2New" align="center">
              This class has now ended, thank you!
            </Ty>
          )}
        />
      </MainContainer>
    );
  }

  // should never happen, log
  Sentry.captureException(
    new Error('MainView received unexpected view:'),
    {
      extra: {
        theDt: view,
      }
    }
  );
  return (null);
};

// #region sub-componens

const MainContainer = ({ children }: any) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <MainContainerCard>
        <CardContent cp={{ sx: { height: '100%' } }}>
          {children}
        </CardContent>
      </MainContainerCard>
    </Grid2>
  </Grid2Ct>
);

const MainContainerScrollarea = ({ children }: any) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <MainContainerCard>
        <CardContent cp={{ sx: { height: '100%' } }}>
          <ScrollArea>
            {children}
          </ScrollArea>
        </CardContent>
      </MainContainerCard>
    </Grid2>
  </Grid2Ct>
);

export const MainContainerCard = styled(Box)(({ theme }: WCLThemeProps) => ({
  // TODO: calc height properly
  height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px - 50px)`,
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  backgroundColor: `hsl(${theme?.ourTheme.colors.app.container})`,
  overflow: 'hidden',
}));

// This is our commonly used design, with a lottie on top and text on bottom
// exported because this is used in the coundown clock component, to ensure it has the exact
// same design as the "getting device permissions..." and "connecting to the class..."
export const StandardLottieScreen = ({ topsection, bottomsection }: any) => (
  <Grid2Ct
      // this vertically centers the children
    sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
  >
    <Grid2 xs={12}>
      <Grid2Ct>
        {/* lottie */}
        <Grid2 xs={12} sm={10} smOffset={1}>
          {topsection}
        </Grid2>

        {/* text */}
        <Grid2 xs={12} sm={10} smOffset={1} xl={8} xlOffset={2}>
          {bottomsection}
        </Grid2>
      </Grid2Ct>
    </Grid2>
  </Grid2Ct>
);

const PageloadRabbitLottie = () => (
  <LottiePlayer
    animationSrc={RabbitLaptop}
    animationHeight={250}
  />
);

// the bird lottie used for "lesson ended". we definite it here so it can be reused in all the
// places it's needed, which ensures the deisgn (height and playback speed) is the same everywhere
const LessonendBirdLottie = () => (
  <LottiePlayer
    animationSrc={BirdBye}
    animationHeight={250}
    playbackSpeed={0.4}
  />
);

// #endregion
