import React, { useEffect, useState } from 'react';
import {
  Ty, Grid2Ct, Grid2, Ic,
} from '@languageconvo/wcl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import Page from '../../components/pagewrapper/Page';
import { TchAuthQuickLoginMutation } from './relay/TchAuthQuickLogin';
import { setJWTForCurrentUserInLocalStorage } from '../../utils/setUserState';
import useAuth from '../../hooks/useAuth';
import { localStorageKeys } from '../../common/localStorage/keys';
import { QuickAuthMutationErrors } from './errors/QuickAuthErrors';

const QuickAuth = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [commitMutationAuthQuickLogin] = useMutation(TchAuthQuickLoginMutation);
  const [isKnownError, setIsKnownError] = useState(false);
  const [isUnknownError, setIsUnknownError] = useState(false);

  useEffect(() => {
    /** 
     * here we are making this mutation call, which will return us the jwt if the
     * uuid that we have passed in is valid.
     * - if the mutation call is successful, we set that JWT in localStorage and redirect
     *   user to /home.
     * - if the mutation call returns error, we have 2 possibilities
     *    - the only error backend can return is "FailedToLogin", in this case we show user a
     *      message explaining what happend
     *    - the unexpected error happend, we log error in sentry and show user something went wrong
    */
    commitMutationAuthQuickLogin({
      variables: {
        uuid: id,
      },

      onCompleted(response: any) {
        setJWTForCurrentUserInLocalStorage(
          response.tch_auth_quicklogin.data,
          setAuth,
          localStorageKeys.KeyForTchSecurity,
          navigate
        );
      },

      onError(error: any) {
        // if the error code matches our known error which backend can return
        // then we have to show a specific message to the user, that's why we are
        // setting a state `setIsKnownError` to true here.
        if (error[0]?.extensions?.code === QuickAuthMutationErrors.FailedToLogin) {
          setIsKnownError(true);

          // if the error code doesn't matche our known error which backend can return
          // then we have to show a different message to the user, that's why we are
          // setting a state `setIsUnknownError` to true here and logging the error in sentry.
        } else {
          setIsUnknownError(true);
          Sentry.captureException(error);
        }
      },
    });

    // we don't need any dependency here because we are only making this 
    // mutation call on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Authenticate" dataTestId="page-quickauth">
      <Grid2Ct>
        <Grid2 xs={12}>
          {/* show the word authenticating... */}
          {!isKnownError && !isUnknownError && (
            <Ty v="h2New" align="center"><Ic iconName="sun" iconStyle="duotone" color="accentGreen1" animationType="spin" />&nbsp;&nbsp;Authenticating…</Ty>
          )}

          {/* display error message in case of known error */}
          {isKnownError && (
            <>
              <Ty v="h2New" align="center">
                <Ic iconName="traffic-cone" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;Error
              </Ty>
              <Ty align="center" cp={{ sx: { mt: 2 } }}>
                Please go back to UI Bakery and get a new authentication link, it
                looks like this one has already been used or is expired. Remember
                that authentication links expire after 3 minutes, for security!
              </Ty>
            </>
          )}

          {/* display error message in case of un-known error */}
          {isUnknownError && (
            <>
              <Ty v="h2New" align="center">
                <Ic iconName="traffic-cone" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;Error
              </Ty>
              <Ty align="center" cp={{ sx: { mt: 2 } }}>
                Something unexpected happened &mdash; if this continues to occur,
                please let us know so that we can fix it.
                Please go back to UI Bakery and get a new authentication link.
              </Ty>
            </>
          )}
        </Grid2>
      </Grid2Ct>
    </Page>
  );
};

export default QuickAuth;
