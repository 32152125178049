import React, { useState, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Button } from '@languageconvo/wcl';
import { useSearchParams } from 'react-router-dom';
import { useFragment } from 'react-relay';
import { useUpdateRoomLayout } from '../../hooks/useUpdateRoomLayout';
import { StudentData } from './StudentsSection';
import { CustomMutationDataLessonDetailFragment } from '../../relay/CustomMutationData';
import {
  CustomMutationDataLessonDetailFragment$data,
  CustomMutationDataLessonDetailFragment$key,
} from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
import { QueryDataRoomLayoutFragment$data } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';

interface Props {
  students: StudentData[];
  isLessonStarted: boolean;
  layoutNode: QueryDataRoomLayoutFragment$data;
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  onlineUsers: string[]
}

// auto arrange btn in student section
export const StudentsSection5AutoArrange = ({
  students,
  isLessonStarted,
  layoutNode,
  classroomDataFragmentRef,
  onlineUsers
}: Props) => {
// #region general setup, react state variables, etc

  /**
   * there are multiple places from where we are mutating room layout data, so
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { loading, mutateRoomLayout } = useUpdateRoomLayout();
  // getting lesson UUID from the search parameters in the URL
  const [searchParams] = useSearchParams();
  const lessonID: string | null = searchParams.get('uuid');
  // state store the lists of online students.we will divide students using onlineStudent array
  const [onlineStudents, setOnlineStudents] = useState<StudentData[]>([]);
  // state store the lists of offline students.we will divide students using offlineStudents array
  const [offLineStudents, setOffLineStudents] = useState<StudentData[]>([]);

  // #endregion

  // the useLayoutEffect will trigger when a new student joins the lesson OR an offline student join
  // the lesson student will be added in online and offline state accordingly
  useLayoutEffect(() => {
    if (onlineUsers.length) {
      // filtering the students to get those who are currently online
      const onlineStd = students.filter((student: any) => onlineUsers.includes(student.uuid));
      setOnlineStudents(onlineStd);
      // filtering the students to get those who are currently offline
      const offlineStd = students.filter((student: any) => !onlineUsers.includes(student.uuid));
      setOffLineStudents(offlineStd);
    }

    // eslint-disable-next-line
  }, [onlineUsers, students]);

  // #region for dividing students into groups

  // reading lesson data.
  const initialClassroomData: CustomMutationDataLessonDetailFragment$data = useFragment(
    CustomMutationDataLessonDetailFragment,
    classroomDataFragmentRef
  );

  // TODO: Need to update comments for now we're testing our code may be we update our logic for
  //  for any of scenarios 
  /** autoArrangeStudent function organizes students into two groups and updates the 
    room layout data:
  * if there are more than 5 online students, they are divided into two groups alternately.
  * If there are 5 or fewer online students, all are assigned to one group.
  * offline students are then added to balance the sizes of the two groups.
  * finally, the room layout data is updated with these new student group assignments.
*/
  const autoArrangeStudent = (uuid: string | null, roomlayoutNode: any) => {
    if (uuid) {
      const studentInGroup1: string[] = [];
      const studentInGroup2: string[] = [];

      try {
        // when there are 5 or less than 5 students in the class room we are putting 
        // them into one group no matter they are online/offline
        if (students.length <= 5) {
          for (let l = 0; l < students.length; l += 1) {
            studentInGroup1.push(students[l].uuid);
          }

          // when student length in 6 or greater 
        } else {
          for (let i = 0; i < onlineStudents.length; i += 1) {
          // when 3 student's online put them in group.
            if (onlineStudents.length > 6) {
            // divide students into two groups based on the 
            // index (even index to group 1, odd to group 2).
              if (i % 2 === 0) {
                studentInGroup1.push(onlineStudents[i].uuid);
              } else {
                studentInGroup2.push(onlineStudents[i].uuid);
              }
            } else {
            // when the number of online students is less than or equal to 3, all go into group 1.
              studentInGroup1.push(onlineStudents[i].uuid);
            }
          }

          // Loop through all offline students and balance them between the two groups.
          for (let j = 0; j < offLineStudents.length; j += 1) {
          // checking the sizes of the two groups and add the current offline 
          // student to the group with fewer students, This ensures that the groups
          //  remain as balanced in size as possible.
            if (studentInGroup1.length > studentInGroup2.length) {
            // if group 1 has more students, add the current offline student to group 2.
              studentInGroup2.push(offLineStudents[j].uuid);
            } else {
            // otherwise, add the current offline student to group 1
              studentInGroup1.push(offLineStudents[j].uuid);
            }
          }
        }
      } catch (e) {
        // log an error if we ever get 
        Sentry.captureException(
          new Error('UnExpected! one of the student came online or goes offline when auto arrange button is being clicked '),
          {
            extra: {
              error: e
            }
          }
        );
      }

      // after dividing students into groups, we are updating our roomLayout data accordingly.
      const updatedHash = {
        ...roomlayoutNode,
        rooms: {
          ...roomlayoutNode.rooms,
          studentGroupRooms: [
            {
              uuid: initialClassroomData.av.studentGroupRooms[0]!.uuid,
              students: studentInGroup1
            },
            {
              uuid: initialClassroomData.av.studentGroupRooms[1]!.uuid,
              students: studentInGroup2
            }
          ]
        },
      };
      mutateRoomLayout(uuid, updatedHash);
    }
  };
  // #endregion

  return (
    <Button
      color="accentGreen1"
      disabled={!isLessonStarted || loading}
      onClick={() => autoArrangeStudent(
        lessonID,
        layoutNode
      )}
      cp={{ sx: { mt: 2 } }}
    >
      Auto Arrange
    </Button>
  );
};
