import {
  AppBar,
  Toolbar,
  Box,
  styled,
  WCLThemeProps,
} from '@languageconvo/wcl';
import cssStyles from '../../../utils/cssStyles';
import { HEADER } from '../../../config';

type RootStyleProps = {
    isDesktop: boolean | null;
};

export const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isDesktop',
})<RootStyleProps & WCLThemeProps>(({
  isDesktop, theme
}) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  transition: theme?.ourTheme.components.transition.page,
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  },
  // to make clipped appBar, we need to apply z-index greater than drawer.
  zIndex: isDesktop ? theme.zIndex.drawer + 1 : theme.zIndex.appBar + 1,
  // bg color of the entire header
  backgroundColor: `hsl(${theme.ourTheme.colors.app.navbar})`,
}));

export const ToolBar = styled(Toolbar)(({ theme }: WCLThemeProps) => ({
  minHeight: '100% !important',
  [theme!.breakpoints.up('lg')]: {
    px: 5
  }
}));

// logo in the header
export const Logo = styled('img')({
  position: 'relative'
});

// on small screens, this is the hamburger icon that opens the drawer
// TODO: this should be a wcl component
export const Hamburger = styled(Box)(({ theme }: WCLThemeProps) => ({
  transition: theme?.ourTheme.components.transition.item,
  width: '40px',
  height: '40px',
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...(theme?.themeMode === 'light' && {
    color: `hsl(${theme?.ourTheme.colors.text.base})`,
    '&:hover': {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter2})`,
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    color: `hsl(${theme?.ourTheme.colors.text.base})`,
    '&:hover': {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.primary.darkest})`,
    },
  }),
}));

export const HeaderSpace = styled(Box)(() => ({
  flexGrow: 1
}));
