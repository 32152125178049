/**
 * @generated SignedSource<<5bbb9f67cc5b276d355c8f7e93dacf40>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TchApptgroupClassroomeventMutation$variables = {
  eventName: string;
  lessonUuid: string;
};
export type TchApptgroupClassroomeventMutation$data = {
  readonly tch_apptgroup_classroomevent: {
    readonly success: boolean;
  } | null;
};
export type TchApptgroupClassroomeventMutation = {
  response: TchApptgroupClassroomeventMutation$data;
  variables: TchApptgroupClassroomeventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lessonUuid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "eventName",
        "variableName": "eventName"
      },
      {
        "kind": "Variable",
        "name": "lessonUuid",
        "variableName": "lessonUuid"
      }
    ],
    "concreteType": "TchApptgroupClassroomeventOutput",
    "kind": "LinkedField",
    "name": "tch_apptgroup_classroomevent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TchApptgroupClassroomeventMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TchApptgroupClassroomeventMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "93418fd57f19fb7c8cd357ad6da6fdeb",
    "id": null,
    "metadata": {},
    "name": "TchApptgroupClassroomeventMutation",
    "operationKind": "mutation",
    "text": "mutation TchApptgroupClassroomeventMutation(\n  $lessonUuid: String!\n  $eventName: String!\n) {\n  tch_apptgroup_classroomevent(lessonUuid: $lessonUuid, eventName: $eventName) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "78f44c5103ba6c5369f382c7a52e8446";

export default node;
