/**
 * @generated SignedSource<<1a7879ab2bf88c9dc5003aad0dcf5c23>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CameraDetailsFragment$data = {
  readonly camera: {
    readonly all: {
      readonly edges: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
      } | null>;
    };
    readonly current: {
      readonly camId: string | null;
      readonly isCameraOff: boolean;
      readonly title: string | null;
    };
  };
  readonly " $fragmentType": "CameraDetailsFragment";
};
export type CameraDetailsFragment$key = {
  readonly " $data"?: CameraDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CameraDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CameraDetailsFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CameraDetails",
          "kind": "LinkedField",
          "name": "camera",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CurrentCameraDetail",
              "kind": "LinkedField",
              "name": "current",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "camId",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCameraOff",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AllAvailableCameraDetailEdges",
              "kind": "LinkedField",
              "name": "all",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AllCameraDetailItem",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};
})();

(node as any).hash = "a0e676dda7c1f05439f252d82f76d15d";

export default node;
