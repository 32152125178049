import {
  RequestParameters,
  Variables,
  Environment,
  Network,
  Store,
  RecordSource,
} from 'relay-runtime';

async function fetchRelay(
  params: RequestParameters,
  variables: Variables,
) {
  const BASE_API_URL = `${process.env.REACT_APP_BASE_API}`;

  const response = await fetch(BASE_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });
  const jsonResponse = await response.json();
  if (Array.isArray(jsonResponse.errors)) {
    throw jsonResponse.errors;
  }

  return jsonResponse;
}

const AuthEnvironment = new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
});
export default AuthEnvironment;
