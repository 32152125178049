import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ScrollArea,
  Stack,
  Drawer,
  useTheme,
} from '@languageconvo/wcl';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// config
import { HEADER } from '../../../config';
import navConfig from './NavbarConfig';
// components
import {
  RootStyles,
  CustomStack,
  DrawerOverrides,
  Logo,
} from './NavbarContainer.styles';
import { NavbarInner } from './navbarinner/NavbarInner';

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

export const NavbarContainer = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDesktop]);

  const renderContent = (
    // wrapping content to our custom scrollbar present in wcl.
    <ScrollArea>
      {/* this logo is visible only on mobile devices. for desktop, the logo is
      in the header */}
      {!isDesktop && (
        <Stack sx={{ ...CustomStack }}>
          <Logo src={`${process.env.REACT_APP_CDNURL}/common/logo/chatboxes.svg`} data-testid="logo" alt="Logo" width="45" />
        </Stack>
      )}

      {/* the main content of our left-side navigation */}
      <NavbarInner
        navConfig={navConfig}
        isCollapse={isCollapse}
      />
    </ScrollArea>
  );

  return (
    // this styled component applying transition and dynamic width
    // based on the collapse prop on desktop screens.
    // In case of drawer collapse, we show icons only view with absolute position.
    <RootStyles
      sx={{
        width: {
          // eslint-disable-next-line max-len
          lg: isCollapse ? theme.ourTheme.components.navbar.navbarWidthCollapse : theme?.ourTheme.components.navbar.navbarWidthExpanded,
        },
        ...(isCollapse && {
          position: 'absolute',
        }),
      }}
    >
      {/* mobile drawer: its default variant is "temporary" which
          auto hide drawer if click outside */}
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: theme?.ourTheme.components.navbar.navbarWidthExpanded,
              backgroundColor: `hsl(${theme.ourTheme.colors.app.navbar})`,
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
      {/* desktop drawer */}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              paddingTop: `${HEADER.MAIN_DESKTOP_HEIGHT + 8}px`,
              borderRight: 'none',
              ...DrawerOverrides,
              ...(isCollapse && {
                width: theme.ourTheme.components.navbar.navbarWidthCollapse,
              }),
              backgroundColor: `hsl(${theme.ourTheme.colors.app.navbar})`,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyles>
  );
};
