import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import { TchApptgroupClassroomeventMutation } from '../relay/TchApptgroupClassroomevent';

/**
 * custom hook to report classroom events to our BE.
 * this hook is used to record events happening in the classroom
 * for a particular lesson 
 */
export const useReportClassroomEvent = () => {
  const [RecordTeacherClassRoomEventsMutation] = useMutation(TchApptgroupClassroomeventMutation);

  const reportClassroomEvent = (uuid: string, classroomEvent: string) => {
    // eslint-disable-next-line no-console
    console.log('classroomEvent', classroomEvent);
    try {
      RecordTeacherClassRoomEventsMutation({
        variables: {
          lessonUuid: uuid,
          eventName: classroomEvent
        },
        onCompleted(res: any) {
          // eslint-disable-next-line no-console
          console.log('classroom event success', res);
          // success, don't need to do anything
        },
        onError(err: any) {
          // eslint-disable-next-line no-console
          console.log('Classroom event error', err);
          // logging the error to sentry
          Sentry.captureException(`RecordTeacherClassRoomEventsMutation: ${err}`);
        },
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(`Error occured in RecordTeacherClassRoomEventsMutation ${error}`);
      // logging the error to sentry
      Sentry.captureException(error);
    }
  };

  return { reportClassroomEvent };
};
