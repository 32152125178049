import React, { useEffect, useRef } from 'react';
import { useTheme, Ty, CircularProgress } from '@languageconvo/wcl';
import { handleLogout } from '../utils';
import { AlignContentCenter, JustifyContent } from '../layouts/authOnly/index.style';
import useAuth from '../hooks/useAuth';

const SessionEnd = ({ message }: {message: string}) => {
  const theme = useTheme();
  const { setLogoutInProgress } = useAuth();
  const PrimaryColor = theme.palette.primary.main;

  const mutationExecuted = useRef(false);
  useEffect(() => {
    if (!mutationExecuted.current) {
      mutationExecuted.current = true;
      handleLogout(setLogoutInProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlignContentCenter excludelogoheight={300}>
      <JustifyContent>
        <CircularProgress data-testid="circularprogress" sx={{ color: PrimaryColor }} />
      </JustifyContent>
      <JustifyContent marginTop="calc(15vh)">
        <Ty align="center">
          {message}
        </Ty>
      </JustifyContent>
    </AlignContentCenter>
  );
};

export default SessionEnd;
