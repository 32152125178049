import React, {
  ReactNode, createContext, useCallback, useState
} from 'react';
import { getDataToLCStorage, setDataToLCStorage } from '../utils/lcStorage';

type SettingsProviderProps = {
    children: ReactNode;
  };

type initialStateType = {
  themeMode: 'light' | 'dark',
  onToggleMode: () => void;
}

const initialState: initialStateType = {
  themeMode: 'light',
  onToggleMode: () => {}
};

const SettingsContext = createContext(initialState);

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const storedValues = getDataToLCStorage('lc_tchsettings');
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>(storedValues?.theme?.mode || 'light');

  const getThemeMode = () => {
    const lcTchsettings = getDataToLCStorage('lc_tchsettings');
    if (lcTchsettings.theme) {
      return lcTchsettings.theme.mode;
    }
    return 'light';
  };

  /** this function will toggle the theme mode in localStorage. All other values inside
   * localStorage remains as it is only theme mode toggle.
   */
  const toggleThemeModeInLocalStorage = (updatedMode: 'light' | 'dark') => {
    const lcTchsettings = getDataToLCStorage('lc_tchsettings');
    const newSettings = {
      theme: {
        ...lcTchsettings.theme,
        mode: updatedMode,
      },
    };
    setDataToLCStorage('lc_tchsettings', newSettings);
  };

  const onToggleMode = useCallback(() => {
    const mode = getThemeMode() === 'light' ? 'dark' : 'light';
    setThemeMode(mode);
    toggleThemeModeInLocalStorage(mode);
  }, [setThemeMode]);

  const settingsProviderValue = React.useMemo(() => ({
    themeMode,
    onToggleMode,
  }), [themeMode, onToggleMode]);

  return (
    <SettingsContext.Provider
      value={settingsProviderValue}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
