import { graphql } from 'babel-plugin-relay/macro';

export const CustomMutationDataMutation = graphql`
  mutation CustomMutationDataMutation($lessonUuid: String!) {
    apptgroup_classroom_tch_enter(lessonUuid: $lessonUuid) {
      ...CustomMutationDataLessonDetailFragment
      ...CustomMutationDataLessonDateTimeFragment
      ...CustomMutationDataPusherDetailsFragment
    }
  }
`;

// This fragment contains lesson info i-e audio, video session tokens to connect with streaming
// library
export const CustomMutationDataLessonDetailFragment = graphql`
  fragment CustomMutationDataLessonDetailFragment on ApptGroupClassroomTchEnterOutput {
    lessonUuid
    av {
      studentGroupRooms
        {
          uuid
          token
          session
        }
      room2 {
        token
        session
      }
      room1 {
        session
        token
      }
    }
  }
`;

// lesson time for the info card
export const CustomMutationDataLessonDateTimeFragment = graphql`
  fragment CustomMutationDataLessonDateTimeFragment on ApptGroupClassroomTchEnterOutput {
    dt {
      startsAt
      secondsUntilStart
      secondsUntilEnd
      endsAt
      duration
      current
    }
  }
`;

// This fragment contains pusher info i-e channel name etc
export const CustomMutationDataPusherDetailsFragment = graphql`
  fragment CustomMutationDataPusherDetailsFragment on ApptGroupClassroomTchEnterOutput {
    pusherChannel
    av {
      studentGroupRooms {
        uuid
      }
    }
  }
`;
