import { graphql } from 'babel-plugin-relay/macro';

export const CameraDetailsFragment = graphql`
  fragment CameraDetailsFragment on appsetting {
    camera {
      current {
        camId
          title
          isCameraOff
      }
      all {
          edges {
              id
              title
          }
      }
    }
  }
`;
