import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { NavListProps } from '../../../../types/nav-section.type';
import {
  NavbarInnerListItemParent,
} from './NavbarInnerListItem';

export const NavbarInnerList = ({ list, isCollapse }: NavListProps) => {
  /** to show active states in sidebar, need pathname */
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);

  // else there are no child subpages, this will display. for example the Home section
  // is just one single page, has no children
  return <NavbarInnerListItemParent item={list} active={active} isCollapse={isCollapse} />;
};

function getActive(path: string, pathname: string) {
  return path ? !!matchPath({ path, end: false }, pathname) : false;
}
