import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import * as Sentry from '@sentry/react';
import * as atatus from 'atatus-spa';
import { GlobalStyleContainer } from '@languageconvo/wcl';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';
import { ThemeProvider } from './theme';
import AppEnvironment from './relay/AppEnvironment';
import { SettingsProvider } from './context/SettingsProvider';
import Router from './routes';
import { CollapseDrawerProvider } from './context/CollapseDrawerContext';
import { AuthProvider } from './context/AuthProvider';
import { AppLevelError } from './common/components/errorfallback/AppLevelError';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // to differentiate local, dev and production environments...
  environment: process.env.REACT_APP_SENTRY_ENV,
  // Don't need to send errors on sentry, in case of development...
  enabled: process.env.NODE_ENV !== 'development'
});

// to monitor real-time insights we are using atatus.
atatus.config(
  process.env.REACT_APP_ATATUS_UID as string,
  { disableErrorTracking: true }
).install();

const App = () => (
  // custom error catcher component.
  <Sentry.ErrorBoundary fallback={({ error }) => <AppLevelError error={error} />}>
    <HelmetProvider>
      {/* preparing relay environment. it is responsible for creating the relay env. */}
      <RelayEnvironmentProvider environment={AppEnvironment}>
        {/* context for user login state */}
        <AuthProvider>
          {/* langnauge translation provider */}
          <I18nextProvider i18n={i18n}>
            {/* overall site setting like dark and light mode etc */}
            <SettingsProvider>
              {/* this is coming from wcl which initialize MUI and our custom theme */}
              <ThemeProvider>
                {/* context for drawer isCollapse (open/close) in desktop view */}
                <CollapseDrawerProvider>
                  {/* applying global styling managed by wcl (i-e overflow: hidden etc.) */}
                  <GlobalStyleContainer>
                    {/* all routes initialize form here. */}
                    <Router />
                  </GlobalStyleContainer>
                </CollapseDrawerProvider>
              </ThemeProvider>
            </SettingsProvider>
          </I18nextProvider>
          {/* react-toastify for notifications */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </AuthProvider>
      </RelayEnvironmentProvider>
    </HelmetProvider>
  </Sentry.ErrorBoundary>
);
export default App;
