import { Device } from '@opentok/client';

import { GetMicIDFromLCStorage } from './localStore/toggleMicInLCStorage';
import { setAllAvailableMicsInRelayStore, setCurrentMicInRelayStore } from '../relay/clientschema/relayappsettings/microphoneFunctionality';

/** why we created that function:
  * in future, we may build our 1-on-1 classroom, and from there we also want to
  * set the micphone state, that why we have created this common function which we will call
  * most of the times when we getDevices from OT.getDevices. Because of this our relay store
  * will remain our single source of truth
*/

/** what it does is: 
   * manages microphone settings by selecting the appropriate microphone based 
   * on available devices and previously stored in localStorge. 
   * updates the relay store and local storage accordingly.
   * @param {Device[]} allMicroPhones - Array of all available microphones. 
   * can be empty if no audio devices are found. 
   * This function performs the following steps: 
   *  1. if no microphones are available (empty array), sets micID and micName to null 
   *     and update and relay store.
   *  2. if microphones are available: 
   *    a. checks for a previously used microphone ID in local storage. 
   *    b. if found, attempts to match it with the available devices and use it. 
   *    c. if not found or not available, store the first available mic in relay store.
   *  3. updates the relay store with the selected microphone id and name. 
   *  4. updates the relay store with all available microphones. 
 */
export const micManager = (allMicroPhones: any) => {
  // initialize default values for micID and micName to null
  let micID = null;
  let micName = null;

  // checking if allMicroPhones array is not empty and the first microphone has a valid deviceId
  // if valid we are using it otherwise default value will be return
  if (typeof (allMicroPhones[0]?.deviceId) === 'string' && allMicroPhones[0]?.deviceId.length > 0) {
    micID = allMicroPhones[0]?.deviceId;
  } else {
    // eslint-disable-next-line
    console.log('Not valid micId')
  }

  // checking if allMicroPhones array is not empty and the first microphone has a valid label
  // if valid we are using it otherwise default value will be return
  if (typeof (allMicroPhones[0]?.label) === 'string' && allMicroPhones[0]?.label.length > 0) {
    micName = allMicroPhones[0]?.label;
  } else {
    // eslint-disable-next-line
    console.log('Not valid mic label')
  }

  // if the user has any mics on their device, we'll check to see if one of them is a mic that
  // the user previously saved in localstorage as wanting to use
  if (allMicroPhones.length > 0) {
    // get the mic that the user *prefers* to use, from localstorage
    const previousMic = GetMicIDFromLCStorage();

    // if there is a preferred mic saved in localstorage, check to see if it is still attached
    // to the device. if it is attached, use it!
    if (previousMic !== null) {
      // checking if the preferred mic is available on the device
      const found = allMicroPhones.find((option: Device) => option.deviceId === previousMic);

      if (found) {
        micID = found.deviceId;
        micName = found.label;
      }
    }
  }

  // update the currently used mic in relay store
  setCurrentMicInRelayStore(micID, micName);

  // updating all devices in relay store
  setAllAvailableMicsInRelayStore(allMicroPhones);

  // we return the mic id and name that are now set in the relay store; there are cases where
  // these values are needed immediately, but the relay store update may take time to propogate
  // through react components. in those cases, these return values can be used
  const ret = {
    theMicId: micID,
    theMicName: micName,
  };
  return ret;
};
