import React, { useEffect } from 'react';
import useVonage from '../../hooks/useVonage';
import { Step2CustomMutationData } from './Step2CustomMutationData';

interface Props {
  setMainView: any;
  setmodalviewContents: any;
  setmodalviewState: any;
}

/**
 * Starting file of group lesson module, this will load the availalbe AV
 * devices and start demo publishing to get permissions of these devices
 */
export const Step1DeviceAccess = ({
  setMainView,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // via glabal state vairables, checking for permissions
  const {
    accessGranted, demoPublish, vonageFirstInitialized
  } = useVonage();

  // this useEffect will trigger once on page load to request camera and microphone permissions.
  // it calls the `demoPublish` function to initialize the process of asking for these permissions.
  useEffect(() => {
    demoPublish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if the required permissions (camera and microphone) are not granted
  // and if Vonage was initialized for the first time.
  // if permissions are not granted, notify the teacher that camera and microphone
  // permissions are required for proper functionality and showing
  // the `PermissionsNotGranted` component.
  if (!accessGranted && vonageFirstInitialized) {
    setMainView(101);
    return <div />;
  }

  // If access is granted, we are making our API to get classroom data, 
  // else display a message prompting the user to allow access to camera and microphone.
  return (
    accessGranted ? (
      <Step2CustomMutationData
        setMainView={setMainView}
        setmodalviewContents={setmodalviewContents}
        setmodalviewState={setmodalviewState}
      />
    ) : (null)
  );
};
