import { commitLocalUpdate } from 'relay-runtime';
import AppEnvironment from '../../../../relay/AppEnvironment';
import { RelayAppSettingsVals } from '../RelayAppSettingsTypes';

// setting camera information (cameraName, cameraId) in relay store.
export const setCurrentCameraInRelayStore = (
  cameraID: string | null,
  cameraName: string | null,
) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const currenMicRecord = store.get(RelayAppSettingsVals.currentCamIdVal);
    if (currenMicRecord) {
      currenMicRecord.setValue(
        cameraID,
        RelayAppSettingsVals.currenCameraIdName
      );
      currenMicRecord.setValue(
        cameraName,
        RelayAppSettingsVals.currentCameraTitleName
      );
    }
  });
};

// setting camera on/off state (isCameraOn: true/false) in relay store.
export const setCurrentCameraStateInRelayStore = (
  isCameraOn: boolean
) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const currenMicRecord = store.get(RelayAppSettingsVals.currentCamIdVal);
    if (currenMicRecord) {
      currenMicRecord.setValue(
        isCameraOn,
        RelayAppSettingsVals.currentCameraOnName
      );
    }
  });
};

// setting all available camera in relay store.
export const setAllAvailableCameraInRelayStore = (allCameras: any) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const allCameraFromRelay = store.get(RelayAppSettingsVals.allCamIdVal);
    if (allCameraFromRelay) {
      const arr = allCameras.map((cam: any) => {
        let rec = store.get(cam.deviceId);
        if (!rec) {
          rec = store.create(cam.deviceId, RelayAppSettingsVals.allCamType);
        }

        rec.setValue(cam.deviceId, 'id');
        rec.setValue(cam.label, 'title');

        return rec;
      });

      allCameraFromRelay.setLinkedRecords(arr, 'edges');
    }
  });
};
