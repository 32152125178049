import React, {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import * as Sentry from '@sentry/react';
import {
  Grid2,
  Card,
  CardContent,
  Box,
  Grid2Ct,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { useStudentsInfo } from '../../hooks/useStudentsInfo';
import { StudentsSection1Group } from './StudentsSection1Group';
import { StudentsSection2Group } from './StudentsSection2Group';
import { StudentsSection3NoGroup } from './StudentsSection3NoGroup';
import { StudentsSection6Dialog } from './StudentsSection6Dialog';
import { StudentsSection5AutoArrange } from './StudentsSection5AutoArrange';
import { useUpdateRoomLayout } from '../../hooks/useUpdateRoomLayout';
import { usePusher } from '../../hooks/usePusher';
import { QueryDataRoomLayoutFragment } from '../../relay/QueryData';
import { QueryDataRoomLayoutFragment$key } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';
import { CustomMutationDataLessonDetailFragment$key } from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
import { CustomMutationDataPusherDetailsFragment } from '../../relay/CustomMutationData';
import { GetEnrolledStudentsFragment$key } from '../../relay/__generated__/GetEnrolledStudentsFragment.graphql';
import { heightStudentLsnInfo } from '../CommonGroupComponents';

export interface StudentData {
  name: string;
  uuid: string;
}

export interface SelectedStudentData extends StudentData {
  groupUUID: string;
}

interface Props {
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  layoutFragmentRef: QueryDataRoomLayoutFragment$key;
  studentFragmentRef: GetEnrolledStudentsFragment$key;
  isLessonStarted: boolean;
  setReservedStudentLenght: Dispatch<SetStateAction<number>>;
  setStdWhoRaiedHand: Dispatch<SetStateAction<StudentData[]>>
}

// Its a student section layout which is responsible for displaying group1, group2, no group etc.
export const StudentsSection = ({
  classroomDataFragmentRef,
  layoutFragmentRef,
  studentFragmentRef,
  isLessonStarted,
  setReservedStudentLenght,
  setStdWhoRaiedHand
}: Props) => {
  // #region general data

  // getting lesson UUID from the search parameters in the URL
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid') as string;

  // fragment data
  const roomLayoutData: any = useFragment(QueryDataRoomLayoutFragment, layoutFragmentRef);
  const initialClassroomData: any = useFragment(
    CustomMutationDataPusherDetailsFragment,
    classroomDataFragmentRef,
  );
  const layoutNode = JSON.parse(roomLayoutData.appt_group_layout.layout);

  // #endregion

  // #region for pusher channel

  // usePusher hook responsible for pusher auth and notify whenever a user added or removed
  // via this pusher channel.
  // it monitors student online/offline status as they enter or leave the classroom,
  // enabling teachers to track student presence effectively.
  const { onlineUsers, isError } = usePusher(initialClassroomData.pusherChannel);

  // in case of pusher errors, we can either show a popup too.
  useEffect(() => {
    if (isError) {
      Sentry.captureException(isError.error);
    }
  }, [isError]);
  // #endregion

  // #region click on a specific student to open a dialog

  // state to hold selected student information, need to show this information
  //  when teacher clicks on a specfic student to see more info about a 
  // student(-i.e student current group)
  const [selectedStudent, setSelectedStudent] = useState<SelectedStudentData>();

  const [anchorElForStudent, setAnchorElForStudent] = useState<HTMLButtonElement | null>(null);

  const handleClickForStudent = (event: React.MouseEvent<HTMLButtonElement>, student:
    StudentData, groupUUID: string) => {
    event.preventDefault(); // preventing onClick to auto reload
    setAnchorElForStudent(event.currentTarget);
    setSelectedStudent({ ...student, groupUUID });
  };

  // responsible for closing the student info dialog.
  const handleCloseForStudent = () => {
    setAnchorElForStudent(null);
  };

  // #endregion

  // #region reading all students from subscription and check if they 
  // exist in groups 1 or 2, if they exist it means group chat is already going on
  // else place them in no group area.

  // via custom hook (useStudentsInfo) reading and listening students via subscription 
  const students = useStudentsInfo(studentFragmentRef);

  /**
   * there are multiple places from where we are mutating room layout data, so
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { mutateRoomLayout } = useUpdateRoomLayout();
  // setting student lenght to show it on lesson detail section 
  useEffect(() => {
    if (students.length) {
      // setting the no of student who have resvered the lesson
      setReservedStudentLenght(students.length);

      // filtering the student's who gave their feedback and storing them in a state
      // because we need to show these student's and their feedback in LessonInfo component
      const stdRaisedHand = students.filter((student: any) => student.handRaisedValue
            !== null && student.handRaisedTS !== null);
      setStdWhoRaiedHand(stdRaisedHand);

      // we are only updaing the layout data when there is change in enrolled students array
      if (students.length > layoutNode.students.length) {
        const data: any = layoutNode;
        const updatedHash = {
          ...data,
          students: [
            ...students
          ]
        };
        mutateRoomLayout(uuid, updatedHash);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  // in the start there will be no student in group0() and group1 so we need
  // to add optional chaining (?) here to avoid code breaking OR possibly teacher can add 
  // all student in one group
  const group0Students = layoutNode.rooms.studentGroupRooms[0]?.students || [];
  const group1Students = layoutNode.rooms.studentGroupRooms[1]?.students || [];

  // concating all student in single array for comparison
  // to show student in no group are who are not divded/added into any group
  const groupStudents = group0Students.concat(group1Students);

  // We are filtering students who are not currently assigned to any group to display
  // them separately in the 'no group' area.
  const studentsNotInGroups = students.filter(
    (student: StudentData) => !groupStudents.some((group: string) => group === student.uuid)
  );

  // We need students fullname, that is available in reserved student subscription
  // so filtering out studetns from there.
  // we need to show student's name on the UI.
  const group0 = students.filter((student: StudentData) => group0Students
    .some((group: string) => group === student.uuid));
  const group1 = students.filter((student: StudentData) => group1Students
    .some((group: string) => group === student.uuid));

  // #endregion

  return (
    <>
      <Card>
        <CardContent cp={{ sx: { minHeight: heightStudentLsnInfo } }}>
          {/* list of students in group 1, 2, and no group */}
          <Grid2Ct>
            {/* list of student in group 1 */}
            <Grid2 xs={12} sm={4}>
              <StudentsSection1Group
                group0={group0}
                onlineUsers={onlineUsers}
                groupUUID={initialClassroomData.av.studentGroupRooms[0]!.uuid}
                handleClickForStudent={handleClickForStudent}
              />
            </Grid2>

            {/* list of student in group 2 */}
            <Grid2 xs={12} sm={4}>
              <StudentsSection2Group
                group1={group1}
                onlineUsers={onlineUsers}
                groupUUID={initialClassroomData.av.studentGroupRooms[1]!.uuid}
                handleClickForStudent={handleClickForStudent}
              />
            </Grid2>

            {/* list of student in No Group (By default all the students are in no group area) */}
            <Grid2 xs={12} sm={4}>
              <StudentsSection3NoGroup
                onlineUsers={onlineUsers}
                studentsNotInGroups={studentsNotInGroups}
                handleClickForStudent={handleClickForStudent}
              />
            </Grid2>
          </Grid2Ct>

          {/* auto-arrange button */}
          <Grid2Ct>
            <Grid2 xs={12}>
              <Box display="flex" justifyContent="flex-end">
                {/* no need to show Auto Arrange btn if already divided into groups */}
                {group0Students.length === 0 && group1Students.length === 0 ? (
                  <StudentsSection5AutoArrange
                    onlineUsers={onlineUsers}
                    students={students}
                    isLessonStarted={isLessonStarted}
                    layoutNode={layoutNode}
                    classroomDataFragmentRef={classroomDataFragmentRef}
                  />
                ) : null}
              </Box>
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>

      {/* Dialog which shows selected student info(name, in which group 
           student is and student online/offline status) */}
      {anchorElForStudent !== null && (
        <StudentsSection6Dialog
          anchorElForStudent={anchorElForStudent!}
          handleCloseForStudent={handleCloseForStudent}
          selectedStudent={selectedStudent!}
          classroomDataFragmentRef={classroomDataFragmentRef}
          layoutNode={layoutNode}
          onlineUsers={onlineUsers}
          isLessonStarted={isLessonStarted}
        />
      )}
    </>
  );
};
