export const LoginPageImagesInfo = {
  images:
    [
      {
        id: 1,
        location: 'Fenghuang, China'
      },
      {
        id: 2,
        location: 'Strasbourg, France'
      },
      {
        id: 3,
        location: 'Kerala, India'
      },
      {
        id: 4,
        location: 'Oahu, United States'
      },
      {
        id: 5,
        location: 'Bali, Indonesia'
      },
      {
        id: 6,
        location: 'Rio De Janeiro, Brazil'
      },
      {
        id: 7,
        location: 'Fairy Meadows, Pakistan'
      },
      {
        id: 8,
        location: 'Lagos, Nigeria'
      },
      {
        id: 9,
        location: 'Farming Community, Bangladesh'
      },
      {
        id: 10,
        location: 'Moscow, Russia'
      },
      {
        id: 11,
        location: 'Tulum, Mexico'
      },
      {
        id: 12,
        location: 'Yokohama, Japan'
      },
      {
        id: 13,
        location: 'Great Rift Valley, Ethiopia'
      },
      {
        id: 14,
        location: 'El Nido, Philippines'
      },
      {
        id: 15,
        location: 'Halong Bay, Vietnam'
      },
      {
        id: 16,
        location: 'Giza, Egypt'
      },
      {
        id: 17,
        location: 'Lake Bunyonyi, Democratic Republic of the Congo'
      },
      {
        id: 18,
        location: 'Tehran, Iran'
      },
      {
        id: 19,
        location: 'Rothenburg ob der Tauber, Germany'
      },
      {
        id: 20,
        location: 'Istanbul, Turkey'
      },
      {
        id: 21,
        location: 'Damnoen Saduak, Thailand'
      },
      {
        id: 22,
        location: 'Sussex, U.K.'
      },
      {
        id: 23,
        location: 'Naples, Italy'
      },
      {
        id: 24,
        location: 'Kilimanjaro, Tanzania'
      },
      {
        id: 25,
        location: 'Cape Town, South Africa'
      },
      {
        id: 26,
        location: 'Bagan, Myanmar'
      },
      {
        id: 27,
        location: 'Busan, South Korea'
      },
      {
        id: 28,
        location: 'Tayrona National Park, Colombia'
      },
      {
        id: 29,
        location: 'Kilimanjaro, Kenya'
      },
      {
        id: 30,
        location: 'Port of Barcelona, Spain'
      },
      {
        id: 31,
        location: 'Kamianets-Podilskyi, Ukraine'
      },
      {
        id: 32,
        location: 'Ushuaia, Argentina'
      },
      {
        id: 33,
        location: 'Meroe, Sudan'
      },
      {
        id: 34,
        location: 'Lake Bunyonyi, Uganda'
      },
      {
        id: 35,
        location: 'Algiers, Algeria'
      },
      {
        id: 36,
        location: 'Erbil, Iraq'
      },
      {
        id: 37,
        location: 'Hel, Poland'
      },
      {
        id: 38,
        location: 'Banff National Park, Canada'
      },
      {
        id: 39,
        location: 'Merzouga, Morocco'
      },
      {
        id: 40,
        location: 'Kabul, Afghanistan'
      },
      {
        id: 41,
        location: 'Medina, Saudi Arabia'
      },
      {
        id: 42,
        location: 'Machu Picchu, Peru'
      },
      {
        id: 43,
        location: 'Angel Falls, Venezuela'
      },
      {
        id: 44,
        location: 'Kuala Lumpur, Malaysia'
      },
      {
        id: 45,
        location: 'Kukeldash Madrasah, Uzbekistan'
      },
      {
        id: 46,
        location: 'Fishing Community, Mozambique'
      },
      {
        id: 47,
        location: 'Annapurna South, Nepal'
      },
      {
        id: 48,
        location: 'Atinpoku, Ghana'
      },
      {
        id: 49,
        location: 'Sanaa, Yemen'
      },
      {
        id: 50,
        location: 'Tundavala Gap, Angola'
      },
      {
        id: 51,
        location: 'Baobab Alley, Madagascar'
      },
      {
        id: 52,
        location: 'Pyongyang, North Korea'
      },
      {
        id: 53,
        location: 'Sydney, Australia'
      },
      {
        id: 54,
        location: 'Fon of Bafut, Cameroon'
      },
      {
        id: 55,
        location: "Grand-Bassam, Côte d'Ivoire"
      },
      {
        id: 56,
        location: 'Niger River, Niger'
      },
      {
        id: 57,
        location: 'Colombo, Sri Lanka'
      },
      {
        id: 58,
        location: 'Sibiu, Romania'
      },
      {
        id: 59,
        location: 'Village, Burkina Faso'
      },
      {
        id: 60,
        location: 'Aleppo, Syria'
      },
      {
        id: 61,
        location: 'Bamako, Mali'
      },
      {
        id: 62,
        location: 'Santiago, Chile'
      },
      {
        id: 63,
        location: 'Lake Malawi, Malawi'
      },
      {
        id: 64,
        location: 'Astana, Kazakhstan'
      },
      {
        id: 65,
        location: 'Victoria Falls, Zambia'
      },
      {
        id: 66,
        location: 'Amsterdam, Netherlands'
      },
      {
        id: 67,
        location: 'Antigua, Guatemala'
      },
      {
        id: 68,
        location: 'Guayaquil, Ecuador'
      },
      {
        id: 69,
        location: 'Victoria Falls, Zimbabwe'
      },
      {
        id: 70,
        location: 'Angkor Wat, Cambodia'
      },
      {
        id: 71,
        location: 'Saint-Louis, Senegal'
      },
      {
        id: 72,
        location: 'Sahara Desert, Chad'
      },
      {
        id: 73,
        location: 'Sala Waterfall, Guinea'
      },
      {
        id: 74,
        location: 'Village, South Sudan'
      },
      {
        id: 75,
        location: 'Volcanoes National Park, Rwanda'
      },
      {
        id: 76,
        location: 'Lake Tanganyika, Burundi'
      },
      {
        id: 77,
        location: 'Sidi Bou Said, Tunisia'
      },
      {
        id: 78,
        location: 'Ganvie, Benin'
      },
      {
        id: 79,
        location: 'Gravensteen, Belgium'
      },
      {
        id: 80,
        location: 'Hargeisa, Somalia'
      },
      {
        id: 81,
        location: 'Havana, Cuba'
      },
      {
        id: 82,
        location: 'La Paz, Bolivia'
      },
      {
        id: 83,
        location: 'Labadee, Haiti'
      },
      {
        id: 84,
        location: 'Santorini, Greece'
      },
      {
        id: 85,
        location: 'Santo Domingo, Dominican Republic'
      },
      {
        id: 86,
        location: 'Prague, Czech Republic'
      },
      {
        id: 87,
        location: 'Porto, Portugal'
      },
      {
        id: 88,
        location: 'Baku, Azerbaijan'
      },
      {
        id: 89,
        location: 'Stockholm, Sweden'
      },
      {
        id: 90,
        location: 'Budapest, Hungary'
      },
      {
        id: 91,
        location: 'Minsk, Belarus'
      },
      {
        id: 92,
        location: 'Dubai, United Arab Emirates'
      },
      {
        id: 93,
        location: 'Fann Mountains, Tajikistan'
      },
      {
        id: 94,
        location: 'Drina River Canyon, Serbia'
      },
      {
        id: 95,
        location: 'Hallstatt, Austria'
      },
      {
        id: 96,
        location: 'Oberhofen am Thunersee, Switzerland'
      },
      {
        id: 97,
        location: 'Tel Aviv, Israel'
      },
      {
        id: 98,
        location: 'Copan, Honduras'
      },
      {
        id: 99,
        location: 'Raja Ampat Islands, Papua New Guinea'
      },
      {
        id: 100,
        location: 'Petra, Jordan'
      },
      {
        id: 101,
        location: 'Grottes de Nok, Togo'
      },
      {
        id: 102,
        location: 'Alexander Nevsky Cathedral, Bulgaria'
      },
      {
        id: 103,
        location: 'Vang Vieng, Laos'
      },
      {
        id: 104,
        location: 'Iguazu Falls, Paraguay'
      },
      {
        id: 105,
        location: 'Freetown, Sierra Leone'
      },
      {
        id: 106,
        location: 'Fezzan, Libya'
      },
      {
        id: 107,
        location: 'San Juan del Sur, Nicaragua'
      },
      {
        id: 108,
        location: 'Cerro Verde National Park, El Salvador'
      },
      {
        id: 109,
        location: 'Valley, Kyrgyzstan'
      },
      {
        id: 110,
        location: 'Beirut, Lebanon'
      },
      {
        id: 111,
        location: 'Marina Bay, Singapore'
      },
      {
        id: 112,
        location: 'Copenhagen, Denmark'
      },
      {
        id: 113,
        location: 'Helsinki, Finland'
      },
      {
        id: 114,
        location: 'Ashgabat, Turkmenistan'
      },
      {
        id: 115,
        location: 'Dahlak Archipelago, Eritrea'
      },
      {
        id: 116,
        location: 'Tatra Mountains, Slovakia'
      },
      {
        id: 117,
        location: 'Bergen, Norway'
      },
      {
        id: 118,
        location: 'Dzanga-Sangha, Central African Republic'
      },
      {
        id: 119,
        location: 'Jericho, State of Palestine'
      },
      {
        id: 120,
        location: 'Arenal Volcano, Costa Rica'
      },
      {
        id: 121,
        location: 'Kouilou-Niari River, Republic of the Congo'
      },
      {
        id: 122,
        location: 'Cork, Ireland'
      },
      {
        id: 123,
        location: 'Muscat, Oman'
      },
      {
        id: 124,
        location: 'Villefranche-de-Conflent, Liberia'
      },
      {
        id: 125,
        location: 'Hobbiton, New Zealand'
      },
      {
        id: 126,
        location: 'Rural Town, Mauritania'
      },
      {
        id: 127,
        location: 'Dubrovnik, Croatia'
      },
      {
        id: 128,
        location: 'Kuwait City, Kuwait'
      },
      {
        id: 129,
        location: 'Rural Town, Moldova'
      },
      {
        id: 130,
        location: 'Panama Canal, Panama'
      },
      {
        id: 131,
        location: 'Tbilisi, Georgia'
      },
      {
        id: 132,
        location: 'Mostar, Bosnia and Herzegovina'
      },
      {
        id: 133,
        location: 'Punta del Este, Uruguay'
      },
      {
        id: 134,
        location: 'Wulan Butong, Mongolia'
      },
      {
        id: 135,
        location: 'Yerevan, Armenia'
      },
      {
        id: 136,
        location: 'Sarande, Albania'
      },
      {
        id: 137,
        location: 'Vilnius, Lithuania'
      },
      {
        id: 138,
        location: 'Black River, Jamaica'
      },
      {
        id: 139,
        location: 'Windhoek, Namibia'
      },
      {
        id: 140,
        location: 'Palmwag, Botswana'
      },
      {
        id: 141,
        location: 'Doha, Qatar'
      },
      {
        id: 142,
        location: 'Sani Pass, Lesotho'
      },
      {
        id: 143,
        location: 'Banjul, Gambia'
      },
      {
        id: 144,
        location: 'Kaneo, Macedonia'
      },
      {
        id: 145,
        location: 'Piran, Slovenia'
      },
      {
        id: 146,
        location: 'Riga, Latvia'
      },
      {
        id: 147,
        location: 'Bijagos Islands, Guinea-Bissau'
      },
      {
        id: 148,
        location: 'Rainforest Waterfalls, Gabon'
      },
      {
        id: 149,
        location: 'Manama, Bahrain'
      },
      {
        id: 150,
        location: 'Maracas Bay, Trinidad and Tobago'
      },
      {
        id: 151,
        location: 'Mlilwane Wildlife Sanctuary, Swaziland'
      },
      {
        id: 152,
        location: 'Tallinn, Estonia'
      },
      {
        id: 153,
        location: 'Mahebourg, Mauritius'
      },
      {
        id: 154,
        location: 'Dili Beach, Timor-Leste'
      },
      {
        id: 155,
        location: 'Limassol, Cyprus'
      },
      {
        id: 156,
        location: 'Lake Assal, Djibouti'
      },
      {
        id: 157,
        location: 'Navala, Fiji'
      },
      {
        id: 158,
        location: 'Bata, Equatorial Guinea'
      },
      {
        id: 159,
        location: 'Comoro Islands, Comoros'
      },
      {
        id: 160,
        location: 'Taktshang Goemba, Bhutan'
      },
      {
        id: 161,
        location: 'Kaieteur National Park, Guyana'
      },
      {
        id: 162,
        location: 'Bay of Kotor, Montenegro'
      },
      {
        id: 163,
        location: 'Guadalcanal Island, Solomon Islands'
      },
      {
        id: 164,
        location: 'Luxembourg City, Luxembourg'
      },
      {
        id: 165,
        location: 'Nieuw Amsterdam, Suriname'
      },
      {
        id: 166,
        location: 'Santiago, Cabo Verde'
      },
      {
        id: 167,
        location: 'Bandar Seri Begawan, Brunei'
      },
      {
        id: 168,
        location: 'Mellieha, Malta'
      },
      {
        id: 169,
        location: 'Eleuthera, Bahamas'
      },
      {
        id: 170,
        location: 'Atoll, Maldives'
      },
      {
        id: 171,
        location: 'Caye Caulker, Belize'
      },
      {
        id: 172,
        location: 'Stokksnes, Iceland'
      },
      {
        id: 173,
        location: 'Bathsheba Bay, Barbados'
      },
      {
        id: 174,
        location: 'Espiritu Santo, Vanuatu'
      },
      {
        id: 175,
        location: 'Hidden Beach, Sao Tome and Principe'
      },
      {
        id: 176,
        location: 'Pago Pago, Samoa'
      },
      {
        id: 177,
        location: 'Soufriere, Saint Lucia'
      },
      {
        id: 178,
        location: 'Tabuaeran, Kiribati'
      },
      {
        id: 179,
        location: 'Barrouallie, St. Vincent & Grenadines'
      },
      {
        id: 180,
        location: "St. George's, Grenada"
      },
      {
        id: 181,
        location: "Vava'u, Tonga"
      },
      {
        id: 182,
        location: 'Malakal, Micronesia'
      },
      {
        id: 183,
        location: 'La Digue, Seychelles'
      },
      {
        id: 184,
        location: "St. John's, Antigua and Barbuda"
      },
      {
        id: 185,
        location: 'Soufriere Bay, Dominica'
      },
      {
        id: 186,
        location: 'Encamp, Andorra'
      },
      {
        id: 187,
        location: 'Basseterre, Saint Kitts and Nevis'
      },
      {
        id: 188,
        location: 'Coral Reef, Marshall Islands'
      },
      {
        id: 189,
        location: 'Vaduz Castle, Liechtenstein'
      },
      {
        id: 190,
        location: 'Monte Carlo, Monaco'
      },
      {
        id: 191,
        location: 'Rocca della Guaita, San Marino'
      },
      {
        id: 192,
        location: 'Islands, Palau'
      },
      {
        id: 193,
        location: 'Unnamed Beach, Nauru'
      },
      {
        id: 194,
        location: 'Unnamed Beach, Tuvalu'
      },
      {
        id: 195,
        location: "Saint Peter's Square, Holy See"
      }
    ]
};
