/**
 * @generated SignedSource<<1125ee7235788f90400169899aa1e718>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomMutationDataMutation$variables = {
  lessonUuid: string;
};
export type CustomMutationDataMutation$data = {
  readonly apptgroup_classroom_tch_enter: {
    readonly " $fragmentSpreads": FragmentRefs<"CustomMutationDataLessonDateTimeFragment" | "CustomMutationDataLessonDetailFragment" | "CustomMutationDataPusherDetailsFragment">;
  } | null;
};
export type CustomMutationDataMutation = {
  response: CustomMutationDataMutation$data;
  variables: CustomMutationDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "lessonUuid",
    "variableName": "lessonUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "session",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomMutationDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApptGroupClassroomTchEnterOutput",
        "kind": "LinkedField",
        "name": "apptgroup_classroom_tch_enter",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomMutationDataLessonDetailFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomMutationDataLessonDateTimeFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomMutationDataPusherDetailsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomMutationDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApptGroupClassroomTchEnterOutput",
        "kind": "LinkedField",
        "name": "apptgroup_classroom_tch_enter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonUuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApptGroupClassroomTchEnterAv",
            "kind": "LinkedField",
            "name": "av",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ApptGroupClassroomTchEnterRoomStudGrp",
                "kind": "LinkedField",
                "name": "studentGroupRooms",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ApptGroupClassroomTchEnterRoom",
                "kind": "LinkedField",
                "name": "room2",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ApptGroupClassroomTchEnterRoom",
                "kind": "LinkedField",
                "name": "room1",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApptGroupClassroomTchEnterDt",
            "kind": "LinkedField",
            "name": "dt",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondsUntilStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondsUntilEnd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "current",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pusherChannel",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da383f61055f2e4f8971065455bae1b3",
    "id": null,
    "metadata": {},
    "name": "CustomMutationDataMutation",
    "operationKind": "mutation",
    "text": "mutation CustomMutationDataMutation(\n  $lessonUuid: String!\n) {\n  apptgroup_classroom_tch_enter(lessonUuid: $lessonUuid) {\n    ...CustomMutationDataLessonDetailFragment\n    ...CustomMutationDataLessonDateTimeFragment\n    ...CustomMutationDataPusherDetailsFragment\n  }\n}\n\nfragment CustomMutationDataLessonDateTimeFragment on ApptGroupClassroomTchEnterOutput {\n  dt {\n    startsAt\n    secondsUntilStart\n    secondsUntilEnd\n    endsAt\n    duration\n    current\n  }\n}\n\nfragment CustomMutationDataLessonDetailFragment on ApptGroupClassroomTchEnterOutput {\n  lessonUuid\n  av {\n    studentGroupRooms {\n      uuid\n      token\n      session\n    }\n    room2 {\n      token\n      session\n    }\n    room1 {\n      session\n      token\n    }\n  }\n}\n\nfragment CustomMutationDataPusherDetailsFragment on ApptGroupClassroomTchEnterOutput {\n  pusherChannel\n  av {\n    studentGroupRooms {\n      uuid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c02f6e42c10a9db9aa49a88ff05b435";

export default node;
