export const url = {
  // locations inside of our app
  app: {
    // home
    home: '/home',
    // more
    moreHome: '/more',

    // group lesson route
    groupclassroomDt: {
      routerPage: 'classroom',
      pathFull: '/group/classroom?uuid=',
      pathName: '/group/classroom',
      allowVersionRefresh: false,
    }
  },
};
