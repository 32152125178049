import React from 'react';
import Page from '../../components/pagewrapper/Page';
import SessionEnd from '../../common/SessionEnd';

const Logout = () => (
  <Page title="Logging Out" dataTestId="page-auth_logout">
    <SessionEnd message="Please Wait! We&apos;re logging you out...." />
  </Page>
);

export default Logout;
