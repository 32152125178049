import {
  Box, Ty, styled, StyledComponentProps, WCLThemeProps
} from '@languageconvo/wcl';

interface BackgroundImageProps extends StyledComponentProps{
  imageurl: any
}

export const JustifyContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
}));

interface ContianerHeightProp {
  excludelogoheight?: number | undefined
}

export const AlignContentCenter = styled(Box)<ContianerHeightProp>(({ excludelogoheight }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: `calc(100vh - ${excludelogoheight || 120}px)`,
}));

export const BackgroundDiv = styled('div')<BackgroundImageProps & WCLThemeProps>(({ theme, imageurl }) => ({
  width: '100%',
  height: '100vh',
  backgroundImage: `url(${imageurl})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const CardMain = styled('div')(() => ({
  width: '33.3%',
  maxWidth: '300px',
  float: 'right',
  marginRight: '50px',
  marginTop: '20px',
  justifyContent: 'center',
}));

export const CardInner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const LocationName = styled(Ty)(() => ({
  fontSize: '1.5rem',
  wordWrap: 'break-word',
  textAlign: 'center'
}));

export const Explore = styled(Ty)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const Logo = styled('img')({
  margin: '50px auto 15vh auto',
  position: 'relative'
});
