import React from 'react';
import {
  Box,
} from '@languageconvo/wcl';

// min height of the student section and lesson info cards
export const heightStudentLsnInfo = '370px';

// small dot which indicates whether a student is online (green) or offline (gray)
interface Props {
  isOnline: boolean;
}
export const StudentOnlineIndicator = ({ isOnline }: Props) => (
  <Box
    sx={{
    // minWidth is needed in case the user has a long name that overflows. in that case
    // the online status color gets "squished" if we don't sent minWidth. we also set
    // minHeight just to be congruent
      minWidth: '10px',
      width: '10px',
      minHeight: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: isOnline ? '#137547' : '#eff1ed',
      marginRight: '8px',
    }}
  />
);
