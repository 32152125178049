import { validateLocalData } from '../../../pages/group/classroom/context/VonageProviderHelper';
import { getTchSettingsFromLCStorage, setTchSettingsToLCStorage } from '../../../utils/lcStorage';
import { GroupLesson, LcTchSettings } from '../../../utils/lcStorageInterface';

// reading mic data form localStorage (mic id)
export const GetMicIDFromLCStorage = () => {
  // need to set audio device here.
  const localData: LcTchSettings = getTchSettingsFromLCStorage();
  // validating the groupLesson object type via ZOD.
  const validGroupLessonData: GroupLesson = validateLocalData(localData.groupLesson);
  return validGroupLessonData.micId;
};

// this function will update the current mic id in the local storage 
export const UpdateMicIDInLocalStorage = (micID: string | null) => {
  // It is necessary to get lcaolstorage values again bcz there are chances, some other part
  // of app may override some values inside localStorage while this function in executing.
  const existingValues = getTchSettingsFromLCStorage();
  const newSettings = {
    ...existingValues,
    groupLesson: {
      ...existingValues.groupLesson,
      micId: micID,
    }
  };
    // storing the default audio device into local storage for future use.
  setTchSettingsToLCStorage(newSettings);
};
