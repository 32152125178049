import React, { useState } from 'react';
import {
  Alert, TextField, Button,
  Grid2, Link, Ty
} from '@languageconvo/wcl';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// Mutation...
import { useMutation } from 'react-relay';
import { ResetPassword } from './relay/ResetPassword';
import Page from '../../components/pagewrapper/Page';

const zxcvbn = require('zxcvbn');

const ForgotPWD = () => {
  const [ResetPasswordMutation] = useMutation(ResetPassword);
  // to managing routes.
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // to manage button loading.
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  // enable, disable submit button.
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isStrongPwd, setIsStrongPwd] = useState(false);
  // show or hide alert and button states
  const [successResponse, setSuccessResponse] = useState(false);

  // starting: alert states
  const [alertText, setAlertText] = useState('');
  const [alertSeverity, setSlertSeverity] = useState<'info' | 'success' | 'error' | 'warning' | undefined>(undefined);
  // ending: alert states

  const onPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    // validating user typed password...
    validatePassword(event.target.value);
  };

  const validatePassword = (pwd: string) => {
    const res = zxcvbn(pwd);
    if (res.score < 3) {
      // setSlertSeverity('warning');
      // setAlertText('Password is not \'strong\' enough, try adding more letters,
      // numbers, or symbols.');
      setIsStrongPwd(true); // enable error state from password field.
      setDisableSubmit(true); // this will disable to submit button.
      return false;
    }
    setIsStrongPwd(false); // disable error state from password field.
    setDisableSubmit(false); // this will enable to submit button.
    setSlertSeverity(undefined); // this will hide the alert of week password warning.
    return true;
  };

  const handlePwdReset = () => {
    if (validatePassword(password)) {
      setLoading(true);
      ResetPasswordMutation({
        variables: { newPassword: password, token: searchParams.get('token') || '' },
        onCompleted(res: any) {
          const { resources_auth_password_reset } = res;
          if (resources_auth_password_reset?.success) {
            setLoading(false);
            setSlertSeverity('success');
            setAlertText('Great, you\'ve successfully set a new password! Head back to the Log In page to log in using that new password.');
            setSuccessResponse(true); // this will hide input and submit button also top into alert.
          }
        },
        onError
      });
    }
  };

  const onError = (data: any) => {
    setLoading(false);
    switch (data[0].extensions.code) {
      case 'PasswordBreached':
        toast.error(`Big problem! The password you tried to set has been leaked online, according 
          to security experts. You cannot use it for our website and you should not use it on any 
          other websites either, it has been stolen by hackers.`);
        setPassword(''); // need to reset the password.
        return;
      case 'InvalidOrExpiredToken':
      default:
        setSlertSeverity('error');
        setAlertText(`Error - it looks like the password reset link expired (for security purposes, 
          it expires after some time). Please click the button below to go request a new reset link. 
          If you continue to receive this error after trying with a new link, please let our team know.`);
        setSuccessResponse(true); // this will hide input and submit button also top into alert.
    }
  };

  return (
    <Page title="Password Reset" dataTestId="page-updatepassword_pwdreset">
      {/* default value of excludelogoheight is 120 in styled componnet but in some cases like
        here in reset password page, content seems little low that's why instead of negative margin
        we can increase the height and move content a bit above. */}
      <Grid2 display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Grid2 xs={10}>
          <Grid2>
            {!successResponse && (
            <Alert severity="info">Set a new password below; there are no specific rules,
              but it must be considered &apos;strong&apos;.
              After setting it, return to the Log In page to log in using your new password!
            </Alert>
            )}
            {alertSeverity !== undefined && <Alert severity={alertSeverity}>{ alertText }</Alert>}
          </Grid2>
          {!successResponse && (
          <Grid2 marginTop="20px">
            <TextField
              id="reset-email"
              inputCp={{ 'data-testid': 'textfield-password' }}
              type="password"
              label="Enter New Password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onPwdChange(e)}
              error={isStrongPwd}
              helpertext={isStrongPwd ? `Password is not 'strong' enough, try 
                adding more letters, numbers, or symbols.` : null}
            />
            <Grid2 marginTop="10px">
              <Button
                fullWidth
                onClick={handlePwdReset}
                disabled={disableSubmit}
                isLoading={loading}
                dataTestId="button-submit"
              >
                Submit
              </Button>
            </Grid2>
          </Grid2>
          )}
          {successResponse && (
          <Grid2 marginTop="20px">
            <Button
              dataTestId="button-successorfailure"
              size="large"
              fullWidth
              onClick={alertSeverity === 'success'
                ? () => navigate('/login')
                : () => navigate('/pwdforgot')}
            >
              {alertSeverity === 'success' ? 'Go To Log In Page' : 'Try Again'}
            </Button>
          </Grid2>
          )}
          {!successResponse && (
          <Grid2 marginTop="25px">
            <Ty align="center">
              <Link onClick={() => navigate('/login')} cp={{ 'data-testid': 'link-backtologin' }}>Back to Log In</Link>
            </Ty>
          </Grid2>
          )}
        </Grid2>

      </Grid2>
    </Page>
  );
};

export default ForgotPWD;
