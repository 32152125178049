import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Grid2, Grid2Ct, Ty, Ic,
} from '@languageconvo/wcl';

interface Props {
  contents: number
}

// builds the html contents of the modal
export const Modalview2Contents = ({ contents } : Props) => {
  if (contents === 0) {
    return (<div />);
  }

  // #region 90x  general errors, multi-use

  // 900, general error that can be used for any situation. prompts user to reload the page
  if (contents === 900) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like something might have gone wrong. If so, please try refreshing the
          page. Also, if you continue seeing this error <strong>please</strong> let us know so
          that we can fix it. Thank you!
        </Ty>
      </InnerContainer>
    );
  }

  // user does not seem to have any mics available
  if (contents === 901) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like your microphone got unplugged, and we could not find another one to
          use. Please check your mic, is it plugged in? If so, you may want to try plugging
          it in to a different port. If all else fails please refresh the page, that often
          fixes the problem!
        </Ty>
      </InnerContainer>
    );
  }

  // user does not seem to have any cams available
  if (contents === 902) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like your camera got unplugged, and we could not find another one to
          use. Please check your camera, is it plugged in? If so, you may want to try plugging
          it in to a different port. If all else fails please refresh the page, that often
          fixes the problem!
        </Ty>
      </InnerContainer>
    );
  }

  // #endregion

  // #region 92x  session.connect error messages

  // OT_CONNECT_FAILED, OT_NOT_CONNECTED
  if (contents === 920) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          We had a problem connecting you to the class. It looks like your internet connection
          went down for a moment. Please try refreshing the page if the classroom is not working.
        </Ty>
      </InnerContainer>
    );
  }

  // OT_UNSUPPORTED_BROWSER
  if (contents === 921) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          Are you using an updated version of Google Chrome?
          If not, our classroom is primarily designed to support Chrome, and you may have problems
          (like this one!) if you  use a different browser.
          <br /><br />
          If you are using Chrome and seeing this, a browser extension or antivirus may be causing
          a problem. Try using Google Chrome in Incognito mode, and if that works the problem is
          very likely due to a browser extension you have installed.
        </Ty>
      </InnerContainer>
    );
  }

  // #endregion

  // #region 93x  session.publish error messages

  // OT_HARDWARE_UNAVAILABLE, OT_NO_DEVICES_FOUND, OT_USER_MEDIA_ACCESS_DENIED,
  // OT_CHROME_MICROPHONE_ACQUISITION_ERROR
  if (contents === 930) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like we might have had a problem accessing your camera or microphone, so students
          may not be able to hear or see you.
          <br /><br />
          Please check to make sure your camera and mic are plugged in, then try reloading the
          page; that usually fixes most problems!
        </Ty>
      </InnerContainer>
    );
  }

  // OT_MEDIA_ERR_NETWORK, OT_NOT_CONNECTED
  if (contents === 931) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          While we were connecting you to the class, it looks like your internet connection
          may have dropped. We recommend refreshing the page to ensure students can hear you.
        </Ty>
      </InnerContainer>
    );
  }

  // general publish error
  if (contents === 932) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like there may have been a problem connecting you to the class.
          We recommend refreshing the page to ensure students can hear you.
        </Ty>
      </InnerContainer>
    );
  }

  // #endregion

  // #region 94x  initPublisher error messages

  // OT_HARDWARE_UNAVAILABLE, OT_NOT_SUPPORTED, OT_NO_DEVICES_FOUND,
  // OT_REQUESTED_DEVICE_PERMISSION_DENIED
  if (contents === 940) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like we had a problem connecting to your microphone or camera. So, students
          might not be able to hear or see you. Ensure your microphone and camera are
          plugged in and then try refreshing the page.
        </Ty>
      </InnerContainer>
    );
  }

  // general publish error
  if (contents === 941) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          It looks like there might have been a problem connecting you to the class.
          Please try refreshing the page.
        </Ty>
      </InnerContainer>
    );
  }

  // #endregion

  // #region 95x  user clock incorrect

  // large difference detected between user's browser time and what our server said the time is
  if (contents === 950) {
    return (
      <InnerContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;Potential Problem</Ty>
        <Ty>
          Important: please refresh the page once now. If you see this error a second
          time, ignore it and continue the class.
          <br /><br />
          We detected a large difference between the time your computer says it is now, and the time
          our software thinks it is.
          Your computer&apos;s clock might be incorrect. It could also be the case that this page
          took a long time to load, in which case there is no problem. Please look at your computer
          clock though and ensure it is correct; this is important for you being able to enter
          classes at the correct start time.
          <br /><br />
          If your computer&apos;s clock is correct you should *rarely* see this error; only in rare
          cases of the page loading very slowly due to a website problem should you see this. If
          you see it frequently PLEASE let us know!
        </Ty>
      </InnerContainer>
    );
  }
  // #endregion

  // log error, should not happen
  // eslint-disable-next-line no-console
  console.log(`Modalview2Contents got an unknown value for contents: ${contents}`);
  Sentry.captureException(
    new Error('Modalview2Contents got an unknown value for contents. Needs to be fixed'),
    {
      extra: {
        dt: contents,
      }
    }
  );
  return (<div />);
};

const InnerContainer = ({ children }: any) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      {children}
    </Grid2>
  </Grid2Ct>
);
