import React from 'react';
import * as Sentry from '@sentry/react';

/** If the app errors before it gets to the router, this is what we will show.
 * Note that it's very possible our ThemeProvider component has not yet been loaded,
 * so we cannot use wcl components! This has to be very basic. You can test it by
 * throwing an error in any of the components that load in App.tsx before the router
*/
export const AppLevelError = ({ e }: any) => {
  Sentry.captureMessage('App level error occurred!');
  Sentry.captureException(e);

  return (
    <div style={{
      width: '90%', margin: 'auto', textAlign: 'center', fontFamily: 'sans-serif'
    }}
    >
      <br />
      <br />
      <br />
      <h1>Oops, a major error occurred.</h1>
      <br />
      <p>
        Please refresh the page.
      </p>
      <p>
        We have notified our software team about the problem
        but please let us know if you continue to see this page.
      </p>
    </div>
  );
};
