import React, { useState, useEffect } from 'react';
import {
  Dialog, Ty, Select, Button, SelectChangeEvent, Grid2,
  Grid2Ct, CardSmallColor, IcSvgList,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { useUpdateRoomLayout } from '../../hooks/useUpdateRoomLayout';
import { optionsArray } from '../../utils/optionsArray';
import { QueryDataRoomLayoutFragment } from '../../relay/QueryData';
import { QueryDataRoomLayoutFragment$key } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';
import { CustomMutationDataLessonDetailFragment } from '../../relay/CustomMutationData';
import { CustomMutationDataLessonDetailFragment$key } from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';

interface Props {
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key,
  layoutFragmentRef: QueryDataRoomLayoutFragment$key;
}

// Hold info related to whom teacher is currently speaking to
export const ClassControlsSpeakingTo = ({
  classroomDataFragmentRef,
  layoutFragmentRef
}: Props) => {
  // #region mutate teacherStreamingTo value inside room layout data

  /**
   * there are multiple places from where we are mutating room layout data, so
   * instead of duplicating same code, we creatd a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { loading, mutateRoomLayout } = useUpdateRoomLayout();

  // if the teacher clicks the "save" button to change who they are speaking to, this
  // mutates the room layout data teacherStreamingTo value
  const mutateTeacherSpeakingTo = (uuid: string, roomLayoutHash: any) => {
    // Parse the roomLayoutHash into a JSON object
    const data = roomLayoutHash;
    // Create an updatedHash based on the tchSpeakingTo By the teacher
    const updatedHash = { ...data, teacherStreamingTo: [JSON.parse(tchSpeakingTo)] };
    // Call the mutateRoomLayout function from the hook to update the room layout  
    handleClose();
    mutateRoomLayout(uuid, updatedHash);
  };

  // #endregion

  // #region reading data from fragments to decide to whom teacher is speaking to

  const lessonDetailsData = useFragment(
    CustomMutationDataLessonDetailFragment,
    classroomDataFragmentRef,
  );
  const roomLayoutData:any = useFragment(QueryDataRoomLayoutFragment, layoutFragmentRef);
  const layoutData = JSON.parse(roomLayoutData.appt_group_layout.layout);
  const initialValue = JSON.stringify(layoutData.teacherStreamingTo[0]);
  useEffect(() => {
    setTchSpeakingTo(initialValue);
  }, [initialValue]);

  // option array's of object in which we are showing teacher speaking to based on our 
  // roomLayoutData
  const options = optionsArray(lessonDetailsData, layoutData);

  // #endregion

  // #region dialog click events (expect for "save" button click)

  // handling click events for Select Component 
  // this handlClick is reponsible for setting to whom teacher is speaking to from the dropdonw.
  const [anchorElForSpeaking, setAnchorElForSpeaking] = useState<HTMLButtonElement |
    null>(null);
  const handleClickForSpeaking = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // preventing onClick to auto reload
    setAnchorElForSpeaking(event.currentTarget);
  };

  // handleClose for speaking to popup 
  const handleClose = () => {
    setAnchorElForSpeaking(null);
  };

  // state for selected option by the teacher (TeacherListeningTo)
  const [tchSpeakingTo, setTchSpeakingTo] = useState<string>(initialValue);
  // this handleChange is reponsible for updating the selected value 
  // which is selected by teacher from Select component 
  const handleChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    const teachertchSpeakingTo: string = event.target.value;
    setTchSpeakingTo(teachertchSpeakingTo);
  };

  // #endregion

  // #region small card display

  // icon
  const [theIcon, setTheIcon] = useState('IcSvgList.group1');
  useEffect(() => {
    const theIc = getIcon(layoutData);
    setTheIcon(theIc);
  }, [layoutData]);

  // text
  const [theText, setTheText] = useState<string>('');
  useEffect(() => {
    if (loading) {
      setTheText('Switching...');
    } else {
      const theVal = getSpeakingToTextToShowOnButton(layoutData, lessonDetailsData);
      setTheText(theVal);
    }
  }, [loading, layoutData, lessonDetailsData]);

  // #endregion

  return (
    <>
      {/* button */}
      <CardSmallColor
        smallerfont
        icon={theIcon}
        text={theText}
        color="accentPurple1"
        hovercursor="pointer"
        onClick={(event) => handleClickForSpeaking(event)}
      />

      {/* modal */}
      <Dialog
        isOpen={Boolean(anchorElForSpeaking)}
        onClose={handleClose}
        width="xs"
        color="accentPurple1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="p" align="center">
              Select who you want to speak to:
            </Ty>
            <Select label="Speak To" value={tchSpeakingTo} options={options} onChange={handleChange} cp={{ sx: { mt: 2 } }} />
            <Button
              fullWidth
              color="accentPurple1"
              onClick={() => mutateTeacherSpeakingTo(lessonDetailsData.lessonUuid, layoutData)}
              disabled={loading}
              cp={{ sx: { mt: 1 } }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// #region helper functions

// determine the text to display on the speaking-to button 
const getSpeakingToTextToShowOnButton = (roomLayoutData: any, initialPageLoadData: any) => {
  const streamingTo = roomLayoutData.teacherStreamingTo[0];
  const { studentGroupRooms } = initialPageLoadData.av;
  // default teacher only mode.
  let speakingTo = 'Everyone';
  // group chat mode.
  if (streamingTo.entity === 2) {
    if (streamingTo.value === studentGroupRooms[0].uuid) {
      speakingTo = 'Group 1';
    }
    if (streamingTo.value === studentGroupRooms[1].uuid) {
      speakingTo = 'Group 2';
    }
  }
  // speaking to nobody.
  if (streamingTo.entity === 4) {
    speakingTo = 'Nobody';
  }

  return speakingTo;
};

// get the icon for the speaking to card
const getIcon = (roomLayoutData: any) => {
  const streamingTo = roomLayoutData.teacherStreamingTo[0];
  // default: lecture
  let ret = IcSvgList.desk1;

  // streaming to one of the groups
  if (streamingTo.entity === 2) {
    ret = IcSvgList.group1;
  }

  if (streamingTo.entity === 4) {
    ret = IcSvgList.cancel2;
  }
  return ret;
};

// #endregion
