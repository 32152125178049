/* eslint-disable */
/* configuring our network layer via Relay environment which is a singleton instance.
we are using this instance in mutations and RelayEnvironmentProvider (which tells child
components how to talk to the server via this instance)

NOTE: cannot convert this to functional component as it is not fulfiling the react
functional component requirements (i-e not returning JSX etc). */

import {
  RequestParameters,
  Variables,
  Environment,
  Network,
  Store,
  Observable,
  RecordSource,
} from 'relay-runtime';

import { createClient } from 'graphql-ws';
import { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';
import { getDataToLCStorage } from '../utils/lcStorage';
import { getTimeValue } from '../utils';

// this helper function call our fetchGraphQL utility with params.text and variables.
// Relay passes a "params" object with the query name and text.
async function fetchRelay(params: RequestParameters, variables: Variables) {
  const BASE_API_URL = `${process.env.REACT_APP_BASE_API}`;
  const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');
  const requestTimeout = getTimeValue();
  let jsonResponse: any = {};
  try {
    // we wil abort the request if it takes more than 5 seconds.
    const c = new AbortController();
    const id = setTimeout(() => c.abort(), requestTimeout);
    const response = await fetch(BASE_API_URL, {
      signal: c.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedSecurityValues?.jwt}`,
      },
      body: JSON.stringify({
        query: params.text,
        variables,
      }),
    });
    clearTimeout(id);
    jsonResponse = await response.json();
  } catch (err: any) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      throw new Error('request-timeout');
    } else {
      throw new Error(err?.message || 'Something went wrong.');
    }
  }
  /**
   * In case of exceptions (for example, a missing required variable) in the "errors"
   * property of the response. If any exceptions occurred when processing the request,
   * throw an error to indicate to the developer what went wrong.
   * */
  if (Array.isArray(jsonResponse.errors)) {
    if (jsonResponse.errors[0]?.extensions?.code === 'invalid-jwt') {
      // going to throw custom error which we are catching in 'page level error boundary'
      // and displaying user friendly error messages.
      throw new Error('invalid-jwt');
    } else {
      // eslint-disable-next-line
      if (params.operationKind === 'mutation') {
        throw new Error(
          JSON.stringify(jsonResponse.errors[0])
        );
      } else {
        throw new Error(
          `Error fetching GraphQL query '${
            params.name
          }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
            jsonResponse.errors
          )}`
        );
      }
    }
  }
  return jsonResponse;
}

const subscriptionURL = `${process.env.REACT_APP_SUBSCRIPTION_URL}`;

// First step is to create web socket client which will connect to our backend
// using the recommended plugin graphql-ws.
const subscriptionsClient = createClient({
  url: subscriptionURL,
  connectionParams: () => {
    const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');

    return {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${storedSecurityValues?.jwt}`,
      }
    };
  },
  // shouldRetry works with retryAttempts, if enabled: client will keep retrying to
  // the retryAttempts value
  shouldRetry: () => true,
  // default retryAttempt value is 5. We are updating it to 2000
  retryAttempts: 2000, // for now, 2000 retries.
  // retryWait: control the wait time between retries, instead of default randomised
  // exponential value we can add retry after every 2 seconds.
  retryWait: async function waitForServerHealthyBeforeRetry(retries: number) {
    /* eslint-disable-next-line no-console */
    console.log('no of retries', retries);
    // todo: will add logic based on retries...
    // eslint-disable-next-line
    await new Promise((resolve) => setTimeout(resolve, 1000 * 2));
  },
  // debug
  on: {
    connecting: () => {
      // eslint-disable-next-line
      console.log('[graphql-ws] connecting');
    },
    connected: () => {
      // eslint-disable-next-line
      console.log('[graphql-ws] connected');
    },
    closed: (closeEvent: any) => {
      // eslint-disable-next-line
      console.log('[graphql-ws] closed', {
        closeEvent,
        code: closeEvent.code,
        reason: closeEvent.reason,
      });
    },
    opened: () => {
      // eslint-disable-next-line
      console.log('[graphql-ws] opened ');
    },
    ping: () => {
      // eslint-disable-next-line
      // console.log('[graphql-ws] ping ');
    },
    pong: () => {
      // eslint-disable-next-line
      // console.log('[graphql-ws] pong ');
    },
    message: () => {
      // eslint-disable-next-line
      // console.log('[graphql-ws] message ');
    },
    error: () => {
      // eslint-disable-next-line
      console.log('[graphql-ws] error ');
    },
  },
});


// operation: relay injected this obj which contains subscription name, text etc.
// variables: relay injected these variables.
// 2nd step is to create observable callback which will use the above web
// socket client and subscribed/listen to changes.
const fetchSubscribe = (operation: any, variables: Variables) => Observable.create((sink) => {
  /*
    A Sink is an object of methods provided by Observable during construction.
    Sink have 3 callback methods.
    Next: executed when a subscription payload is received
    Error: executed when the subscription errors occures
    Complete: executed when the server ends the subscription
  */
  if (!operation.text) {
    return sink.error(new Error('Operation text cannot be empty'));
  }
  return subscriptionsClient.subscribe(
    {
      operationName: operation.name,
      query: operation.text,
      variables,
    },
    {
      ...sink,
      error: (err: any) => {
        if (Array.isArray(err)) {
          // GraphQLError[]
          return sink.error(
            new Error(err.map(({ message }) => message).join(', ')),
          );
        }

        if (err instanceof CloseEvent) {
          return sink.error(
            new Error(
              `Socket closed with event ${err.code} ${err.reason || ''}`, // reason will be available on clean closes only
            ),
          );
        }

        return sink.error(err);
      },
    }
  );
}) as RelayObservable<any>;

// creating a singleton instance of Relay Environment
const AppEnvironment = new Environment({
  // registering the fetchRelay and fetchSubscribe callbacks in Network Layer.
  network: Network.create(fetchRelay, fetchSubscribe),
  store: new Store(new RecordSource(), {
    /**
     * This property tells Relay to not immediately clear its cache when the user
     * navigates around the app. Relay will hold onto the specified number of
     * query results, allowing the user to return to recently visited pages
     * and reusing cached data if its available/fresh.
     */
    gcReleaseBufferSize: 10,
  }),
});
export default AppEnvironment;
