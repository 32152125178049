export interface Props {
  width: string;
  height: string;
  // eslint-disable-next-line no-use-before-define
  icon: IcSvgList;
  /** Do NOT use this in production! It is used for testing in storybook; setting this to true will cause
   * the icon to be loaded directly from our root s3 url instead of our cdn url. This is to test that the
   * file does exist in the root s3 bucket. If a file is removed from s3, the cdn will still serve it, and
   * so this property allows us to test to ensure the actual root file does exist.
   */
  devtestingRooturl?: boolean;
}

// list of all svg icons
// the RIGHT side, the string, MUST be exactly equal to a filename in the
// the cdn repo, /images/wcl/svgicons folder. this allows you to easily version files
// without breaking the api. for example, you could edit the 'mailheart' svg and then
// save it as a new file in the cdn: 'mailheart-v3.svg'. below, simply edit the string
// for the mailheart svg to 'mailheart-v4'
// VERY IMPORTANT!
// Our icons come from flaticon.com, author = freepik. The style is called "Kawaii Flat"
// https://www.flaticon.com/authors/kawaii/flat?author_id=1
// https://www.flaticon.com/authors/freepik
// EDITING: you can edit icon colors fairly easily using iconscout, and trim using inkscape,
//  which is a free/open source svg editor
export enum IcSvgList {
  arrowleft1 = 'arrowleft1',
  book1 = 'book1',
  calendar1_accentOrange1 = 'calendar1_accentOrange1',
  calendar1 = 'calendar1',
  calendar2 = 'calendar2',
  calendar3 = 'calendar3',
  calendar4 = 'calendar4',
  calendar5 = 'calendar5',
  cancel1 = 'cancel1',
  cancel2 = 'cancel2',
  clock1 = 'clock1',
  customersupport1 = 'customersupport1',
  cursing1 = 'cursing1',
  desk1 = 'desk1',
  earth1 = 'earth1',
  exit1 = 'exit1',
  folder1 = 'folder1',
  flagarabnation1 = 'flagarabnation1_v2',
  flagchina1 = 'flagchina1',
  flagfrance1 = 'flagfrance1',
  flaggerman1 = 'flaggerman1',
  flagitaly1 = 'flagitaly1',
  flaglatin1 = 'flaglatin1_v2',
  flagjapan1 = 'flagjapan1_v2',
  flagportugal1 = 'flagportugal1_v2',
  flagrussia1 = 'flagrussia1_v2',
  flagspain1 = 'flagspain1_v2',
  gift1 = 'gift1',
  graduationcap1 = 'graduationcap1',
  group1 = 'group1',
  knowledge1 = 'knowledge1',
  knowledge2 = 'knowledge2',
  list1 = 'list1',
  mail1 = 'mail1',
  mailheart1 = 'mailheart1',
  microphone1 = 'microphone1',
  microphone2 = 'microphone2',
  microphone2off = 'microphone2off',
  monitor1 = 'monitor1',
  music1 = 'music1',
  purchase1 = 'purchase1',
  settings1 = 'settings1',
  soundmixer1 = 'soundmixer1_v2',
  sun1 = 'sun1_v2',
  sun2 = 'sun2',
  sun3 = 'sun3_v2',
  star1 = 'star1-v2',
  teacher1 = 'teacher1',
  teacher2 = 'teacher2',
  teacher3 = 'teacher3',
  trafficcone1_accentOrange1 = 'trafficcone1_accentOrange1',
  trafficcone1 = 'trafficcone1-v2',
  watch1 = 'watch1',
  webcam1 = 'webcam1',
  question1 = 'question1',
}
