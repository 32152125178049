/**
 * @generated SignedSource<<4d4bbab6c05967fe6b95dc79a8861eab>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetPasswordMutation$variables = {
  newPassword: string;
  token: string;
};
export type ResetPasswordMutation$data = {
  readonly resources_auth_password_reset: {
    readonly success: boolean;
  } | null;
};
export type ResetPasswordMutation = {
  response: ResetPasswordMutation$data;
  variables: ResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newPassword"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "newPassword",
        "variableName": "newPassword"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "ResourcesAuthPasswordResetOutput",
    "kind": "LinkedField",
    "name": "resources_auth_password_reset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a0d390eac7fa47cb17d8286de0af6bf6",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordMutation(\n  $newPassword: String!\n  $token: String!\n) {\n  resources_auth_password_reset(newPassword: $newPassword, token: $token) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa3377336cf9917c94f6184ed4555e4d";

export default node;
