import compNavbar_DE from './compNavbar.json';
import pageHome_DE from './pageHome.json';
import pageEarningsHistory_DE from './pageEarningsHistory.json';
import pageSetting_DE from './pageSetting.json';
import accountPopover_DE from './accountPopover.json';
import languagePopover_DE from './languagePopover.json';
import groupLessonLevel_DE from './groupLessonLevel.json';

export default {
  compNavbar: compNavbar_DE,
  pageHome: pageHome_DE,
  pageEarningsHistory: pageEarningsHistory_DE,
  pageSetting: pageSetting_DE,
  accountPopover: accountPopover_DE,
  languagePopover: languagePopover_DE,
  groupLessonLevel: groupLessonLevel_DE
};
