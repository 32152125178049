import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ListItemStyleParent,
  ListItemTextStyleParent,
  ListItemIconStyle,
  ListItemSvgStyle
} from './style';

// list item for parent/header, the main "sections" of the navbar: Home, Schedule, Purchase, etc.
// note that for example Home does not have any children pages, while other sections like
// Purchase do have child pages, so this must handle each of those situations
export const NavbarInnerListItemParent = ({
  item, active, isCollapse
}: any) => {
  const {
    title, path, icon
  } = item;

  const { t } = useTranslation('compNavbar');

  // builds the inner item text
  const renderContent = (
    <>
      {
        icon && (
        <ListItemIconStyle>
          <ListItemSvgStyle src={icon} />
        </ListItemIconStyle>
        )
      }
      <ListItemTextStyleParent
        disableTypography
        primary={t(`${title.toLowerCase()}.title`)}
        isCollapse={isCollapse}
      />
    </>
  );

  return (
    <ListItemStyleParent
      component={RouterLink}
      to={path}
      activeRoot={active}
      isCollapse={isCollapse}
    >
      {renderContent}
    </ListItemStyleParent>
  );
};
