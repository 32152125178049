import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { Step4ClassroomLayout } from './Step4ClassroomLayout';
import { QueryDataQuery } from '../../relay/QueryData';
import { QueryDataRoomLayoutFragment$key } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';
import { QueryDataLessonDetailsFragment$key } from '../../relay/__generated__/QueryDataLessonDetailsFragment.graphql';
import { CustomMutationDataLessonDetailFragment$key } from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';

interface Props {
  setMainView: any;
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  uuid: string;
  setmodalviewContents: any;
  setmodalviewState: any;
}
/**
 * Getting the room layout data this is main data based on this data change, we notify 
 * students and move them into groups etc.
 */
export const Step3RegularQueryData = ({
  setMainView,
  classroomDataFragmentRef,
  uuid,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // #region for query

  // data we can get from a regular query
  // note that if any error occurs, our custom error boundary will hit
  const response: any = useLazyLoadQuery(
    QueryDataQuery,
    { lessonUuid: uuid }
  );
  const roomLayoutFragment: QueryDataRoomLayoutFragment$key = response
    .appt_group_connection.edges[0].node;
  const lessonDetailsFragment: QueryDataLessonDetailsFragment$key = response
    .appt_group_connection.edges[0].node;

  // #endregion

  return (roomLayoutFragment !== null
    ? (
      <Step4ClassroomLayout
        setMainView={setMainView}
        classroomDataFragmentRef={classroomDataFragmentRef}
        layoutFragmentRef={roomLayoutFragment!}
        lessonDetailsFragmentRef={lessonDetailsFragment}
        setmodalviewContents={setmodalviewContents}
        setmodalviewState={setmodalviewState}
      />
    )
    : null
  );
};
