import React from 'react';
import {
  Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { Welcome } from './components/Welcome';

const Home = () => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <Welcome />
    </Grid2>
  </Grid2Ct>
);

export default Home;
