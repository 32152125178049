import { Publisher, Session } from '@opentok/client';
import { Dispatch, ReactNode, SetStateAction } from 'react';

export type VonageProviderProps = {
  children: ReactNode;
  setmodalviewContents: any;
  setmodalviewState: any;
};

// #region layout data

// the roomView key of layout data. it denotes whether we are in lecture mode or in group chat
// mode
export enum RoomViewTy {
  Lecture = 1,
  GroupChat = 2,
}

// #endregion

// #region teacher stream

type TeacherStream = {
  // state for our publisher in the begining it's undefined 
  videoPublisher: Publisher | undefined;
  // starts publishing teacher audio/vido stream if vonage session is valid and connected.
  publishVideoStream: (_arg1: any, _arg2: any, _arg3: RoomViewTy) => void;
  // subscribing to students streams in case of group chat mode.
  subscribeToStream: () => void;
  // handling teacher screen sharing.
  isTeacherScreensharing: boolean;
  // publishing teacher screen sharing stream to students.
  publishScreenSharingStream: () => void;
  // stoping teacher screen sharing stream to students.
  stopScreenSharingStream: () => void;
  // responsible to destroy old session and connect to new session with new creds.
  disconnectFromSessionAndConnectToNewSession: (_arg1: {
    sessionId: string,
    token: string,
  }) => void;
}

// the name we give to the stream that the teacher publishes. uis will need to listen for these
// to display the teacher's stream correctly
export enum TeacherStreamNameTy {
  TeacherStreamLecture = 'TeacherStreamLecture',
  TeacherStreamGroup = 'TeacherStreamGroup',
}

// #endregion

// hold previous session info, required for comparison while connecting to new session
export type PreviousRoomLayoutDataType = {
  roomView: number;
  teacherStreamingTo: [{
    entity: number,
    value: string | null,
  }]
}

// audio/video devices
type AVDeviceType = {
  // responsible for asking camera and microphone permissions on page load.
  demoPublish: () => void;
  // state to track whether access of mic/camer has been granted or not
  accessGranted: boolean;
  // track if Vonage has been initialized for the first time after permission granted.
  vonageFirstInitialized: boolean;
}

type PreviousVonageRoomInfo = {
  // hold previous session info, required for comparison while connecting to new session
  previousRoomLayoutData?: PreviousRoomLayoutDataType;
  setPreviousRoomLayoutData?: Dispatch<SetStateAction<PreviousRoomLayoutDataType | undefined>>;
}

type VonageSessions = {
  // responsible to intialize vonage session.
  initializeSession: (_arg1: string) => any;
  // responsible to connect vonage session after successful initialization.
  connectToSession: (_arg1: string) => void;
  vonageSession: Session | undefined;
  // upon successful connection, this is our flag which is giving us go ahead to 
  // publish or subscribe to stream.
  sessionConnected: boolean;
  // on lesson end, this will disconnect from vonage session and reset everything state.
  disconnect: () => void;
}

type ToggleMicState = {
  // responsible to mute publisher microphone
  muteMicrophone: () => void;
  // responsible to unmute publisher microphone
  unMuteMicrophone: () => void;
}

type ToggleCameraState = {
  // responsible to turnOff publisher camera video
  turnCameraOff: () => void;
  // responsible to turnON publisher camera video
  turnCameraON: () => void;
}

// Combine all types here by & sign.
export type VonageProviderInitialStateType = AVDeviceType &
  VonageSessions & TeacherStream & PreviousVonageRoomInfo
  & ToggleMicState & ToggleCameraState;
