import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField, Button, Checkbox, Alert, Popover,
  FormControlLabel, Link, Grid2, Ty, Card, Box, Ic,
  Grid2Ct,
} from '@languageconvo/wcl';
import { toast } from 'react-toastify';
import React, {
  ChangeEvent,
  useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import { emailProps, emailValidationSchema } from '../../schema/emailChange-schema';
import { passCodeProps, passCodeValidationSchema } from '../../schema/passCodeChange-schema';
import Page from '../../components/pagewrapper/Page';

// Mutation...
import { GenerateLoginLinkOrCode } from './relay/GenerateLoginLinkOrCode';
import { VerifyPasscodeMutation } from './relay/VerifyPasscode';
import { AuthLoginPassword } from './relay/AuthLoginPassword';

import useAuth from '../../hooks/useAuth';
import { setCookieForCurrentUser } from '../../utils/setUserState';
import { getDataToLCStorage, setDataToLCStorage } from '../../utils/lcStorage';

const Login = () => {
  const [LoginPasswordMutation] = useMutation(AuthLoginPassword);
  const [GenerateLoginLinkOrCodeMutation] = useMutation(GenerateLoginLinkOrCode);
  const [VerifyPasscodeMutationMutation] = useMutation(VerifyPasscodeMutation);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [loginBtnLoading, setLoginBtnLoading] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [rememberMe, SetRememberMe] = useState<boolean>(false);
  const [showLoginCodeInfo, setShowLoginCodeInfo] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [passwordExist, setPasswordExist] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showEmailLinkPopover, setShowEmailLinkPopover] = useState<HTMLElement | null>(null);
  const [showEmailCodePopover, setShowEmailCodePopover] = useState<HTMLElement | null>(null);
  const [showResetPwdPopover, setShowResetPwdPopover] = useState<HTMLElement | null>(null);

  const [magicLinkSuccessContent, setMagicLinkSuccessContent] = useState<boolean>(false);
  // starting: alert states
  const [alertText, setAlertText] = useState<string>();
  const [alertSeverity, setAlertSeverity] = useState<'info' | 'success' | 'error' | 'warning' | undefined>();
  // ending: alert states

  const {
    control, handleSubmit, reset, setValue
  } = useForm <any>({
    resolver: yupResolver(showLoginCodeInfo ? passCodeValidationSchema : emailValidationSchema)
  });

  // after page render, check if "remember me" is checked, prefil the user saved email in user
  // input field and check the "remember me" checkbox.
  useEffect(() => {
    const storedValues = getDataToLCStorage('lc_tchsettings');
    // set default email if already present in localStorage...
    if (storedValues?.login && storedValues?.login?.rememberMeSettings) {
      setValue('email', storedValues.login.rememberMeSettings.email);
      SetRememberMe(storedValues.login.rememberMeSettings.rememberme);
    }
    setSessionEmail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showLoginCodeInfo) {
      setSessionEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoginCodeInfo]);

  const setSessionEmail = () => {
    const sessionEmail = sessionStorage.getItem('email');
    if (sessionEmail) {
      setValue('email', sessionEmail);
    }
  };

  const onPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordExist(true);
  };

  const handlePwdLogin = (data:emailProps) => {
    if (password.length < 1) {
      setPasswordExist(false);
      return;
    }
    // saveEmailToLCStorage will executes once teacher set's
    // his/her email. So, no need to check if email is present or not
    saveEmailToLCStorage(data);
    setLoginBtnLoading(true);
    LoginPasswordMutation({
      variables: { email: data.email, password },
      onCompleted(res: any) {
        // this is responsible for setting the loggedIn user info in auth context.
        setCookieForCurrentUser(res?.resources_auth_login_password, setAuth, 'lc_tchsecurity', navigate);
      },
      onError(err: any) {
        setLoginBtnLoading(false);
        setPassword(''); // need to reset the password.
        switch (err[0].extensions.code) {
          case 'PasswordNeedReset':
            toast.error(`Error - you need to reset your password. Click the 'Reset My Password' 
            link below to do that.`);
            return;
          case 'InvalidEmailPasswordCombination':
            toast.warning('Your email address or password were incorrect, please try again.', { toastId: 'data-testid_emailpwdwrong' });
            return;
          case 'ExternalApiFailure':
          default:
            toast.error(`Hmm, something went wrong, please try again. If this continues to happen 
              please let our team know; you can try the 'Email Link' and 'Email Code' log 
              in options, too.`);
        }
      }
    });
  };

  const handleLoginWithCode = (data: passCodeProps) => {
    // saveEmailToLCStorage will executes once teacher set's
    // his/her email. So, no need to check if email is present or not
    saveEmailToLCStorage({ email });
    VerifyPasscodeMutationMutation({
      variables: { email, passcode: data.passcode },
      onCompleted(res: any) {
        // this is responsible for setting the loggedIn user info in auth context.
        setCookieForCurrentUser(res?.resources_auth_verify_passcode, setAuth, 'lc_tchsecurity', navigate);
      },
      onError(err: any) {
        setLoading(false);
        switch (err[0].extensions.code) {
          case 'InvalidOrExpiredPasscode':
            toast.error('Passcode is either invalid or has already expired');
            return;
          default:
            toast.error('Hmm, something went wrong.');
        }
      }
    });
    setLoading(true);
    reset();
  };

  const saveEmailToLCStorage = (data: emailProps) => {
    const storedValues = getDataToLCStorage('lc_tchsettings');
    if (rememberMe) {
      // if rememberMe is checked, save these settings by preserving other login saved
      // values and save back again to localStorage...
      const loginNodes = {
        login: {
          rememberMeSettings: {
            rememberme: true,
            email: data.email
          }
        }
      };
      setDataToLCStorage('lc_tchsettings', loginNodes);
    } else if (storedValues?.login && storedValues?.login.rememberMeSettings) {
      // if rememberMe is un-checked, remove the stored values and save back again
      // to localStorage...
      delete storedValues.login.rememberMeSettings;
      setDataToLCStorage('lc_tchsettings', storedValues);
    }
  };

  const handleLoginWithCodeORMagicLink = (data:emailProps, loginType: number) => {
    // saveEmailToLCStorage will executes once teacher set's
    // his/her email. So, no need to check if email is present or not
    saveEmailToLCStorage(data);
    reset();
    // Same mutation is for email link and passcode, loginType will differentiate both...
    GenerateLoginLinkOrCodeMutation({
      variables: { email: data.email, loginType },
      onCompleted() { /* in future, we can make decisions */ },
      onError(err: any) { Sentry.captureException(err); }
    });
    if (loginType === 1) {
      // CASE: Email PassCode
      setShowLoginCodeInfo(true); // this will show passcode input and hide others.
      // need to save user email in react variable as need to send this email with
      // passcode in mutation.
      setEmail(data.email);
      setAlertSeverity('info');
      setAlertText('Check your email for a log in code, and enter that code below!');
      // forcefully un setting user email as need to show passCode input now.
      setValue('email', '');
    } else {
      // CASE: Email link
      // this will hide all other inputs and just show success alert and back link,
      setMagicLinkSuccessContent(true);
      setShowLoginCodeInfo(false);
    }
  };

  // this will execute on *back to login* button click.
  const resetStates = () => {
    setMagicLinkSuccessContent(false);
    setShowLoginCodeInfo(false);
    setSessionEmail();
    setAlertText('');
    setAlertSeverity(undefined);
  };

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    // onSubmit, if checkbox is checked and email is present, save email to lcoalStorage.
    SetRememberMe(event.target.checked);
  };

  // dont allow access to this pagein prod,
  if (process.env.REACT_APP_ENV === 'prod') {
    return (
      <Grid2Ct>
        <Grid2 xs={10} xsOffset={1}>
          <Ty>Go to UI Bakery to log in! Here&apos;s a link:</Ty><br />
          <Link
            color="accentPurple1"
            href="https://cloud.uibakery.io/"
          >
            UI Bakery
          </Link>
        </Grid2>
      </Grid2Ct>
    );
  }

  return (
    <Page title="Log In" dataTestId="page-login">
      {/* default value of excludelogoheight is 120 in styled componnet but in some cases like
        login with code case, content seems little low that's why instead of negative margin
        we can increase the height and move content a bit above. */}
      <Grid2 display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Grid2 xs={10} maxWidth="400px">
          {magicLinkSuccessContent && (
          // this is a success content which will display once user do a magic link request.
          <Grid2>
            <Grid2 marginBottom="10px">
              <Alert severity="success" cp={{ 'data-testid': 'alert-checkemailforlink' }}>Check your email for a link you can click to log in!
              </Alert>
            </Grid2>
            <Grid2 marginTop="25px">
              <Ty align="center">
                <Link cp={{ 'data-testid': 'link-emllinkbacktologin' }} onClick={resetStates}>Back to Log In</Link>
              </Ty>
            </Grid2>
          </Grid2>
          )}
          { showLoginCodeInfo
            ? (
          // login with code.
          // this content allow user to enter 6 digit verfication code and login via code.
              <Grid2>
                <Grid2>
                  <Grid2 marginBottom="10px">
                    <Alert severity={alertSeverity} cp={{ 'data-testid': 'alert-checkemailforcode' }}>{ alertText }</Alert>
                  </Grid2>
                  <Controller
                    name="passcode"
                    control={control}
                    render={
                  ({ field: { onChange, value }, fieldState: { error } }) => (

                    <TextField
                      id="passcode-login"
                      type="text"
                      label="Passcode"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helpertext={error ? error.message : null}
                    />
                  )
                }
                  />
                </Grid2>
                <Grid2 marginTop="10px">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit(handleLoginWithCode)}
                    fullWidth
                    isLoading={loading}
                    disabled={loading}
                  >
                    Login
                  </Button>

                </Grid2>
                <Grid2 marginTop="25px">
                  <Ty align="center">
                    <Link cp={{ 'data-testid': 'link-emlcodebacktologin' }} onClick={resetStates}>Back to Log In</Link>
                  </Ty>
                </Grid2>
              </Grid2>
            )
            : (!magicLinkSuccessContent
          && (
          // main login content
          <Grid2>
            <Grid2 marginBottom="13px">
              <Controller
                name="email"
                control={control}
                render={
                ({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="login-email"
                    inputCp={{ 'data-testid': 'textfield-email' }}
                    type="email"
                    label="Email Address"
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      sessionStorage.setItem('email', event.target.value);
                    }}
                    error={!!error}
                    helpertext={error ? error.message : null}
                  />
                )
                }
              />
            </Grid2>
            <Grid2 marginBottom="13px">
              <TextField
                id="password-login"
                inputCp={{ 'data-testid': 'textfield-password' }}
                type="password"
                label="Password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onPwdChange(e)}
                error={!passwordExist}
                helpertext={!passwordExist ? 'Password is required.' : null}
              />
            </Grid2>
            <Grid2 marginBottom="15px">
              <Button
                fullWidth
                size="large"
                onClick={handleSubmit(handlePwdLogin)}
                disabled={loginBtnLoading}
                isLoading={loginBtnLoading}
                cp={{ 'data-testid': 'button-loginwithpassword' }}
              >
                Log In
              </Button>
            </Grid2>
            <Grid2 display="flex" justifyContent="center">
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={rememberMe}
                    cp={{ 'data-testid': 'checkbox-rememberme' }}
                    value="undefined"
                    onChange={handleChange}
                  />
                  )}
                label="Remember Me"
              />
            </Grid2>
            <Grid2 marginTop="21px" width="100%">
              <Box sx={{ width: '100%' }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <Card
                      growsOnHover
                      onMouseEnter={(e) => setShowEmailLinkPopover(e.currentTarget)}
                      onMouseLeave={() => setShowEmailLinkPopover(null)}
                      onClick={handleSubmit((data) => handleLoginWithCodeORMagicLink(data, 2))}
                      cp={{ 'data-testid': 'button-emaillink' }}
                    >
                      <Ic iconName="envelope" iconStyle="solid" size="rel-lg" />
                    </Card>
                    <Popover
                      isOpen={Boolean(showEmailLinkPopover)}
                      anchorEl={showEmailLinkPopover}
                      onMouseEnter={(e) => setShowEmailLinkPopover(e.currentTarget)}
                      onMouseLeave={() => setShowEmailLinkPopover(null)}
                      anchorH="left"
                      anchorV="top"
                      transformH="center"
                      transformV="center"
                    >
                      <Box sx={{ padding: '12px' }}>
                        <Ty v="p">Send a magic login link to your email</Ty>
                      </Box>
                    </Popover>
                  </Grid2>
                  <Grid2 xs={4}>
                    <Popover
                      isOpen={Boolean(showEmailCodePopover)}
                      anchorEl={showEmailCodePopover}
                      onMouseEnter={(e) => setShowEmailCodePopover(e.currentTarget)}
                      onMouseLeave={() => setShowEmailCodePopover(null)}
                      anchorH="center"
                      anchorV="top"
                      transformH="center"
                      transformV="center"
                    >
                      <Box sx={{ padding: '12px' }}>
                        <Ty v="p">Send a login code to your email</Ty>
                      </Box>
                    </Popover>
                    <Card
                      onMouseEnter={(e) => setShowEmailCodePopover(e.currentTarget)}
                      onMouseLeave={() => setShowEmailCodePopover(null)}
                      growsOnHover
                      onClick={handleSubmit((data) => handleLoginWithCodeORMagicLink(data, 1))}
                      cp={{ 'data-testid': 'button-emailcode' }}
                    >
                      <Ic iconName="hashtag" iconStyle="solid" size="rel-lg" />
                    </Card>
                  </Grid2>
                  <Grid2 xs={4}>
                    <Popover
                      isOpen={Boolean(showResetPwdPopover)}
                      anchorEl={showResetPwdPopover}
                      onMouseEnter={(e) => setShowResetPwdPopover(e.currentTarget)}
                      onMouseLeave={() => setShowResetPwdPopover(null)}
                      anchorH="right"
                      anchorV="top"
                      transformH="center"
                      transformV="center"
                    >
                      <Box sx={{ padding: '12px' }}>
                        <Ty v="p">Forgot your password? Reset it!</Ty>
                      </Box>
                    </Popover>
                    <Card
                      growsOnHover
                      onMouseEnter={(e) => setShowResetPwdPopover(e.currentTarget)}
                      onMouseLeave={() => setShowResetPwdPopover(null)}
                      onClick={() => navigate('/pwdforgot')}
                      cp={{ 'data-testid': 'link-resetmypwd' }}
                    >
                      <Ic iconName="unlock" iconStyle="duotone" size="rel-lg" />
                    </Card>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
          )
            )}
        </Grid2>
      </Grid2>
    </Page>
  );
};

export default Login;
