import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, Grid2, Grid2Ct,
} from '@languageconvo/wcl';
import { LessonInfo } from '../layout/LessonInfo';
import { TeacherStreamDisplay } from '../layout/TeacherStreamDisplay';
import { GetEnrolledStudents } from '../GetEnrolledStudents';
import { ClassControls } from '../layout/ClassControls';
import { VonageSessionsManager } from '../VonageSessionsManager';
import { StudentData, StudentsSection } from '../layout/StudentsSection';
import { AudioVideoControls } from '../layout/AudioVideoControls';
import { QueryDataRoomLayoutFragment$key } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';
import { QueryDataLessonDetailsFragment$key } from '../../relay/__generated__/QueryDataLessonDetailsFragment.graphql';
import { CustomMutationDataLessonDetailFragment$key } from '../../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
import { heightStudentLsnInfo } from '../CommonGroupComponents';

interface Props {
  setMainView: any;
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  layoutFragmentRef: QueryDataRoomLayoutFragment$key;
  lessonDetailsFragmentRef: QueryDataLessonDetailsFragment$key;
  setmodalviewContents: any;
  setmodalviewState: any;
}

/**
 * This is our classroom layout which includes different sections for example. screen sharing,
 * teachers audio, teachers controls (by clicking on these controls, teachers can update room
 * layout data & move students into groups.)
 */
export const Step4ClassroomLayout = ({
  setMainView,
  classroomDataFragmentRef,
  layoutFragmentRef,
  lessonDetailsFragmentRef,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // 10 seconds before lesson start, we start vonage stream.
  // state to manage the initiation of the Vonage stream 10 seconds before the lesson starts.
  // This state is set to true when there are 10 seconds remaining until the lesson begins.
  const [startVonage, setStartVonage] = useState<boolean>(false);

  // state to track whether the lesson has started or not. this state is set to true when 
  // `secondsRemainingInLessonStart` is less than 0.
  // this state is used in:
  // -1 StudentsSectionAutoArrange: to disable the auto-arrange button so the teacher 
  //   cannot divide students into groups before the lesson starts. 
  // Note: The teacher can enter the classroom up to 5 minutes before the lesson starts.
  const [isLessonStarted, setIsLessonStarted] = useState<boolean>(false);

  // state to track whether the lesson has ended or not. this state is set to true when 
  // `secondsRemainingInLessonEnd ` is less than 0.
  // we are showing lesson ended dialog when this state is true and disconnecting teacher from 
  // vonage session 
  const [lessonEnded, setLessonEnded] = useState<boolean>(false);

  // state to hold the number of student who reserved the lesson.
  // need to show how many student has reserved the lesson in `LessonTimeCalculation` cmp
  const [reservedStudentLenght, setReservedStudentLenght] = useState(0);

  // state to hold student's feedback related to teacher's audio
  // because we need to show this information in LessonInfo component,
  const [stdWhoRaisedHand, setStdWhoRaiedHand] = useState<StudentData[]>([]);

  // state to hold fragmentRef return by subscription.
  const [studentFragmentRef, setStudentFragmentRef] = useState(null);

  // remove the "loading" view so we can display the main classroom, as long as the lesson
  // has not ended yet
  useEffect(() => {
    if (!lessonEnded) {
      setMainView(0);
    } else {
      setMainView(105);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonEnded]);

  // make sure we don't display this component once the lesson ends. note that other code
  // in LessonInfo disconnects from the vonage session
  if (lessonEnded) {
    return (null);
  }

  return (
    <Grid2Ct>
      {/* first row -> video components */}
      <Grid2 xs={12}>
        {/* teacher video and screensharing UI */}
        <TeacherStreamDisplay />

        {/* hold vonage session connection/disconnection, connect 
         to new session on room layout change logic */}
        <VonageSessionsManager
          classroomDataFragmentRef={classroomDataFragmentRef}
          layoutFragmentRef={layoutFragmentRef}
          startVonage={startVonage}
        />
      </Grid2>

      {/* second row -> AV Devices controls */}
      <Grid2 xs={12}>
        <AudioVideoControls />
      </Grid2>

      {/* third row -> teacher controls */}
      <Grid2 xs={12}>
        <ClassControls
          classroomDataFragmentRef={classroomDataFragmentRef}
          layoutFragmentRef={layoutFragmentRef}
        />
      </Grid2>

      {/* fourth row -> student section and lesson info */}
      <Grid2 xs={12}>
        <Grid2Ct>
          {/* subscription to get list of students who reserved this lesson */}
          <Grid2 xs={12} md={8}>
            <GetEnrolledStudents setStudentFragmentRef={setStudentFragmentRef} />
            {studentFragmentRef === null ? (
              // suspense/loading, just an empty card the same height as the actual
              <Card>
                <CardContent cp={{ sx: { minHeight: heightStudentLsnInfo } }}>
                  &nbsp;
                </CardContent>
              </Card>
            ) : (
              <StudentsSection
                classroomDataFragmentRef={classroomDataFragmentRef}
                layoutFragmentRef={layoutFragmentRef}
                studentFragmentRef={studentFragmentRef!}
                isLessonStarted={isLessonStarted}
                setReservedStudentLenght={setReservedStudentLenght}
                setStdWhoRaiedHand={setStdWhoRaiedHand}
              />
            )}
          </Grid2>

          {/* Here we are showing lesson info (time remaining in lesson end, no of student 
          in the lesson) this componnet also responsible to start vonage stream if
          lesson time started. */}
          <Grid2 xs={12} md={4}>
            <LessonInfo
              lessonDetailsFragmentRef={lessonDetailsFragmentRef}
              classroomDataFragmentRef={classroomDataFragmentRef}
              reservedStudentLenght={reservedStudentLenght}
              isLessonStarted={isLessonStarted}
              setIsLessonStarted={setIsLessonStarted}
              lessonEnded={lessonEnded}
              setLessonEnded={setLessonEnded}
              startVonage={startVonage}
              setStartVonage={setStartVonage}
              setmodalviewContents={setmodalviewContents}
              setmodalviewState={setmodalviewState}
              stdWhoRaisedHand={stdWhoRaisedHand}
            />
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
