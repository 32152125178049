import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { getDataToLCStorage } from '../../../../utils/lcStorage';

// this hook is responsible to handle pusher authorization and authentication
// and register an event listner to notify if any user added or removed from
// pusher channel
export const usePusher = (pusherChannelName: string) => {
  // onlineUsers holds all students who are online, else all other students 
  // will consider offline.
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [isError, setIsError] = useState<any>();
  const storedSecurityValues = getDataToLCStorage('lc_tchsecurity');

  const initAndConnect = (channelName: any) => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
      // very important: this changes the default activity timeout from 120 seconds
      // to 3 seconds. we do this so that pusher will check "is the user online?" every
      // 3 seconds, which allows us to use pusher as an "is user online" detection system
      activityTimeout: 3000,
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_DIRECTBE}/apptgroup/classroom/tch/pusherauthorize`,
        transport: 'ajax',
        headers: {
          Authorization: `Bearer ${storedSecurityValues.jwt}`,
        },
      },
      userAuthentication: {
        endpoint: `${process.env.REACT_APP_DIRECTBE}/apptgroup/classroom/tch/pusherauthenticate`,
        transport: 'ajax',
        params: {},
        headers: {
          Authorization: `Bearer ${storedSecurityValues.jwt}`,
        },
      },
      cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
    });
    pusher.signin();
    const pusherChannel = pusher.subscribe(channelName);
    return { pusher, pusherChannel };
  };

  useEffect(() => {
    const { pusher, pusherChannel } = initAndConnect(pusherChannelName);

    // this fires once, the first time the subscription succeeds. when this occurs
    // we can get an initial list of user uuids who are currently online, including
    // the teacher (whose id will be just 'teacher')
    pusherChannel.bind('pusher:subscription_succeeded', (members: any) => {
      // VERY IMPORTANT
      // if the code does not get here, we have NOT successfully subscribed to the pusher channel.
      // even though the pusher object is created, the connection state is = "connected", only
      // THIS callback tells us that the user successfully authed and subscribed to the pusher
      // channel when we first connect to the pusher presence channel, this tells us which
      // onlineUsers are currently connected to it
      const keys = Object.keys(members.members);
      processChannelMembers(keys);
    });

    // this will fire in the case that subscribing to the pusher channel fails. we should probably
    // log and show a page level error if this occurs. testing is needed though -- if the
    // user's internet disconnects for more than X seconds, does this fire?
    pusherChannel.bind('pusher:subscription_error', (err: any) => {
      setIsError(err);
    });

    // fires whenever a user connects to pusher channel
    pusherChannel.bind('pusher:member_added', () => {
      const allMembers: string[] = [];
      // pusher types assume this is regular channel rather than a presence channel, and
      // regular channels don't have a members method. thus ts-ignore
      // @ts-ignore
      pusherChannel.members.each((mem: any) => {
        allMembers.push(mem.id);
      });
      processChannelMembers(allMembers);
    });

    // fires whenever a user disconnects from the pusher channel
    // we are assuming if inter connection is down for teacher
    // this callback will fire.
    pusherChannel.bind('pusher:member_removed', () => {
      const allMembers: string[] = [];
      // @ts-ignore see above for explanation
      pusherChannel.members.each((mem: any) => {
        allMembers.push(mem.id);
      });
      processChannelMembers(allMembers);
    });

    return () => {
      pusher.unsubscribe(pusherChannelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* setOnlineUsers anytime pusher tells us the list of members has changed
      This function should be called anytime pusher tells us new members have been added or removed
      from the presence channel. Its primary job is:
        - remove the __abc123 random string from the end of each member's "id". the first part of
            the string is the user's uuid, which is the only thing we want to have in the array
            of online users (onlineUsers)
  */
  function processChannelMembers(members: string[]) {
    // remove the double underscores and everything after it. recall that each connection
    // a user makes to pusher looks like this:
    // abc123-def456__wqiek32
    // the first part, before the __, is their uuid. the random string at the end is used denote
    // separate connections they make from different browser tabs. this is how we can determine
    // that the user has connected twice
    const cleanedMembers: string[] = [];
    members.forEach((el) => {
      const theUuid = el.split('__')[0];
      cleanedMembers.push(theUuid);
    });

    // set our array of online users. onlineUsers is the exported array of user uuids we use in
    // other places in the classroom
    // eslint-disable-next-line no-console
    console.log('processChannelMembers', cleanedMembers);
    setOnlineUsers(cleanedMembers);
  }

  return { onlineUsers, isError };
};
