/**
 * @generated SignedSource<<c5c5d7c492950474c669cbaae1151b2a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TchApptgroupUpdatelayoutMutation$variables = {
  data: string;
  lessonUuid: string;
};
export type TchApptgroupUpdatelayoutMutation$data = {
  readonly tch_apptgroup_updatelayout: {
    readonly appt_group_layout: {
      readonly id: string;
      readonly layout: string;
    };
    readonly success: boolean;
  };
};
export type TchApptgroupUpdatelayoutMutation = {
  response: TchApptgroupUpdatelayoutMutation$data;
  variables: TchApptgroupUpdatelayoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "data"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lessonUuid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      },
      {
        "kind": "Variable",
        "name": "lessonUuid",
        "variableName": "lessonUuid"
      }
    ],
    "concreteType": "ApptGroupLayoutTchUpdateOutput",
    "kind": "LinkedField",
    "name": "tch_apptgroup_updatelayout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "appt_group_layout",
        "kind": "LinkedField",
        "name": "appt_group_layout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "layout",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TchApptgroupUpdatelayoutMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TchApptgroupUpdatelayoutMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c25c6e18be1683bd28620e5e5bd30f5d",
    "id": null,
    "metadata": {},
    "name": "TchApptgroupUpdatelayoutMutation",
    "operationKind": "mutation",
    "text": "mutation TchApptgroupUpdatelayoutMutation(\n  $lessonUuid: String!\n  $data: String!\n) {\n  tch_apptgroup_updatelayout(lessonUuid: $lessonUuid, data: $data) {\n    success\n    appt_group_layout {\n      id\n      layout\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b1bd46a30dc1738d7dcc9d7a08a589e";

export default node;
