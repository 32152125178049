import { useContext } from 'react';
import { VonageContext } from '../context/VonageProvider';

// VonageProvider contains communication api which is being used 
// in the whole group lesson module, no other part of application 
// using this, that's why, we created its context so, it can be shared
// in the whole group lesson compoennts without drilling dwon the props.
const useVonage = () => useContext(VonageContext);

export default useVonage;
