import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Alert, Ty, useTheme, CircularProgress
} from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import Page from '../../components/pagewrapper/Page';
import { JustifyContent } from '../../layouts/authOnly/index.style';
// Mutation...
import { VerifyAuthToken } from './relay/VerifyAuthToken';

import useAuth from '../../hooks/useAuth';
import { setCookieForCurrentUser } from '../../utils/setUserState';

const Auth = () => {
  const [VerifyAuthTokenMutation] = useMutation(VerifyAuthToken);
  const theme = useTheme();
  const PrimaryColor = theme.palette.primary.main;
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  // In react 18 useEffect (componentDidMount works twice) which causes to fail our
  // mutation as styched token is available only once.
  // React 18 used reuseable state feature, which mean, in between the initial mount and unMount at
  // the end of the lifecycle, component can be remounted multiple time
  // To restrict useEffect work only once, there are 2 solutions.
  // 1- Add cleanUp function in the useEffect so it executes only once.
  // 2- We are using Refernce, which will execute only once and save our ass.
  const mutationExecuted = useRef(false);
  useEffect(() => {
    if (!mutationExecuted.current) {
      mutationExecuted.current = true;
      VerifyAuthTokenMutation({
        variables: { authToken: searchParams.get('token') || '' },
        onCompleted(res: any) {
          setCookieForCurrentUser(res?.resources_auth_verify_token, setAuth, 'lc_tchsecurity', navigate);
        },
        onError(err: any) {
          setError(true);
          // Can check on sentry, why sentry token validation is failed...
          Sentry.captureException(err);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Logging In..." dataTestId="page-auth">
      <JustifyContent marginTop="calc(10vh)">
        {!error && <CircularProgress data-testid="circularprogress" sx={{ color: PrimaryColor }} />}
      </JustifyContent>
      <JustifyContent marginTop="calc(10vh)">
        {!error ? (
          <Ty v="p">
            Please Wait! We&apos;re logging you in....
          </Ty>
        ) : (
          <Alert severity="error" cp={{ 'data-testid': 'alert-failedauth' }}>Hmm, it looks like your login link may have expired, please return to the log in page and try logging in again.</Alert>
        )}

      </JustifyContent>
    </Page>
  );
};

export default Auth;
