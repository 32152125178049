import React, { useEffect } from 'react';
import { useClientQuery, useFragment } from 'react-relay';
import useVonage from '../hooks/useVonage';
import { initializeNewSession, getSessionToConnectWith } from './VonageSessionsManagerHelper';
import { MicAndCameraDetailsQuery } from '../../../../common/relay/queries/MicAndCameraDetails';
import { MicDetailsFragment } from '../../../../common/relay/queries/fragments/MicDetails';
import { CameraDetailsFragment } from '../../../../common/relay/queries/fragments/CameraDetails';
import { QueryDataRoomLayoutFragment } from '../relay/QueryData';
import { QueryDataRoomLayoutFragment$key } from '../relay/__generated__/QueryDataRoomLayoutFragment.graphql';
import { CustomMutationDataLessonDetailFragment } from '../relay/CustomMutationData';
import { CustomMutationDataLessonDetailFragment$key } from '../relay/__generated__/CustomMutationDataLessonDetailFragment.graphql';
import { RoomViewTy } from '../context/Types';

interface Props {
  classroomDataFragmentRef: CustomMutationDataLessonDetailFragment$key;
  layoutFragmentRef: QueryDataRoomLayoutFragment$key;
  startVonage: boolean;
}

/* This is the main component which is responsible for managing the vonage session in react
   way, for example, via this manager, session connection/disconnection become automatic
   via the useEffect hooks...
*/
export const VonageSessionsManager = ({
  classroomDataFragmentRef,
  layoutFragmentRef,
  startVonage,
}: Props) => {
  // #region reading data from fragments

  const initialClassroomData = useFragment(
    CustomMutationDataLessonDetailFragment,
    classroomDataFragmentRef,
  );
  const roomLayoutData: any = useFragment(QueryDataRoomLayoutFragment, layoutFragmentRef);
  // based on layout data we are making decision either teacher needs to publish their stream
  // or need to subscribe as well while publishing
  const layoutData = JSON.parse(roomLayoutData.appt_group_layout.layout);

  // #endregion

  // reading mic & camera details from relay
  const response: any = useClientQuery(MicAndCameraDetailsQuery, {});
  const fragmentR = response.RelayAppSettings;
  const micData = useFragment(MicDetailsFragment, fragmentR);
  // micInfo hold all the information related to microphone
  // we need to pass current mic info in our publisher for current audio
  // source and current mic State(is it mute/unmute).
  const micInfo = micData.microphone;
  const currentMicId = micInfo.current.micId;
  const currentMicName = micInfo.current.title;
  // need to pass this information in publishVideoStream
  const cameraData = useFragment(CameraDetailsFragment, fragmentR);
  // cameraInfo hold all the information related to camera
  // we need to pass current camera info in our publisher for current video
  // source and current camera State(is it on/off).
  const cameraInfo = cameraData.camera;
  const currentCameraId = cameraInfo.current.camId;
  const currentCameraName = cameraInfo.current.title;

  // #region general region for react steate and varriables
  // Destructure the properties returned by `useVonage` hook
  const {
    initializeSession,
    connectToSession,
    publishVideoStream,
    sessionConnected,
    vonageSession,
    subscribeToStream,
    previousRoomLayoutData,
    setPreviousRoomLayoutData,
    disconnectFromSessionAndConnectToNewSession,
    stopScreenSharingStream,
    isTeacherScreensharing,
    videoPublisher
  } = useVonage();
  // #endregion

  // #region for listening layout data changes and based on appropriate change, connect/disconnect 
  // diff sessions.

  /** Step 1: listen to layout data changes and lesson start time indication,
   * whenever there is a change in layout data. i-e teacher starts group chat.
   * Here we've the main logic of initialize to new vonage session/disconnect from 
   * from previous vonage session. 
   * This will exeuctes whenever there is a change in layout data OR on pageload
   * when the startVonage flag is true (10 seconds before lesson start)
   * we are connecting teacher with session this is important because without initializing the 
   * session user can not connect to the session
   */
  useEffect(() => {
    initializeNewSession(
      startVonage,
      previousRoomLayoutData,
      layoutData,
      initialClassroomData,
      vonageSession,
      disconnectFromSessionAndConnectToNewSession,
      setPreviousRoomLayoutData!,
      initializeSession,
      isTeacherScreensharing,
      stopScreenSharingStream
    );
    // eslint-disable-next-line
  }, [startVonage, layoutData]);

  /** Step 2: listen for vonage session creation, and then connect to it
   * In the vonage provider, we are initializing the vonage session and once this initialized
   * we get the notification here with the udpated vonageSession and once we get that, we 
   * call connect to session function and connect the user to new session.
   * so user can publish their streams OR subscribe to other's streams
   */
  useEffect(() => {
    // two cases in which vonageSession will be undefined:
    //  1. if the teacher has selected "speaking to = nobody". in that case we don't want to
    //    connect to any session
    //  2. on pageload/when this useEffect first runs
    if (vonageSession !== undefined) {
      // get updated session creds based on latest room layout change
      const sessionCredentials = getSessionToConnectWith(
        layoutData,
        initialClassroomData
      );
      // connecting to the Session
      if (sessionCredentials !== null) {
        connectToSession(sessionCredentials.token);
      }
    }
    // eslint-disable-next-line
  }, [vonageSession]);

  /** Step 3: listen to sessionConnected, then publish/subscribe to stream
   * in the vonage provider, we are connecting the vonage session and once this connected
   * we get the notification here with the sessionConnected flag and once we get that, we 
   * start pubishing audio/video streams.
   * 
   * Note that sessionConnected will not be true if "speaking to = nobody", and when page
   * initially loads. So publish/subscribe will not occur in those cases.
   */
  useEffect(() => {
    // checking if the vonage session is already connected
    if (sessionConnected) {
      // in both lecture mode and group chat, we publish the teacher's stream. but in group
      // chat we *also* subscribe to other students' streams
      publishVideoStream(micInfo, cameraInfo, layoutData.roomView);

      if (layoutData.roomView === RoomViewTy.GroupChat) {
        subscribeToStream();
      }
    }
    // eslint-disable-next-line
  }, [sessionConnected]);

  /** switch the mic the user is publishing with, anytime the relay store value for current mic
   * changes. note that currentAudioDevice can definitely be null (when the user has no mics
   * attached to their device)
   * 
   * VERY IMPORTANT -- Here we must listen to changes in mic NAME, not ID. Why? A common micId
   * is "default", another common one is "communications". If the user is using their "default"
   * device and unplugs it, the new one that's used might also have the id "default" in which
   * case the mic won't change!
   */
  useEffect(() => {
    if (videoPublisher && currentMicId) {
      // eslint-disable-next-line
      console.log('detected current mic change in relay store; automatically switching it');
      videoPublisher.setAudioSource(currentMicId);
    } else if (videoPublisher && !currentMicId) {
      // eslint-disable-next-line
      console.log('detected current mic change in relay store, BUT its null so we cant change mic to anything');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMicName]);

  /** switch the cam the teacher is publishing with, anytime the relay store value for current cam
   * changes. note that currentVideoDevice can definitely be null (when the user has no camera
   * attached to their device)
   * 
   * VERY IMPORTANT -- Here we must listen to changes in camera NAME, not ID. Why? A common camId
   * is "default", another common one is "communications". If the user is using their "default"
   * device and unplugs it, the new one that's used might also have the id "default" in which
   * case the camera won't change!
   */
  useEffect(() => {
    if (videoPublisher && currentCameraId) {
      // eslint-disable-next-line
      console.log('detected current camera change in relay store; automatically switching it');
      videoPublisher.setVideoSource(currentCameraId);
    } else if (videoPublisher && !currentCameraId) {
      // eslint-disable-next-line
      console.log('detected current camera change in relay store, BUT its null so we cant change camera to anything');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCameraName]);

  // #endregion

  return <div />;
};
