export const RelayAppSettingsVals = {
  // root record
  name: 'RelayAppSettings',
  idName: 'id',
  idVal: 'c6c484ba-c6d2-4c2d-8e54-ffc7a4b758fa',

  // microphone settings
  micrphoneDetailsIdVal: 'fd0db262-1a92-4e65-b8dc-a0ad0b0b1c23',
  microPhoneDetailsName: 'microphone',
  micrphoneDetailsType: 'MicroPhoneDetails',
  microphoneDetailsIdName: 'id',

  // camera settings
  cameraDetailsIdVal: 'feb5d1bf-17b5-4060-88d2-3c53c3e104a4',
  cameraDetailsName: 'camera',
  cameraDetailsType: 'CameraDetails',
  cameraDetailsIdName: 'id',

  // current microphone
  currentMicIdVal: 'e34b5d78-f56b-4d26-a7d6-114a41357a10',
  currentMicType: 'CurrentMicrophone',
  currentMicName: 'current',
  currenMicrophoneIdName: 'micId',
  currentMicrophoneTitleName: 'title',
  currentMicrohponeMutedName: 'isMuted',

  // all microphones
  allMicIdVal: 'cfd1f3ca-7a32-4a04-b21b-ee653a816b26',
  allMicIdName: 'id',
  allMicType: 'AllAvailableMic',
  allMicName: 'all',

  // current camera
  currentCamIdVal: 'bd5344aa-d82e-40b9-88da-ec05f12f42c1',
  currentCamType: 'CurrentCamera',
  currentCamName: 'current',
  currenCameraIdName: 'camId',
  currentCameraTitleName: 'title',
  currentCameraOnName: 'isCameraOff',

  // all available camera
  allCamIdVal: 'c5d88b74-dfa8-4726-a80b-9841c07f75ce',
  allCamIdName: 'id',
  allCamType: 'AllAvailableCamera',
  allCamName: 'all',
};
