const graphql = require('babel-plugin-relay/macro');

export const AuthLoginPassword = graphql`
mutation AuthLoginPasswordMutation($email: String!, $password: String!) {
    resources_auth_login_password(email: $email, password: $password) {
        data {
          email
          image
          name
          resourceId
          languageId
          timezone
        }
        jwt
      }
    }
`;
