import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// common... navbar translations...
import compNavbar_DE from './de';
import compNavbar_EN from './en';
import compNavbar_FR from './fr';

const resources = {
  en: compNavbar_EN,
  de: compNavbar_DE,
  fr: compNavbar_FR
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: 'en', // if you're using a language detector, do not define the lng option
    // this can help you avoid having to pass the namespace to the translation hook
    // defaultNS: 'compNavbar',
    fallbackLng: 'en',
    // keySeparator: ':',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;
