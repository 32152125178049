export const VonagePermissionMessages = {
  AVDefaultConnected: 'You are connected with default audio/video devices.',
  PrevAduioAndDefaultVideo: 'You are connected with previouly used audio but connected with default video device as previously used video device not found!',
  DefaultAudioAndPrevVideo: 'You are connected with previouly used video but connected with default audio device as previously used audio device not found!',
  PreviousAVDevices: 'You are connected with previouly used A/V devices',
  AudioDeviceSwitch: 'Audio device unplugged, and you are connected with your default audio device',
  FailedToGetAudioDevice: 'Failed to get audio device.',
  FailedToGetVideoDevice: 'Failed to get video device.',
  SessionConnectionFailed: 'Failed to connect with session.',
  ScreenSharingNotSupported: 'Screen Sharing is not supported in this browser',
  NeedScreenSharingAccess: 'You need to allow access to screen in order to share it',
  FaildToGetDevices: 'Failed To Get Audio & Video Devices.',
  PlugAudioDeviceAgain: 'You need to  plug  audio device otherwise student can not hear you.',
  GeneralError: 'Hmm, something went wrong',
  InValidGroupLessonLcData: 'localStored values for group lesson not valid.',
  ScreenSharingIsNotAllowed: 'Screen Sharing is only possible in lecture mode',
  NoAudioDevice: 'No Audio Device Found',
  NoVideoDevice: 'Currently you are not connected with any video device',
  FailedToOffCam: 'Failed To Turn Off your camera',
  FailedToONCam: 'Failed To Turn On your camera',
  FailedToMute: 'Failed to mute your microphne',
  FailedToUnMute: 'Failed to unMute your microphne',
  // this message is displayed when the teacher enters the classroom
  // before the lesson has started and attempts to share their screen.
  NoSessionForScreenSharing: 'You are not connected with any session, so you can not share your screen right now',
};
