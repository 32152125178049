import { graphql } from 'babel-plugin-relay/macro';

export const GetEnrolledStudentsSubscription = graphql`
  subscription GetEnrolledStudentsSubscription($lessonUuid: uuid!) {
    ...GetEnrolledStudentsFragment
  }
`;

export const GetEnrolledStudentsFragment = graphql`
  fragment GetEnrolledStudentsFragment on subscription_root {
    appt_group_connection(
      where: {
        uuid: {_eq: $lessonUuid}
      }
    ) {
      edges {
        node {
          appt_group_reservations {
            hand_raised
            hand_raised_ts
            users {
               first_name
              last_name
              user_publicinfo {
                uuid
                display_firstname
                display_lastname
                displayname_approved
                appt_group_stublocks {
                  uuid: other_user_publicuuid
                }
              },
              appt_group_tchblocks {
                users {
                  user_publicinfo {
                    uuid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
