import React from 'react';
import {
  CardSmallColor,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { toast } from 'react-toastify';
import useVonage from '../../hooks/useVonage';
import { VonagePermissionMessages } from '../../messages/VonagePermissions';
import { QueryDataRoomLayoutFragment } from '../../relay/QueryData';
import { QueryDataRoomLayoutFragment$key } from '../../relay/__generated__/QueryDataRoomLayoutFragment.graphql';

interface Props {
  layoutFragmentRef: QueryDataRoomLayoutFragment$key;
}

// enable teacher to share his/hershare screen
export const ClassControlsScreenshare = ({
  layoutFragmentRef
}: Props) => {
  // #region reading data from fragments to decide to whom teacher is speaking to

  const roomLayoutData:any = useFragment(QueryDataRoomLayoutFragment, layoutFragmentRef);
  const layoutData = JSON.parse(roomLayoutData.appt_group_layout.layout);

  // #endregion

  // #region for handling screen sharing 

  const {
    publishScreenSharingStream,
    stopScreenSharingStream,
    isTeacherScreensharing,
  } = useVonage();

  const handleClickingOnScreenSharing = (isScreenSharing: boolean, roomLayoutHash: any) => {
    // checking if teacher is not already sharing his screen and making sure teacher 
    // streaming to entity is 1 this means roomView is 1(Lecture Mode) and screen sharing is only 
    // available in Teacher only mode.
    if (!isScreenSharing && roomLayoutHash.teacherStreamingTo[0].entity === 1) {
      publishScreenSharingStream();

      // screenshare is not (currently) allowed during group chat mode
    } else if (roomLayoutHash.teacherStreamingTo[0].entity !== 1) {
      toast.warning(VonagePermissionMessages.ScreenSharingIsNotAllowed);
      // if the teacher is already sharing their screen and they click on the share button
      // again, we stop their sharing
    } else if (isScreenSharing) {
      stopScreenSharingStream();
    }
  };

  // #endregion

  return (
    <CardSmallColor
      smallerfont
      icon="monitor1"
      text="Share Screen"
      color="accentOrange1"
      hovercursor="pointer"
      onClick={() => handleClickingOnScreenSharing(isTeacherScreensharing, layoutData)}
    />
  );
};
